import Aos from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './App.css';
import './styles/css/mobile-styles.css';
import { Router } from './routes/Routes';

function App() {
  let scrolldiff = 0;
  const location = useLocation();

  useEffect(() => {
    Aos.init({ once: true });
  }, []);

  useEffect(() => {
    scrolldiff = 0;
    window.addEventListener('scroll', aosScrollEvent, true);
  }, [location]);

  function aosScrollEvent() {
    if (scrolldiff <= 5) scrolldiff++;
    else {
      Aos.refresh();
    }
  }

  return (
    <div>
      <Router />
    </div>
  );
}

export default App;
