import { Link } from "react-router-dom";
import { Rules } from "../../context/Nosotros/models/GobiernoCorporativo.model";
import "../article/article.css";

export interface RuleFileListProps {
    rules?: Rules;
}
export const RuleFileList = (props: RuleFileListProps) => {

    return (<>
        {props.rules?.ruleFileList?.map((ruleFile, key) => (
            <div className="items-center article-text__document md:flex" key={key}>
                <img src={ruleFile.tumbnail} alt="" className="article-text__document-img" />
                <div className="article-text__document-info">
                    <h3 className="article-text__document-title">{ruleFile.name}</h3>
                    <p className="article-text__document-text opacity-100">Modificado: {ruleFile.date.toLocaleDateString('es-DO')} - Tamaño: {ruleFile.size}</p>
                    <a className="article-text__document-download" target="_blank" rel="noreferrer" href={ruleFile.link}>
                        <span className="feather icon-external-link"></span> Ver documento completo
                    </a>
                </div>
            </div>
        ))}

    </>);
};