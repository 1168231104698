import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { MiembrosConsejoMockData } from "./mock/MiembrosConsejo.mock";
import { MiembrosConsejo, PersonalDetail } from "./models/MiembrosConsejo.model";


type MiembrosConsejoProviderProps = { children: React.ReactNode; };
const MiembrosConsejoContext = React.createContext<MiembrosConsejo | undefined>(undefined);

type Message = { type: 'consejo'; value: MiembrosConsejo; };

const reducer = (state: MiembrosConsejo, message: Message) => {
    switch (message.type) {
        case 'consejo':
            return {
                ...state, ...message.value
            };
        default:
            return { ...state };
    }
};
const MiembrosConsejoContextProvider = ({ children }: MiembrosConsejoProviderProps) => {

    const [MiembrosConsejo, setMiembrosConsejo] = useReducer(reducer, {} as MiembrosConsejo);



    useEffect(() => {
        fetchMiembrosConsejoHeader();
        fetchMiembrosConsejo();
    }, []);

    async function fetchMiembrosConsejo() {
        try {
            const miembrosDelConsejo = await axios.get("/Nosotros/_api/web/lists/GetByTitle('Nosotros - Miembros del Consejo')/items?$select=Activo,Title,Posicion,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                const result: MiembrosConsejo = { Titulo: "Miembros del Consejo", personalList: [] };

                if (response.status === 200 && response.data && response.data.d && response.data.d.results) {
                    result.personalList = response.data.d.results.map((detail: any): PersonalDetail => (
                        {
                            name: detail.Title,
                            image: detail.Attachments ? detail.AttachmentFiles.results[0].ServerRelativeUrl : "",
                            position: detail.Posicion
                        }
                    ));
                }
                return result;
            });
            setMiembrosConsejo({ type: 'consejo', value: miembrosDelConsejo });
            // const mockData = MiembrosConsejoMockData;
            // setMiembrosConsejo({ type: 'consejo', value: miembrosDelConsejo });

        } catch (error) {
            console.log(error);
        }
    }

    async function fetchMiembrosConsejoHeader() {

    }


    return (
        <MiembrosConsejoContext.Provider value={MiembrosConsejo}>
            {children}
        </MiembrosConsejoContext.Provider>
    );

};
const useMiembrosConsejo = () => {
    const context = useContext(MiembrosConsejoContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { useMiembrosConsejo, MiembrosConsejoContextProvider };