import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import {
  Project,
  ProjectBuilder,
  ProjectDetail,
  ProjectSummary,
} from "./models/Project.models";
import { ProjectPage } from "./models/ProjectPage.model";

type ProjectDetailsProviderProps = { children: React.ReactNode };
const ProjectDetailsContext = React.createContext<ProjectPage | undefined>(
  undefined
);

type Message =
  | { type: "project"; value: Project }
  | { type: "imagenes"; value: Array<string> }
  | { type: "summary"; value: Array<ProjectSummary> }
  | { type: "details"; value: Array<ProjectDetail> }
  | { type: "builders"; value: ProjectBuilder }
  | { type: "related"; value: Array<Project> };

const reducer = (state: ProjectPage, message: Message): ProjectPage => {
  switch (message.type) {
    case "project":
      return { ...state, Project: message.value };
    case "imagenes":
      return { ...state, Imagenes: message.value };
    case "summary":
      return { ...state, Summary: message.value };
    case "details":
      return { ...state, Details: message.value };
    case "builders":
      return { ...state, Builder: message.value };
    case "related":
      return { ...state, RelatedProjects: message.value };
    default:
      return { ...state };
  }
};

const ProjectDetailsContextProvider = ({
  children,
}: ProjectDetailsProviderProps) => {
  const [state, dispatch] = useReducer(reducer, {} as ProjectPage);
  const location = useLocation();
  const projectId = new URLSearchParams(location.search).get("proyectoId");

  useEffect(() => {
    fetchProject();
    fetchImagenes();
    fetchSummary();
    fetchDetails();
    fetchBuilder();
  }, [location]);

  useEffect(() => {
    if (state.Project && state.Project.status) fetchRelatedProjects();
  }, [state.Project]);

  async function fetchProject() {
    try {
      const projects = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Proyectos')/items?$select=ID,Activo,Orden,Title,TipoProyecto,Ciudad,Estado,PrecioDesde,PrecioHasta,Moneda,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1 and ID eq ${projectId} &$orderby=Orden`,
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          var result = Array<Project>();

          if (
            response.status == 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((item: any) => {
              result.push({
                id: item.ID,
                name: item.Title,
                type: item.TipoProyecto,
                city: item.Ciudad,
                status: item.Estado,
                imageUrl: item.Attachments
                  ? item.AttachmentFiles.results[0].ServerRelativeUrl
                  : "",
                images: [],
                prices: {
                  base: item.PrecioDesde,
                  top: item.PrecioHasta,
                  currency: item.Moneda,
                },
              });
            });

          return result;
        });

      dispatch({ type: "project", value: projects[0] });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchImagenes() {
    try {
      const imagenes = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Proyectos - Imagenes')/items?$select=Orden,Activo,File/ServerRelativeUrl&$expand=File&$orderby=Orden&$filter=Activo eq 1 and Proyecto/ID eq ${projectId}`,
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: string[] = [];

          if (
            response.status == 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((slide: any) => {
              result.push(slide.File ? slide.File.ServerRelativeUrl : "");
            });

          return result;
        });

      dispatch({ type: "imagenes", value: imagenes });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchSummary() {
    try {
      const summaries = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Proyectos - Resumen')/items?$select=ID,Activo,Orden,Title,Contenido,ProyectoId&$filter=Activo eq 1 and Proyecto/ID eq ${projectId} &$orderby=Orden`,
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          var result = Array<ProjectSummary>();

          if (
            response.status == 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((summary: any) => {
              result.push({
                title: summary.Title,
                paragraph: summary.Contenido,
              });
            });

          return result;
        });

      dispatch({ type: "summary", value: summaries });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchDetails() {
    try {
      const details = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Proyectos - Detalles')/items?$select=ID,Activo,Orden,Title,Categoria,ProyectoId&$filter=Activo eq 1 and Proyecto/ID eq ${projectId} &$orderby=Orden`,
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          var result = Array<ProjectDetail>();

          if (
            response.status == 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((detail: any) => {
              result.push({
                title: detail.Categoria,
                text: detail.Title,
              });
            });

          return result;
        });

      dispatch({ type: "details", value: details });
    } catch (error) {
      console.log(error);
    }
  }
  async function fetchBuilder() {
    try {
      const builder = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Proyectos - Constructoras')/items?$select=ID,Activo,Title,Telefono,Correo,Website,ProyectoId&$filter=Activo eq 1 and Proyecto/ID eq ${projectId}`,
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          var result = Array<ProjectBuilder>();

          if (
            response.status == 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((builder: any) => {
              result.push({
                name: builder.Title,
                email: builder.Correo,
                phone: builder.Telefono,
                website: builder.Website,
              });
            });

          return result;
        });

      dispatch({ type: "builders", value: builder[0] });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchRelatedProjects() {
    try {
      const projects = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Proyectos')/items?$select=ID,Activo,Orden,Title,Ciudad,Estado,TipoProyecto,PrecioDesde,PrecioHasta,Attachments,AttachmentFiles,Constructora/Title&$expand=Constructora,AttachmentFiles&$filter=Activo eq 1 and ID ne ${projectId} and TipoProyecto eq '${state?.Project?.type}' &$orderby=Orden&$top=10`,
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          var result = Array<Project>();

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((fideicomiso: any) => {
              result.push({
                id: fideicomiso.ID,
                name: fideicomiso.Title,
                city: fideicomiso.Ciudad,
                status: fideicomiso.Estado,
                type: fideicomiso.TipoProyecto,
                imageUrl: fideicomiso.Attachments
                  ? fideicomiso.AttachmentFiles.results[0].ServerRelativeUrl
                  : "",
                images: [],
                prices: {
                  currency: "Peso",
                  base: fideicomiso.PrecioDesde,
                  top: fideicomiso.PrecioHasta,
                },
                builderName: fideicomiso.Constructora.Title,
              });
            });

          return result;
        });

      dispatch({ type: "related", value: projects });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <ProjectDetailsContext.Provider value={state}>
      {children}
    </ProjectDetailsContext.Provider>
  );
};

const useProjectDetails = () => {
  const context = useContext(ProjectDetailsContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useProjectDetails, ProjectDetailsContextProvider };
