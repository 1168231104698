import { Link } from "react-router-dom";
type SocialNewsLinkProps = {
  social?: string;
  url: string;
};

function SocialNewsLink({ social, url }: SocialNewsLinkProps) {
  return (
    <div className="noticias__social">
      {social === "twitter" && (
        <div className="noticias__social">
          <a href={url}>
            <p>
              <i className="feather icon-twitter"></i>{" "}
              <span>Visitar publicación en Twitter</span>
            </p>
          </a>
        </div>
      )}
      {social === "facebook" && (
        <div className="noticias__social">
          <a href={url}>
            <p>
              <i className="feather icon-facebook"></i>{" "}
              <span>Visitar publicación en Facebook</span>
            </p>
          </a>
        </div>
      )}
      {social === "instagram" && (
        <div className="noticias__social">
          <a href={url}>
            <p>
              <i className="feather icon-instagram"></i>{" "}
              <span>Visitar publicación en Instagram</span>
            </p>
          </a>
        </div>
      )}
      {social === "enlace" && (
        <div className="noticias__social">
          <a href={url}>
            <p>
              <i className="feather icon-globe"></i>{" "}
              <span>Visitar publicación</span>
            </p>
          </a>
        </div>
      )}
    </div>
  );
}

export default SocialNewsLink;
