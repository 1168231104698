import { News, NewsPage } from "./models/News.models";
import React, { useContext, useEffect, useReducer } from "react";

import { NewsMockData } from "./mock/News.mock";
import axios from "axios";
import { useLocation } from "react-router-dom";

type NewsDetailsProviderProps = { children: React.ReactNode };
const NewsDetailsContext = React.createContext<NewsPage | undefined>(undefined);

type Message =
  | { type: "news"; value: News }
  | { type: "related"; value: News[] };

const reducer = (state: NewsPage, message: Message): NewsPage => {
  switch (message.type) {
    case "news":
      return { ...state, News: message.value };
    case "related": {
      return { ...state, RelatedNews: message.value };
    }
    default:
      return { ...state };
  }
};

const NewsDetailsContextProvider = ({ children }: NewsDetailsProviderProps) => {
  const [state, dispatch] = useReducer(reducer, {} as NewsPage);
  const location = useLocation();
  const newsId = new URLSearchParams(location.search).get("noticiaId");

  useEffect(() => {
    async function fetchNews() {
      try {
        const news = await axios
          .get(
            `/Noticias/_api/web/lists/GetByTitle('Noticias')/items?$filter=Activo eq 1 and ID eq ${newsId} &$orderby=Fecha desc`,
            {
              headers: {
                Accept: "application/json;odata=verbose",
                "Content-Type": "application/json;odata=verbose",
              },
            }
          )
          .then((response) => {
            var result = Array<News>();

            if (
              response.status === 200 &&
              response.data &&
              response.data.d &&
              response.data.d.results
            )
              response.data.d.results.forEach((news: any) => {
                result.push({
                  id: news.Id,
                  title: news.Title,
                  content: news.Contenido,
                  imageDetailsUrl: news.Detalle.Url,
                  imageThumbnailUrl: news.Lista.Url,
                  date: new Date(news.Fecha),
                  dateString: new Date(news.Fecha).toLocaleDateString("es-DO", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }),
                });
              });

            return result;
          });

        dispatch({ type: "news", value: news[0] });

        // const newsMockData = NewsMockData;
        // dispatch({ type: 'news', value: newsMockData.find(news => news.id.toString() === newsId) || {} as News });
      } catch (error) {
        console.log(error);
      }
    }
    fetchNews();
  }, [location, newsId]);

  useEffect(() => {
    async function fetchRelatedNews() {
      try {
        const news = await axios
          .get(
            `/Noticias/_api/web/lists/GetByTitle('Noticias')/items?$select=ID,Activo,Title,Contenido,Fecha,Detalle,Lista&$filter=Activo eq 1 and ID ne ${newsId} &$orderby=Fecha desc&$top=10`,
            {
              headers: {
                Accept: "application/json;odata=verbose",
                "Content-Type": "application/json;odata=verbose",
              },
            }
          )
          .then((response) => {
            var result = Array<News>();

            if (
              response.status === 200 &&
              response.data &&
              response.data.d &&
              response.data.d.results
            )
              response.data.d.results.forEach((news: any) => {
                result.push({
                  id: news.Id,
                  title: news.Title,
                  content: news.Contenido,
                  imageDetailsUrl: news?.Detalle?.Url,
                  imageThumbnailUrl: news?.Lista?.Url,
                  date: new Date(news.Fecha),
                  dateString: new Date(news.Fecha).toLocaleDateString("es-DO", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  }),
                });
              });

            return result;
          });

        dispatch({ type: "related", value: news });

        // const newsMockData = NewsMockData;
        // dispatch({ type: 'related', value: newsMockData.filter(news => news.id.toString() !== newsId) });
      } catch (error) {
        console.log(error);
      }
    }
    if (state.News) fetchRelatedNews();
  }, [newsId, state.News]);

  return (
    <NewsDetailsContext.Provider value={state}>
      {children}
    </NewsDetailsContext.Provider>
  );
};

const useNewsDetails = () => {
  const context = useContext(NewsDetailsContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useNewsDetails, NewsDetailsContextProvider };
