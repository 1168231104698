import './checkbox.css';
export interface CustomCheckboxProps {
    id: string;
    label: string;
    defaultChecked: boolean;

    onChange: React.ChangeEventHandler<HTMLInputElement>;

}
export const CustomCheckbox = (props: CustomCheckboxProps) => {
    const { id, label, defaultChecked, onChange } = props;
    return (<label htmlFor={id} className="control control-checkbox">
        {label}
        <input id={id} type="checkbox" defaultChecked={defaultChecked} onChange={onChange} />
        <div className="control_indicator"></div>
    </label>);
};