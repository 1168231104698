import { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { EducationPalabra } from "../../context/Education/models/Glosario.models";
import './termsContainer.css';

export interface TermsContainerProps {
    className?: string;
    words: EducationPalabra[];
}

interface wordGroup {
    letter: string;
    words: EducationPalabra[];
}

export function TermsContainer(props: TermsContainerProps) {

    const [letters, setLetters] = useState<{ [key: string]: boolean; }>({
        a: false,
        b: false,
        c: false,
        d: false,
        e: false,
        f: false,
        g: false,
        h: false,
        i: false,
        j: false,
        k: false,
        l: false,
        m: false,
        n: false,
        o: false,
        p: false,
        q: false,
        r: false,
        s: false,
        t: false,
        v: false,
        w: false,
        x: false,
        y: false,
        z: false
    });
    const [wordFilter, setWordFilter] = useState("");
    const [filteredWords, setFilteredWords] = useState(Array<EducationPalabra>());
    const [groupedWords, setGroupedWords] = useState(Array<wordGroup>());
    const [selectedLetter, setSelectedLetter] = useState("");
    const [selectedGroups, setSelectedGroups] = useState(Array<wordGroup>());

    useEffect(() => {
        if (wordFilter)
            setFilteredWords(
                props.words.filter((word: EducationPalabra) => {
                    if (word.Palabra.toLowerCase().indexOf(wordFilter.toLowerCase()) >= 0)
                        return word;
                })
            );
        else
            setFilteredWords(props.words);
    }, [props.words, wordFilter]);

    useEffect(() => {
        setGroupedWords(
            filteredWords.reduce(
                (result: Array<wordGroup>, currentWord: EducationPalabra) => {
                    const letterIndex = result.findIndex((group: wordGroup) => { return group.letter.toLowerCase() === currentWord.Palabra.toLowerCase()[0]; });
                    if (letterIndex < 0)
                        result.push({
                            letter: currentWord.Palabra.toLowerCase()[0],
                            words: [currentWord]
                        });
                    else
                        result[letterIndex].words = [...result[letterIndex].words, currentWord];

                    return result;
                }, [])
                .sort((firstWord: wordGroup, secondWord: wordGroup) => {
                    return firstWord.letter.localeCompare(secondWord.letter);
                })
        );
    }, [filteredWords]);

    useEffect(() => {
        let newState = letters;

        groupedWords.forEach((group: wordGroup) => {
            newState = { ...newState, [group.letter.toLowerCase()]: true };
        });

        setLetters(newState);
    }, [groupedWords]);

    useEffect(() => {
        if (selectedLetter)
            setSelectedGroups(groupedWords.filter((group: wordGroup) => { return group.letter === selectedLetter; }));
        else
            setSelectedGroups(groupedWords);
    }, [selectedLetter, groupedWords]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [selectedGroups]);

    return (
        <section className="terms-container">
            <div className="head-section">
                <h3 className="article-text__head-title">
                    Glosario
                </h3>

                <div className="form">
                    <input type="text" placeholder="Buscar"
                        value={wordFilter} onChange={(e) => setWordFilter(e.target.value)} />
                </div>
            </div>

            <div className="letter-filter">
                <button className={"letter" + (selectedLetter === "" ? " active" : "")} type="button"
                    onClick={
                        () => {

                            setSelectedLetter("");

                        }
                    }>Ver todos</button>
                {
                    Object.keys(letters).map((key: string, index: number) => {
                        return (
                            <button key={index} className={"letter" + (selectedLetter === key ? " active" : "")} type="button" disabled={!letters[key]}
                                onClick={
                                    () => {
                                        if (selectedLetter === key)
                                            setSelectedLetter("");
                                        else
                                            setSelectedLetter(key);
                                    }
                                }>{key}</button>
                        );
                    })
                }
            </div>

            <div className="words-container">
                {
                    selectedGroups.map((group: wordGroup, groupIndex: number) => {
                        return (
                            <div key={groupIndex} className="letter-container">
                                <div className="letter">
                                    <h1>{group.letter}</h1>
                                </div>

                                <ul className="words">
                                    {
                                        group.words.map((word: EducationPalabra, wordIndex: number) => {
                                            return (
                                                <li key={wordIndex} data-tip={word.Significado} data-for={group.letter + '-tooltip'}>
                                                    {word.Palabra}
                                                </li>
                                            );
                                        })
                                    }
                                </ul>

                                <ReactTooltip id={group.letter + '-tooltip'} />
                            </div>
                        );
                    })
                }
            </div>
        </section>
    );
}