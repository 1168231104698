import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./sideMenu.css";

export interface SideMenuProps {
  className?: string;
  title?: string;
  listItems?: ListItemProps[];
}

export interface ListItemProps {
  className?: string;
  id?: number;
  title?: string;
  parentId?: number;
}

interface SideMenuItem {
  className?: string;
  id?: number;
  title?: string;
  children?: Array<SideMenuItem>;
}

export function SideMenu(props: SideMenuProps) {
  const search = useLocation().search;
  const fideicomisoId = new URLSearchParams(search).get("fideicomisoId");
  const [isShow, setIsShow] = useState(true);
  const [menuItems, setMenuItems] = useState<{ [key: string]: SideMenuItem }>(
    {}
  );

  const handleClick = () => {
    setIsShow(!isShow);
    console.log("clicked");
  };

  useEffect(() => {
    if (props.listItems) {
      setMenuItems(
        props.listItems.reduce<{ [key: string]: SideMenuItem }>(
          (
            result: { [key: string]: SideMenuItem },
            currentItem: ListItemProps
          ) => {
            if (currentItem.id) {
              if (currentItem.parentId) {
                if (!result[currentItem.parentId])
                  result[currentItem.parentId] = {
                    children: [currentItem],
                  };
                else
                  result[currentItem.parentId].children = [
                    ...(result[currentItem.parentId].children || []),
                    currentItem,
                  ];
              } else {
                if (!result[currentItem.id])
                  result[currentItem.id] = currentItem;
                else
                  result[currentItem.id] = {
                    id: currentItem.id,
                    title: currentItem.title,
                    className: currentItem.className,
                    children: result[currentItem.id].children,
                  };
              }
            }
            return result;
          },
          {}
        )
      );
    }
  }, [props.listItems]);

  return (
    <div className="side-menu">
      <div className="side-menu__top">
        <Link to={"/Fideicomisos"}>
          <button type="button" className="side-menu__trigger">
            <span className="side-menu__title">{props.title}</span>
          </button>
        </Link>
      </div>
      <div className="relative overflow-hidden transition-all duration-700 side-menu__bottom">
        <ul className="side-menu__bottom-list">
          {Object.keys(menuItems).map((key: string, index: number) => {
            return (
              <li
                key={index}
                className={menuItems[key].children ? "sub-acordeon" : ""}
              >
                <div className="flex items-baseline justify-between">
                  <Link
                    to={"/Fideicomisos?fideicomisoId=" + menuItems[key].id}
                    className={
                      fideicomisoId &&
                      fideicomisoId === menuItems[key].id?.toString()
                        ? "active trigger-sub-accordeon"
                        : "trigger-sub-accordeon"
                    }
                  >
                    {menuItems[key].title}
                  </Link>
                  <button className="mx-4" onClick={handleClick} type="button">
                    {
                      <span
                        className={
                          menuItems[key].children
                            ? `feather ${
                                isShow ? "icon-chevron-down" : "icon-chevron-up"
                              }`
                            : ""
                        }
                      ></span>
                    }
                  </button>
                </div>

                {menuItems[key].children && (
                  <ul className={`sub-menu ${isShow ? "hidden" : "block"}`}>
                    {menuItems[key].children?.map((child, index) => {
                      return (
                        <li key={index}>
                          <Link
                            to={"/Fideicomisos?fideicomisoId=" + child.id}
                            className={
                              fideicomisoId &&
                              fideicomisoId == child.id?.toString()
                                ? "active"
                                : ""
                            }
                          >
                            {child.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
