import axios from 'axios';
import DOMPurify from 'dompurify';
import React, { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Fideicomiso,
  FideicomisoPageContext,
  FideicomisoListas,
  FideicomisoFAQ,
  FideicomisoBeneficio,
  FideicomisoProyecto,
  FideicomisoHome,
  FideicomisoHeader,
  FideicomisoBono
} from './models/fideicomiso.models';

type FideicomisoPageProps = { children: React.ReactNode };
const FideicomisoContext = React.createContext<FideicomisoPageContext | undefined>(undefined);

type Message =
  | { type: 'inHome'; value: boolean }
  | { type: 'home'; value: FideicomisoHome }
  | { type: 'fideicomisoHeader'; value: Array<FideicomisoHeader> }
  | { type: 'fideicomiso'; value: Fideicomiso }
  | { type: 'otrosFideicomisos'; value: Array<Fideicomiso> }
  | { type: 'listas'; value: Array<FideicomisoListas> }
  | { type: 'faq'; value: Array<FideicomisoFAQ> }
  | { type: 'beneficios'; value: Array<FideicomisoBeneficio> }
  | { type: "bonos"; value: Array<FideicomisoBono>; }
  | { type: 'proyectos'; value: Array<FideicomisoProyecto> };

const reducer = (state: FideicomisoPageContext, message: Message) => {
  switch (message.type) {
    case 'inHome':
      return {
        ...state,
        InHome: message.value
      };
    case 'home':
      return {
        ...state,
        Home: message.value
      };
    case 'fideicomisoHeader':
      return {
        ...state,
        FideicomisoHeader: message.value
      };
    case 'fideicomiso':
      return {
        ...state,
        Fideicomiso: message.value
      };
    case 'otrosFideicomisos':
      return {
        ...state,
        OtrosFideicomisos: message.value
      };
    case 'listas':
      return {
        ...state,
        ListasFideicomiso: message.value
      };
    case 'faq':
      return {
        ...state,
        FAQFideicomiso: message.value
      };
    case 'beneficios':
      return {
        ...state,
        BeneficiosFideicomiso: message.value
      };
    case "bonos":
      return {
        ...state,
        Bonos: message.value,
      };
    case 'proyectos':
      return {
        ...state,
        ProyectosFideicomiso: message.value
      };
    default:
      return { ...state };
  }
};

const FideicomisoContextProvider = ({ children }: FideicomisoPageProps) => {
  const [fideicomiso, setFideicomiso] = useReducer(reducer, {} as FideicomisoPageContext);
  const location = useLocation();
  const [fideicomisoId, setFideicomisoId] = useState(new URLSearchParams(location.search).get('fideicomisoId') || 'Home');

  useEffect(() => {
    setFideicomisoId(new URLSearchParams(location.search).get('fideicomisoId') || 'Home');
  }, [location]);

  const fetchHome = useCallback(async () => {
    try {
      const home = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - Home')/items?$select=Title,Enlace,Descripcion,Created,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          let result: FideicomisoHome[] = [];

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((media: any) => {
              result.push({
                Title: media.Title,
                Thumbnail: media.AttachmentFiles.results.length > 0 ? media.AttachmentFiles.results[0].ServerRelativeUrl : '',
                Url: media.Enlace,
                Descripcion: media.Descripcion
              });
            });

          return result;
        });
      setFideicomiso({ type: 'home', value: home[0] });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchIntro = useCallback(async () => {
    try {
      const introFideicomiso = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos')/items?$select=Activo,Orden,Title,Resumen,Descripcion,Nota,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1 and Id eq ${fideicomisoId} &$orderby=Orden&$top=1`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          var result = {
            Titulo: '',
            Resumen: '',
            Imagen: '',
            Descripcion: '',
            Nota: '',
            Orden: 0
          };

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((intro: any) => {
              result = {
                Titulo: intro.Title,
                Resumen: intro.Resumen,
                Imagen: intro.Attachments ? intro.AttachmentFiles.results[0].ServerRelativeUrl : '',
                Descripcion: DOMPurify.sanitize(intro.Descripcion, {
                  USE_PROFILES: { html: true }
                }),
                Orden: intro.Orden,
                Nota: intro.Nota
              };
            });

          return result;
        });

      setFideicomiso({ type: 'fideicomiso', value: introFideicomiso });
    } catch (error) {
      console.log(error);
    }
  }, [fideicomisoId]);

  const fetchFideicomisosHeader = useCallback(async () => {
    try {
      const fideicomisosHeader = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - Header')/items?$select=ID,Title,Fideicomiso/Title,Attachments,AttachmentFiles&$expand=AttachmentFiles,Fideicomiso`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          var result = Array<FideicomisoHeader>();

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((header: any) => {
              result.push({
                Title: header.Title,
                Banner: header.Attachments ? header.AttachmentFiles.results[0].ServerRelativeUrl : '',
                Parent: header.Fideicomiso.Title
              });
            });

          return result;
        });

      setFideicomiso({ type: 'fideicomisoHeader', value: fideicomisosHeader });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchOtrosFideicomisos = useCallback(async () => {
    try {
      const otrosFideicomiso = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos')/items?$select=ID,Activo,Orden,Title,PadreId&$filter=Activo eq 1&$orderby=Orden`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          var result = Array<Fideicomiso>();

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((otros: any) => {
              result.push({
                Id: otros.ID,
                Titulo: otros.Title,
                PadreId: otros.PadreId
              });
            });

          return result;
        });

      setFideicomiso({ type: 'otrosFideicomisos', value: otrosFideicomiso });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const fetchListas = useCallback(async () => {
    try {
      const listas = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - Listas')/items?$select=Activo,Orden,Title,Contenido,Lista,TipoLista,FideicomisoId&$filter=Activo eq 1 and Fideicomiso/ID eq ${fideicomisoId} &$orderby=Orden`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          let result: Array<FideicomisoListas> = [];

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((lista: any) => {
              result.push({
                Titulo: lista.Title,
                Contenido: DOMPurify.sanitize(lista.Contenido, {
                  USE_PROFILES: { html: true }
                }),
                Lista: lista.Lista,
                Orden: lista.Orden,
                Tipo: lista.TipoLista
              });
            });

          return result;
        });

      setFideicomiso({ type: 'listas', value: listas });
    } catch (error) {
      console.log(error);
    }
  }, [fideicomisoId]);

  const fetchFAQ = useCallback(async () => {
    try {
      const preguntas = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - FAQ')/items?$select=Activo,Orden,Title,Contenido,FideicomisoId&$filter=Activo eq 1 and Fideicomiso/ID eq ${fideicomisoId} &$orderby=Orden`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          let result: Array<FideicomisoFAQ> = [];

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((pregunta: any) => {
              result.push({
                Titulo: pregunta.Title,
                Contenido: DOMPurify.sanitize(pregunta.Contenido, {
                  USE_PROFILES: { html: true }
                }),
                Orden: pregunta.Orden
              });
            });

          return result;
        });

      setFideicomiso({ type: 'faq', value: preguntas });
    } catch (error) {
      console.log(error);
    }
  }, [fideicomisoId]);

  const fetchBeneficios = useCallback(async () => {
    try {
      const beneficios = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - Beneficios')/items?$select=Activo,Orden,Title,Enlace,Descripcion,TipoMedia,Attachments,AttachmentFiles,FideicomisoId&$expand=AttachmentFiles&$filter=Activo eq 1 and Fideicomiso/ID eq ${fideicomisoId} &$orderby=Orden`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          let result: Array<FideicomisoBeneficio> = [];

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((beneficio: any) => {
              result.push({
                Titulo: beneficio.Title,
                Enlace: beneficio.Enlace,
                Thumbnail: beneficio.Attachments ? beneficio.AttachmentFiles.results[0].ServerRelativeUrl : '',
                TipoMedia: beneficio.TipoMedia,
                Orden: beneficio.Orden,
                Descripcion: beneficio.Descripcion
              });
            });

          return result;
        });

      setFideicomiso({ type: 'beneficios', value: beneficios });
    } catch (error) {
      console.log(error);
    }
  }, [fideicomisoId]);

  const fetchBonos = useCallback(async () => {
    try {
      const bonos = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - Bonos')/items?$select=ID,Activo,Orden,Title,FideicomisoId,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1 and Fideicomiso/ID eq ${fideicomisoId} &$orderby=Orden`,
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: Array<FideicomisoBono> = [];

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((bono: any) => {
              result.push({
                Id: bono.ID,
                Title: bono.Title,
                Icono: bono.Attachments
                  ? bono.AttachmentFiles.results[0].ServerRelativeUrl
                  : ""
              });
            });

          return result;
        });

      setFideicomiso({ type: "bonos", value: bonos });
    } catch (error) {
      console.log(error);
    }
  }, [fideicomisoId]);

  const fetchProyectos = useCallback(async () => {
    try {
      const proyectos = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - Proyectos')/items?$select=Activo,Orden,Title,Proyecto/ID,Proyecto/Title,Proyecto/Descripcion,Proyecto/Ciudad,Attachments,AttachmentFiles,FideicomisoId&$expand=AttachmentFiles,Proyecto&$filter=Activo eq 1 and Fideicomiso/ID eq ${fideicomisoId} &$orderby=Orden`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          let result: Array<FideicomisoProyecto> = [];

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((proyecto: any) => {
              result.push({
                Id: proyecto.Proyecto.ID,
                Titulo: proyecto.Proyecto.Title,
                Thumbnail: proyecto.Attachments ? proyecto.AttachmentFiles.results[0].ServerRelativeUrl : '',
                Descripcion: proyecto.Proyecto.Descripcion,
                Ubicacion: proyecto.Proyecto.Ciudad
              });
            });

          return result;
        });

      setFideicomiso({ type: 'proyectos', value: proyectos });
    } catch (error) {
      console.log(error);
    }
  }, [fideicomisoId]);

  useEffect(() => {
    fetchOtrosFideicomisos();
    if (fideicomisoId === 'Home') {
      fetchHome();
      setFideicomiso({ type: 'inHome', value: fideicomisoId === 'Home' });
    } else {
      fetchIntro();
      fetchFideicomisosHeader();
      fetchListas();
      fetchFAQ();
      fetchBeneficios();
      fetchProyectos();
      fetchBonos();
      setFideicomiso({ type: 'inHome', value: fideicomisoId === 'Home' });
    }
  }, [
    location,
    fideicomisoId,
    fetchHome,
    fetchFideicomisosHeader,
    fetchBeneficios,
    fetchFAQ,
    fetchIntro,
    fetchListas,
    fetchOtrosFideicomisos,
    fetchProyectos
  ]);

  return <FideicomisoContext.Provider value={fideicomiso}>{children}</FideicomisoContext.Provider>;
};

const useFideicomiso = () => {
  const context = useContext(FideicomisoContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};

export { useFideicomiso, FideicomisoContextProvider };

