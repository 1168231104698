export interface DynamicImageProps {
    src?: string,
    alt?: string,
    className?: string
}

export function DynamicImage(props: DynamicImageProps) {
    return (
        <img className={props.className} src={props.src} alt={props.alt} />
    );
}