import { config } from '../../../config.js';

export interface StaticImageProps {
    src?: string,
    alt?: string,
    className?: string
}

export function StaticImage(props: StaticImageProps) {
    return (
        <img src={(config.prefixes.imagePrefix || "") + (props.src?.startsWith('/') ? props.src.replace('/', '') : props.src)} alt={props.alt} className={props.className} />
    );
}