import "./checkList.css";

export interface CheckListProps {
    className?: string;
    listElements?: CheckListItem[];
    type: string; //"check" | "number" | "bullet"
    htmlContent?: boolean;
}

export interface CheckListItem {
    className?: string,
    text?: string;
}

export function CheckList(props: CheckListProps) {
    return (
        <ul className={"custom-list " + props.type.toLowerCase() + "-list " + (props.className ? " " + props.className : "")}>
            {
                props.listElements?.map((listItem: any, index: number) => {
                    if (!props.htmlContent)
                        return (
                            <li key={'check-item-' + index} className={(listItem.className ? " " + listItem.className : "")}>
                                {listItem.text}
                            </li>
                        );
                    else
                        return (
                            <li key={'check-item-' + index} className={(listItem.className ? " " + listItem.className : "")} dangerouslySetInnerHTML={{ __html: listItem.text }}></li>
                        );
                })
            }
        </ul>
    );
}
