import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import React, { useEffect } from 'react';
import { DynamicImage } from '../base/dynamicImage/dynamicImage';

export interface ProjectPhotoGalleryProps {
  prices: { base: number; top: number; currency: string };
  images?: string[];
}
export const ProjectPhotoGallery = (props: ProjectPhotoGalleryProps) => {
  const { images, prices } = props;

  const mainSlider = React.createRef<Splide>();
  const thumbSlider = React.createRef<Splide>();

  const primarySliderOptions = {
    type: 'fade',
    heightRatio: 0.5,
    pagination: false,
    arrows: false,
    cover: true
  };

  const thumbSliderOptions = {
    rewind: true,
    fixedWidth: 245,
    fixedHeight: 154,
    isNavigation: true,
    gap: 34,
    arrows: false,
    pagination: false,
    cover: true,
    breakpoints: {
      '600': {
        fixedWidth: 150,
        fixedHeight: 84
      }
    }
  };

  useEffect(() => {
    if (mainSlider && mainSlider.current && thumbSlider && thumbSlider.current && thumbSlider.current.splide)
      mainSlider.current.sync(thumbSlider.current.splide);
  }, [mainSlider, thumbSlider]);

  return (
    <div className='project-detail__slide'>
      <Splide id='primary-slider' className='project-slide' ref={mainSlider} options={primarySliderOptions}>
        {images?.map((imageUrl, index) => (
          <SplideSlide key={index}>
            <div className='hidden xl:block project-price'>
              <span className='project-price__tag'>PRECIO</span>
              <h3 className='project-price__number'>
                {`Desde: ${prices.currency} `}
                {new Intl.NumberFormat().format(prices?.base)}
              </h3>
              {/* <h3 className='project-price__number'>
                {'Hasta RD$'}
                {new Intl.NumberFormat().format(prices?.top)}
              </h3> */}
            </div>
            <DynamicImage src={imageUrl} alt='Imagen de la propiedad'></DynamicImage>
          </SplideSlide>
        ))}
      </Splide>
      <Splide ref={thumbSlider} id='secondary-slider' className='thumbs' options={thumbSliderOptions}>
        {images?.map((imageUrl, index) => (
          <SplideSlide key={index}>
            <DynamicImage src={imageUrl} alt='Miniatura de imagen de la propiedad'></DynamicImage>
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};
