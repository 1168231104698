import { DynamicImage } from "../../base/dynamicImage/dynamicImage";
import "./mediaHorizontalCard.css";

export interface HorizontalMediaCardProps {
    className?: string;
    title: string;
    descripcion: string;
    url: string;
    thumbnail: string;
    iconClass: string;
    lightbox: boolean;
}

export function HorizontalMediaCard(props: HorizontalMediaCardProps) {
    return (
        <div className="horizontal-media-card">
            <a href={props.url} className={"horizontal-media-card-box" + (props.lightbox ? " glightbox" : "")} target="_blank">
                <div className="overlay">
                    <div className="circle">
                        <span className={'feather ' + props.iconClass}></span>
                    </div>
                </div>
                <DynamicImage src={props.thumbnail} alt={"Imagen de " + props.title}></DynamicImage>
            </a>
            <div className="horizontal-media-card-text">
                <p className="horizontal-media-card-paragraph">{props.descripcion}</p>
            </div>
        </div>
    );
}
