import React, { useEffect, useState } from "react";

import axios from "axios";

export const TransparenciaContext = React.createContext({
  portada: { Title: "", Contenido: "", Subtitulo: "" },
  sideMenuProps: [{ id: 0, title: "", route: "" }],
  isHidden: true,
  currDate: "",
  searchInput: "",
  currDocSelection: [],
  showDocuments: false,
  currDocType: "",
  menuTitleActive: "Portada",
  changeCurrDocType: (value: any) => {},
  changeCurrDate: (value: any) => {},
  handleSearchInput: (value: any) => {},
  handleToggle: () => {},
  handleShowDocuments: () => {},
  handleMenuTitleClick: (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {},
  resetShowDoc: () => {},
});

type TransparenciaProps = {
  children?: React.ReactNode;
};

function TransparenciaData({ children }: TransparenciaProps) {
  const [portada, setPortada] = useState({
    Title: "",
    Contenido: "",
    Subtitulo: "",
  });
  const [currDocType, setCurrDocType] = useState("");
  const [isHidden, setIsHidden] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [currDate, setCurrDate] = useState("");
  const [currDocSelection, setCurrDocSelection] = useState([]);
  const [showDocuments, setShowDocuments] = useState(false);
  const [menuTitleActive, setMenuTitleActive] = useState("Portada");

  const sideMenuProps: any = [
    { id: 1, title: "Portada", route: "/Transparencia/Portada" },
    {
      id: 2,
      title: "Compras y Contrataciones",
      route: "/Transparencia/ComprasYContrataciones",
    },
    {
      id: 3,
      title: "Hechos Relevantes",
      route: "/Transparencia/HechosRelevantes",
    },
    {
      id: 4,
      title: "Fiduciaria",
      route: "/Transparencia/Fiduciaria",
    },
  ];
  async function fetchPortada() {
    try {
      const Portada = await axios
        .get("/_api/web/lists/GetByTitle('Portada-de-Transparencia')/items", {
          headers: {
            Accept: "application/json; odata=nometadata",
            "Content-Type": "application/json;odata=nometadata",
          },
        })
        .then((response) => response.data.value)
        .catch((e) => console.error(e));

      setPortada({
        Title: Portada[0]?.Title,
        Contenido: Portada[0]?.Contenido,
        Subtitulo: Portada[0]?.Subtitulo,
      });
    } catch (error) {
      console.log(error);
    }
  }
  const getCurrentDocSelection = async () => {
    let resultsArr = await axios
      .get(
        `/_api/web/GetFolderByServerRelativeUrl('/Documentos de Transparencia/Files')/Files?$expand=ListItemAllFields${
          currDocType ? `&$filter=Title eq '${currDocType}'` : ""
        }${filterOrderDate()}`
      )
      .then((response) => response.data.value)
      .catch((e) => console.error(e));
    resultsArr = resultsArr.filter(
      (result: { ListItemAllFields: { Section: string } }) =>
        result.ListItemAllFields.Section === menuTitleActive
    );
    setCurrDocSelection(
      resultsArr
        .filter((term: any) => {
          if (searchInput.length === 0) {
            return term;
          } else {
            const specialChars: any = {
              á: "a",
              é: "e",
              í: "i",
              ó: "o",
              ú: "u",
            };
            const adjustedName = term.Name.toLowerCase().replace(
              /[^A-Za-z0-9[\] ]/g,
              (a: string | number) => specialChars[a] || a
            );
            const adjustedInput = searchInput
              .toLowerCase()
              .replace(
                /[^A-Za-z0-9[\] ]/g,
                (a: string | number) => specialChars[a] || a
              );
            return adjustedName.includes(adjustedInput);
          }
        })
        .map((item: any) => {
          return {
            nombre: item?.Name,
            referencia: item?.ListItemAllFields.Referencia_x002d_Documento,
            descripcion: item?.ListItemAllFields.Descripcion_x002d_Documento,
            id: item?.ListItemAllFields.ID,
            peso: item?.Length,
            modificado: item?.ListItemAllFields.Modified,
            tipo: item?.Title,
            creacion: item?.ListItemAllFields.Created,
            clasificacion: item?.ListItemAllFields.Padre_x002d_Documento,
            enlace: item?.ServerRelativeUrl,
            section: item?.ListItemAllFields?.Section,
          };
        })
    );
  };

  const handleSearchInput = function (value: any) {
    setSearchInput(value);
  };
  const changeCurrDocType = function (value: any) {
    setCurrDocType(value);
  };
  const changeCurrDate = function (value: any) {
    setCurrDate(value);
  };
  const resetShowDoc = function () {
    setShowDocuments(false);
    setCurrDate("");
    setCurrDocType("");
    setSearchInput("");
  };
  function filterOrderDate() {
    if (currDate) {
      return `&$orderby=TimeLastModified ${currDate}`;
    }
    return "";
  }
  const handleToggle = () => {
    setIsHidden(!isHidden);
  };
  const handleShowDocuments = () => {
    setShowDocuments(!showDocuments);
    setCurrDate("");
    setCurrDocType("");
    setSearchInput("");
  };
  const handleMenuTitleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const activateTitle: any = (event.target as HTMLLIElement)?.textContent;

    setMenuTitleActive(activateTitle);
  };
  useEffect(() => {
    getCurrentDocSelection();
  }, [currDocType, searchInput, currDate, menuTitleActive]);

  useEffect(() => {
    fetchPortada();
  }, []);

  return (
    <TransparenciaContext.Provider
      value={{
        portada,
        sideMenuProps,
        isHidden,
        currDate,
        searchInput,
        currDocSelection,
        showDocuments,
        currDocType,
        menuTitleActive,
        changeCurrDocType,
        changeCurrDate,
        handleToggle,
        handleShowDocuments,
        handleSearchInput,
        handleMenuTitleClick,
        resetShowDoc,
      }}
    >
      {children}
    </TransparenciaContext.Provider>
  );
}
export default TransparenciaData;
