import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { FamiliaReservasMockData } from "./mock/FamiliaReservas.mock";
import {
  FamiliaReservas,
  FamiliaReservasElemento,
  FamiliaReservasHeader,
} from "./models/FamiliaReservas.model";

type FamiliaReservasProviderProps = { children: React.ReactNode };
const FamiliaReservasContext = React.createContext<FamiliaReservas | undefined>(
  undefined
);

type Message =
  | { type: "familia"; value: FamiliaReservasElemento[] | undefined }
  | { type: "header"; value: FamiliaReservasHeader | undefined };

const reducer = (state: FamiliaReservas, message: Message) => {
  switch (message.type) {
    case "familia":
      return {
        ...state,
        Elementos: message.value,
      };
    case "header":
      return {
        ...state,
        Header: message.value,
      };
    default:
      return { ...state };
  }
};
const FamiliaReservasContextProvider = ({
  children,
}: FamiliaReservasProviderProps) => {
  const [FamiliaReservas, setFamiliaReservas] = useReducer(
    reducer,
    {} as FamiliaReservas
  );

  useEffect(() => {
    fetchFamiliaReservas();
    fetchFamiliaReservaHeader();
  }, []);

  async function fetchFamiliaReservas() {
    try {
      const familiaReservas = await axios
        .get(
          "/Nosotros/_api/web/lists/GetByTitle('Nosotros - Familia Reservas Filiales')/items?$select=Activo,Title,Contenido,Orden,Url,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1",
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: FamiliaReservasElemento[] = [];

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          ) {
            result = response.data.d.results.map(
              (detail: any): FamiliaReservasElemento => ({
                detalle: detail.Contenido,
                image: detail.Attachments
                  ? detail.AttachmentFiles.results[0].ServerRelativeUrl
                  : "",
                position: detail.Orden,
                url: detail.Url.Url,
              })
            );
          }

          return result;
        });
      setFamiliaReservas({ type: "familia", value: familiaReservas });
      // const mockData = FamiliaReservasMockData.Elementos;
      // setFamiliaReservas({ type: 'familia', value: mockData });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchFamiliaReservaHeader() {
    try {
      const familiaReservas = await axios
        .get(
          "/Nosotros/_api/web/lists/GetByTitle('Nosotros - Familia Reservas')/items?$select=Activo,Title,Subtitulo&$filter=Activo eq 1",
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: FamiliaReservasHeader[] = [];

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          ) {
            result = response.data.d.results.map(
              (detail: any): FamiliaReservasHeader => ({
                Titulo: detail.Title,
                Subtitulo: detail.Subtitulo,
              })
            );
          }
          return result;
        });
      setFamiliaReservas({ type: "header", value: familiaReservas[0] });

      // const mockData = FamiliaReservasMockData;
      // setFamiliaReservas({ type: 'header', value: mockData.Header });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <FamiliaReservasContext.Provider value={FamiliaReservas}>
      {children}
    </FamiliaReservasContext.Provider>
  );
};
const useFamiliaReservas = () => {
  const context = useContext(FamiliaReservasContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useFamiliaReservas, FamiliaReservasContextProvider };
