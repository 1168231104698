import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { terminosDeUsoMockData } from "./mock/TerminosDeUso.mock";
import { TerminosDeUso } from "./models/TerminosDeUso.model";


type TerminosDeUsoProviderProps = { children: React.ReactNode; };
const TerminosDeUsoContext = React.createContext<TerminosDeUso>({} as TerminosDeUso);

type Message = { type: 'terminos'; value: TerminosDeUso; };

const reducer = (state: TerminosDeUso, message: Message): TerminosDeUso => {
    switch (message.type) {
        case 'terminos':
            return { ...state, ...message.value };
        default:
            return { ...state };
    }
};

const TerminosDeUsoContextProvider = ({ children }: TerminosDeUsoProviderProps) => {
    const [state, dispatch] = useReducer(reducer, {} as TerminosDeUso);

    useEffect(() => {
        fetchDocuments();
    }, []);

    async function fetchDocuments() {
        try {
            const terminos = await axios.get("/_api/web/lists/GetByTitle('Terminos De Uso')/items?$select=Title,Contenido",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: TerminosDeUso[] = [];

                if (response.status === 200 && response.data && response.data.d && response.data.d.results)
                    response.data.d.results.forEach((terminos: any) => {
                        result.push({
                            title: terminos.Title,
                            content: terminos.Contenido
                        });
                    });

                return result;
            });

            dispatch({ type: 'terminos', value: terminos[0] });

            // const mockData = terminosDeUsoMockData;
            // dispatch({ type: 'terminos', value: mockData });

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <TerminosDeUsoContext.Provider value={state} >
            {children}
        </TerminosDeUsoContext.Provider >
    );
};

const useTerminosDeUso = () => {
    const context = useContext(TerminosDeUsoContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { useTerminosDeUso, TerminosDeUsoContextProvider };

