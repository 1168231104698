import axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { SiteMapMockData } from "./mock/SiteMap.mock";
import { Module, Page, SiteMap } from "./models/SiteMap.model";

type SiteMapProviderProps = { children: React.ReactNode };
const SiteMapContext = React.createContext<SiteMap>({} as SiteMap);

type Message =
  | { type: "modules"; value: Module[] }
  | { type: "pages"; value: Module[] };

const reducer = (state: SiteMap, message: Message): SiteMap => {
  switch (message.type) {
    case "modules":
      return { ...state, modules: message.value };
    case "pages":
      return { ...state, modules: message.value };
    default:
      return { ...state };
  }
};

const SiteMapContextProvider = ({ children }: SiteMapProviderProps) => {
  const [state, dispatch] = useReducer(reducer, {} as SiteMap);
  const [hasFechedThePages, setHasFechedThePages] = useState(false);

  async function fetchModules() {
    try {
      const modules = await axios
        .get(
          "/_api/web/lists/GetByTitle('Sitio - Modulos')/items?$select=Title,Link,ID",
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: Module[] = [];

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          ) {
            response.data.d.results.forEach((modules: any) => {
              result.push({
                id: modules.ID,
                title: modules.Title,
                link: modules.Link,
              });
            });
          }

          return result;
        });

      dispatch({ type: "modules", value: modules });

      // const mockData = SiteMapMockData.modules;
      // dispatch({ type: "modules", value: mockData });
    } catch (error) {
      console.log(error);
    }
  }

  const fetchPages = useCallback(async () => {
    try {
      const modules = await axios
        .get(
          "/_api/web/lists/GetByTitle('Sitio - Paginas')/items?$select=Title,Descripcion,Link,ModuloId",
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let pages: Page[] = [];
          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          ) {
            response.data.d.results.forEach((_pages: any) => {
              pages.push({
                title: _pages.Title,
                link: _pages.Link,
                description: _pages.Descripcion,
                parent: _pages.ModuloId,
              });
            });
          }

          const _modules: Module[] = state.modules.map((module) => {
            return {
              id: module.id,
              title: module.title,
              link: module.link,
              pages: pages.filter((page) => page.parent === module.id),
            };
          });

          return _modules;
        });
      setHasFechedThePages(true);
      dispatch({ type: "pages", value: modules });

      //debug
      // const _modules: Module[] = state.modules.map((module) => {
      //     return {
      //         title: module.title,
      //         link: module.link,
      //         pages: module.pages?.filter((page) => page.parent === module.title),
      //     };
      // });
      // dispatch({ type: "pages", value: _modules });
    } catch (error) {
      console.log(error);
    }
  }, [state.modules]);

  useEffect(() => {
    fetchModules();
  }, []);

  useEffect(() => {
    if (state.modules && !hasFechedThePages) {
      fetchPages();
    }
  }, [state.modules]);

  return (
    <SiteMapContext.Provider value={state}>{children}</SiteMapContext.Provider>
  );
};

const useSiteMap = () => {
  const context = useContext(SiteMapContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useSiteMap, SiteMapContextProvider };
