import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { QuienesSomosMockData } from "./mock/QuienesSomos.mock";
import { QuienesSomos, QuienesSomosHeader } from "./models/QuienesSomos.model";



type QuienesSomosProviderProps = { children: React.ReactNode; };
const QuienesSomosContext = React.createContext<QuienesSomos | undefined>(undefined);

type Message = { type: 'header'; value: QuienesSomosHeader | undefined; };

const reducer = (state: QuienesSomos, message: Message) => {
    switch (message.type) {
        case 'header':
            return {
                ...state, Header: message.value
            };
        default:
            return { ...state };
    }
};
const QuienesSomosContextProvider = ({ children }: QuienesSomosProviderProps) => {

    const [quienesSomos, setQuienesSomos] = useReducer(reducer, {} as QuienesSomos);



    useEffect(() => {
        fetchQuienesSomos();
    }, []);

    async function fetchQuienesSomos() {
        try {
            const quienesSomos = await axios.get("/Nosotros/_api/web/lists/GetByTitle('Nosotros - Quienes Somos Detalle')/items?$select=Activo,Title,SubTitulo,Contenido,Orden,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: QuienesSomosHeader[] = [];

                if (response.status === 200 && response.data && response.data.d && response.data.d.results) {
                    result = response.data.d.results.map((detail: any): QuienesSomosHeader => (
                        {
                            Title: detail.Title,
                            Subtitulo: detail.SubTitulo,
                            Image: detail.Attachments ? detail.AttachmentFiles.results[0].ServerRelativeUrl : "",
                            Contenido: detail.Contenido
                        }
                    ));
                }
                return result;
            });
            setQuienesSomos({ type: 'header', value: quienesSomos[0] });
            // const mockData = QuienesSomosMockData.Header;
            // setQuienesSomos({ type: 'header', value: mockData });

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <QuienesSomosContext.Provider value={quienesSomos}>
            {children}
        </QuienesSomosContext.Provider>
    );

};
const useQuienesSomos = () => {
    const context = useContext(QuienesSomosContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { useQuienesSomos, QuienesSomosContextProvider };