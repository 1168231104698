import { Link } from "react-router-dom";
import { News } from "../../../context/Noticias/models/News.models";
import SocialNewsLink from "./SocialNewsLink";

export interface NewsCardProps {
  news: News;
}

export const NewsCard = (props: NewsCardProps) => {
  const { news } = props;

  return (
    <>
      <div className="container mx-auto">
        <div className="noticias__box lg:flex lg:space-x-4">
          <div className="noticias__text lg:w-9/12">
            <Link to={"/Noticias/Noticia?noticiaId=" + news.id}>
              <h3 className="noticias__title">{news.title}</h3>
            </Link>
            <div className="noticias__date">
              <h4>
                <span className="feather icon-calendar"></span>
                {news.dateString}
              </h4>
            </div>

            {!news.externalLink && (
              <div
                className="noticias__parragraf"
                dangerouslySetInnerHTML={{
                  __html: news.content.split("</p>")[0] || news.content,
                }}
              ></div>
            )}
            {news.externalLink && (
              <div>
                <SocialNewsLink
                  social={news?.social}
                  url={news?.externalLink}
                />
              </div>
            )}
          </div>

          <div className="noticias__image-box lg:w-3/12">
            <Link to={"/Noticias/Noticia?noticiaId=" + news.id}>
              <img
                src={news.imageThumbnailUrl}
                alt=""
                className="noticias__image"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
