import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useInterval from "../../hooks/useInterval";
import { StaticImage } from "../base/staticImage/staticImage";
import { Transition } from "@headlessui/react";
import axios from "axios";
import { Page } from "../../context/SiteMap/models/SiteMap.model";
export const Header = () => {
  const [headerAnimationActive, setHeaderAnimationActive] = useState(1);
  const [showAddress, setShowAddress] = useState(false);

  useInterval(() => {
    setHeaderAnimationActive(
      headerAnimationActive === 4 ? 1 : headerAnimationActive + 1
    );
  }, 4500);

  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [menuChildOpen, setMenuChildOpen] = useState(0);
  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleSearch = () => setSearchOpen(!searchOpen);
  const toggleMenuChild = (ulNum: number) => setMenuChildOpen(ulNum);

  const [searchInput, setSearchInput] = useState("");
  const [defaulSearchResult, setDefaulSearchResult] = useState<Page[]>([]);
  const [searchResults, setSearchResults] = useState<Page[]>([]);

  const fetchSearchData = useCallback(async () => {
    return await axios
      .get(
        `/_api/web/lists/GetByTitle('Sitio - Paginas')/items?$select=Title,Descripcion,Link`,
        {
          headers: {
            Accept: "application/json;odata=verbose",
            "Content-Type": "application/json;odata=verbose",
          },
        }
      )
      .then((response) => {
        var result = Array<Page>();

        if (
          response.status === 200 &&
          response.data &&
          response.data.d &&
          response.data.d.results
        ) {
          response.data.d.results.forEach((_pages: any) => {
            result.push({
              title: _pages.Title,
              link: _pages.Link,
              description: _pages.Descripcion,
              parent: 0,
            });
          });
        }
        setDefaulSearchResult(result);
      });
  }, []);

  useEffect(() => {
    if (searchInput.trim() === "") {
      setSearchResults([]);
      return;
    }
    const filtered = defaulSearchResult.filter((result) => {
      return (
        result.title.toLowerCase().includes(searchInput.toLowerCase()) ||
        result.description.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    setSearchResults(filtered);
  }, [defaulSearchResult, searchInput]);

  useEffect(() => {
    fetchSearchData();
  }, [fetchSearchData]);

  return (
    <nav className="navigation" id="navigation">
      <div
        className={
          "transition duration-150 overlay-sidemenu" +
          (menuOpen || searchOpen ? "" : "hidden")
        }
        onClick={() => {
          if (menuOpen) {
            setMenuOpen(false);
          }
          if (searchOpen) {
            setSearchOpen(false);
          }
        }}
      ></div>
      <div className="hidden transition duration-150 overlay-search"></div>
      <div className="py-4 navigation__topbar">
        <div className="container mx-auto">
          <div className="block md:grid lg:gap-6 lg:grid-cols-2">
            <div className=" column">
              <div
                className="relative"
                id="addressActivator"
                onClick={() => setShowAddress(!showAddress)}
              >
                <div className="relative">
                  <div
                    style={{ height: "30px" }}
                    className="relative flex overflow-x-hidden overflow-y-hidden"
                  >
                    <Transition
                      className="absolute flex items-center"
                      show={headerAnimationActive === 1}
                      enter="transition duration-2000"
                      enterFrom="transform translate-y-full"
                      enterTo="transform translate-y-0"
                      leave="transition duration-2000"
                      leaveFrom="transform"
                      leaveTo="transform -translate-y-full"
                    >
                      <span className="text-white feather icon-map-pin"></span>

                      <span
                        id="navigationAddress"
                        className="flex-1 transition ease-out navigation__address"
                      >
                        Ave. Roberto Pastoriza #358, Torre Roberto Pastoriza
                      </span>
                      <span className="text-white navigation__icon justify-items-end">
                        <button
                          type="button"
                          id="btnAdress"
                          className="btnadress"
                          onClick={() => setShowAddress(!showAddress)}
                        >
                          <span className="feather icon-chevron-down"></span>
                        </button>
                      </span>
                    </Transition>
                    <Transition
                      className="absolute flex items-center"
                      show={headerAnimationActive === 2}
                      enter="transition duration-2000"
                      enterFrom="transform translate-y-full"
                      enterTo="transform translate-y-0"
                      leave="transition duration-2000"
                      leaveFrom="transform"
                      leaveTo="transform -translate-y-full"
                    >
                      <span className="text-white feather icon-calendar"></span>
                      <span className="flex-1 transition ease-out navigation__address">
                        <strong>Horario: </strong>
                        Lunes a viernes de 8:00 a.m. a 5:00 p.m.
                      </span>
                      <span className="text-white navigation__icon justify-items-end">
                        <button
                          type="button"
                          id="btnAdress"
                          className="btnadress"
                          onClick={() => setShowAddress(!showAddress)}
                        >
                          <span className="feather icon-chevron-down"></span>
                        </button>
                      </span>
                    </Transition>
                    <Transition
                      className="absolute flex items-center"
                      show={headerAnimationActive === 3}
                      enter="transition duration-2000"
                      enterFrom="transform translate-y-full"
                      enterTo="transform translate-y-0"
                      leave="transition duration-2000"
                      leaveFrom="transform"
                      leaveTo="transform -translate-y-full"
                    >
                      <span className="text-white feather icon-phone"></span>
                      <span className="flex-1 transition ease-out navigation__address">
                        <strong>Telefono: </strong>(809) 960-4580
                      </span>
                      <span className="text-white navigation__icon justify-items-end">
                        <button
                          type="button"
                          id="btnAdress"
                          className="btnadress"
                          onClick={() => setShowAddress(!showAddress)}
                        >
                          <span className="feather icon-chevron-down"></span>
                        </button>
                      </span>
                    </Transition>
                    <Transition
                      className="absolute flex items-center"
                      show={headerAnimationActive === 4}
                      enter="transition duration-2000"
                      enterFrom="transform translate-y-full"
                      enterTo="transform translate-y-0"
                      leave="transition duration-2000"
                      leaveFrom="transform"
                      leaveTo="transform -translate-y-full"
                    >
                      <span className="text-white feather icon-mail"></span>
                      <span className="flex-1 transition ease-out navigation__address">
                        <strong>Correo electrónico: </strong>
                        servicioalclienteFI@fiduciariareservas.com
                      </span>
                      <span className="text-white navigation__icon justify-items-end">
                        <button
                          type="button"
                          id="btnAdress"
                          className="btnadress"
                          onClick={() => setShowAddress(!showAddress)}
                        >
                          <span className="feather icon-chevron-down"></span>
                        </button>
                      </span>
                    </Transition>
                  </div>
                </div>
                <div
                  id="adressModal"
                  className={
                    "transition duration-500 ease-out transform adressmodal" +
                    (showAddress ? "" : " -translate-y-full")
                  }
                >
                  <h2 className="adressmodal__info">
                    <span className="feather icon-map-pin"></span>
                    <a
                      href="https://goo.gl/maps/WNArbX1i1fFcuyvw6"
                      className="adressmodal__info-text"
                    >
                      Ave. Roberto Pastoriza #358, Torre Roberto Pastoriza, Piso
                      7, Ensanche Piantini, Santo Domingo, D. N., República
                      Dominicana.
                    </a>
                  </h2>
                  <h2 className="adressmodal__info">
                    <span className="feather icon-calendar"></span>
                    <span className="adressmodal__info-text">
                      <strong>Horario: </strong> Lunes a viernes de 8:00 a.m. a
                      5:00 p.m.
                    </span>
                  </h2>
                  <h2 className="adressmodal__info">
                    <span className="feather icon-phone"></span>
                    <a href="tel:8099604580" className="adressmodal__info-text">
                      <strong>Teléfono: </strong>(809) 960-4580
                    </a>
                  </h2>
                  <h2 className="adressmodal__info">
                    <span className="feather icon-mail"></span>
                    <a
                      href="mailto:servicioalclienteFI@fiduciariareservas.com"
                      className="adressmodal__info-text"
                    >
                      <strong className="block">Correo electrónico: </strong>
                      servicioalclienteFI@fiduciariareservas.com
                    </a>
                  </h2>
                </div>
              </div>
            </div>
            <div className="hidden column xl:block">
              <ul className="flex items-center justify-end h-full divide-x navigation__linklist">
                <li>
                  <Link to="/Educativa">Conoce Más</Link>
                </li>
                <li>
                  <Link to="/PreguntasFrecuentes">Preguntas Frecuentes</Link>
                </li>
                <li>
                  <Link to="/Noticias">Sala de Prensa</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <div id="searchContainer"> */}
      <Transition
        as="div"
        id="searchModal"
        unmount={false}
        show={searchOpen}
        enter="transition ease-out transform duration-400"
        enterFrom="-translate-y-full"
        enterTo=""
        leave="transition ease-out transform duration-400"
        leaveFrom=""
        leaveTo="-translate-y-full"
        className="adressmodal adressmodal--search"
      >
        <button
          type="button"
          onClick={() => {
            toggleSearch();
            setSearchInput("");
          }}
          id="closeSearch"
          className="adressmodal__close"
        >
          <span className="">CERRAR</span>
        </button>
        <div className="form-box">
          <form action="">
            <div className="form-control form-control--icon">
              <span className="feather icon-search form-control__icon"></span>
              <input
                placeholder="Buscar"
                className="generic-input"
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <button
                type="button"
                className="form-clear"
                onClick={() => setSearchInput("")}
              >
                <span className="feather icon-x form-control__icon"></span>
              </button>
            </div>
          </form>
          <div className={`search-mid ${searchResults.length > 0 && " open"}`}>
            {searchResults.map((result) => (
              <Link
                to={
                  "/" +
                  result?.parent +
                  (result?.link?.startsWith("?") ? "" : "/") +
                  (result?.link ? result?.link : "")
                }
                onClick={() => {
                  setSearchOpen(false);
                }}
              >
                <div className="search-mid__result">
                  <span className="search-mid__text">{result.title}</span>
                  <p className="search-mid__parragraf">{result.description}</p>
                </div>
              </Link>
            ))}
          </div>
          <div className="xl:block search-bottom">
            <h3 className="search-bottom__question">
              ¿No encuentras lo que estas buscando?
            </h3>
            <div className="search-bottom__link-question ">
              <ul className="search-bottom__list">
                <li>
                  <Link to={"Contacto"}>
                    <span>•</span> Contáctanos
                  </Link>
                </li>
                <li>
                  <Link to={"MapaDelSitio"}>
                    <span>•</span> Ir al mapa del sitio
                  </Link>
                </li>
              </ul>
              <StaticImage src="//img/logo-search.svg"></StaticImage>
            </div>
          </div>
        </div>
      </Transition>
      {/* </div> */}
      <Transition
        show={menuOpen}
        enter="transition ease-out transform duration-400"
        enterFrom="-translate-x-full"
        enterTo=""
        leave="transition ease-out transform duration-400"
        leaveFrom=""
        leaveTo="-translate-x-full"
        className="sidemenu"
      >
        <div className="sidemenu__top">
          <StaticImage
            src="//img/logo-nav.svg"
            alt="Logo Fiduciaria Reservas"
          ></StaticImage>
          <button
            id="closeSideBar"
            className="sidemenu__close"
            type="button"
            onClick={() => toggleMenu()}
          >
            <span className="feather icon-x"></span>
          </button>
        </div>
        <div className="sidemenu__middle">
          {/* <Link className='sidemenu__link' to='/Nosotros'>
            <span>Nosotros</span>
          </Link> */}
          <div
            className={
              menuChildOpen === 1 ? "sidemenu__link active" : "sidemenu__link"
            }
            onClick={() => toggleMenuChild(1)}
          >
            <span>Nosotros</span>
            <ul
              className={
                menuChildOpen === 1
                  ? "menu-child-links open-child pt-4 pl-6"
                  : "hidden"
              }
            >
              <li>
                <Link className="sidemenu__sublink" to="/Nosotros/QuienesSomos">
                  <span>¿Quiénes Somos?</span>
                </Link>
              </li>
              <li>
                <Link className="sidemenu__sublink" to="/Nosotros/Historia">
                  <span>Historia</span>
                </Link>
              </li>
              <li>
                <Link
                  className="sidemenu__sublink"
                  to="/Nosotros/GobiernoCorporativo"
                >
                  <span>Gobierno Corporativo</span>
                </Link>
              </li>
              <li>
                <Link
                  className="sidemenu__sublink"
                  to="/Nosotros/EquipoEjecutivo"
                >
                  <span>Equipo Ejecutivo</span>
                </Link>
              </li>
              <li>
                <Link
                  className="sidemenu__sublink"
                  to="/Nosotros/FamiliaReservas"
                >
                  <span>Filiales Familia Reservas</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* <Link className='sidemenu__link' to='/Fideicomisos'>
            <span>Fideicomisos</span>
          </Link> */}
          <div
            className={
              menuChildOpen === 2 ? "sidemenu__link active" : "sidemenu__link"
            }
            onClick={() => toggleMenuChild(2)}
          >
            <span>Fideicomisos</span>
            <ul
              className={
                menuChildOpen === 2
                  ? "menu-child-links open-child pt-4 pl-6"
                  : "hidden"
              }
            >
              <li>
                <Link className="sidemenu__sublink" to="/Fideicomisos">
                  <span>Productos</span>
                </Link>
              </li>
              <li>
                <Link className="sidemenu__sublink" to="/Proyectos">
                  <span>Proyectos</span>
                </Link>
              </li>
            </ul>
          </div>
          <Link className="sidemenu__link chevronless" to="/Educativa">
            <span>Conoce Más</span>
          </Link>
          <Link
            className="sidemenu__link chevronless"
            to="/PreguntasFrecuentes"
          >
            <span>Preguntas Frecuentes</span>
          </Link>
          <Link className="sidemenu__link chevronless" to="/Noticias">
            <span>Sala De Prensa</span>
          </Link>
          <Link className="sidemenu__link chevronless" to="/Transparencia">
            <span>Transparencia</span>
          </Link>
          <Link
            className="sidemenu__link sidemenu__link--bottom chevronless"
            to="/Contacto"
          >
            <span>Contáctanos</span>
          </Link>
        </div>
        <div className="sidemenu__bottom">
          <a
            className="sidemenu__bold"
            href="https://fidureservas.sificloud.com/Agora/#/login"
          >
            Fiduciaria en línea
          </a>
        </div>
      </Transition>

      <div className="navigation__bottombar">
        <div
          className="container flex items-center justify-between mx-auto container--no-padding"
          style={{ padding: "0" }}
        >
          <button
            className="navigation__menu-icon xl:hidden"
            type="button"
            onClick={() => toggleMenu()}
          >
            {" "}
            <StaticImage
              src="//img/menu-icon.svg"
              alt="Ícono de menú"
            ></StaticImage>
          </button>
          <div className="navigation__main-nav">
            <Link to="/">
              <StaticImage
                className="navigation__logo"
                src="//img/logo-nav.svg"
                alt="Logo Fiduciaria Reservas"
              ></StaticImage>
            </Link>
          </div>
          <div className="flex items-center navigation__actions">
            <ul className="items-center justify-end hidden w-full xl:flex navigation__action-list">
              <li className="px-2 py-4 navigation__action-item">
                <a className="navigation__action-link" href={"##"}>
                  Nosotros
                </a>
                <div className="absolute hidden dropdown-menu">
                  <ul className="block w-full px-8 py-4 bg-white">
                    <li className="py-3">
                      <Link
                        to="/Nosotros/QuienesSomos"
                        className="sidemenu__sublink"
                      >
                        ¿Quiénes Somos?
                      </Link>
                    </li>
                    <li className="py-3 pl-3 lg:pl-0">
                      <Link
                        to="/Nosotros/Historia"
                        className="sidemenu__sublink"
                      >
                        Historia
                      </Link>
                    </li>
                    <li className="py-3 pl-3 lg:pl-0">
                      <Link
                        to="/Nosotros/GobiernoCorporativo"
                        className="sidemenu__sublink"
                      >
                        Gobierno Corporativo
                      </Link>
                    </li>
                    <li className="py-3 pl-3 lg:pl-0">
                      <Link
                        to="/Nosotros/EquipoEjecutivo"
                        className="sidemenu__sublink"
                      >
                        Equipo Ejecutivo
                      </Link>
                    </li>
                    <li className="py-3 pl-3 lg:pl-0">
                      <Link
                        to="/Nosotros/FamiliaReservas"
                        className="sidemenu__sublink"
                      >
                        Filiales Familia Reservas
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="px-2 py-4 navigation__action-item">
                <a className="navigation__action-link" href={"##"}>
                  Fideicomisos
                </a>
                <div className="absolute hidden dropdown-menu">
                  <ul className="block w-full px-8 py-4 bg-white">
                    <li className="py-3 pl-3 lg:pl-0">
                      <Link to="/Fideicomisos" className="sidemenu__sublink">
                        Productos
                      </Link>
                    </li>
                    <li className="py-3 pl-3 lg:pl-0">
                      <Link to="/Proyectos" className="sidemenu__sublink">
                        Proyectos
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="px-2 py-4 navigation__action-item">
                <Link className="navigation__action-link" to="/Contacto">
                  Contáctanos
                </Link>
              </li>
              <li className="px-2 py-4 navigation__action-item">
                <a
                  className="navigation__action-link navigation__action-link--highlight"
                  href="https://fidureservas.sificloud.com/Agora/#/login"
                >
                  Fiduciaria en línea
                </a>
              </li>
            </ul>
            <button
              type="button"
              className="navigation__search"
              onClick={() => toggleSearch()}
            >
              <span id="btnSearch" className="feather icon-search"></span>
            </button>
          </div>
        </div>
        <div
          id="searchModal"
          className="hidden ease-out transform -translate-y-full adressmodal duration-400 adressmodal--search"
        >
          <button type="button" id="closeSearch" className="adressmodal__close">
            <span className="feather icon-x"></span>
          </button>
          <div className="form-box">
            <form action="">
              <div className="form-control form-control--icon">
                <span className="feather icon-search form-control__icon"></span>
                <input
                  placeholder="Buscar"
                  className="generic-input"
                  type="text"
                />
              </div>
            </form>
            <div className="hidden- xl:block search-mid">
              <div className="search-mid__result">
                <span className="search-mid__text">Lorem, ipsum dolor.</span>
                <span className="search-mid__icon feather icon-external-link"></span>
              </div>
              <div className="search-mid__result">
                <span className="search-mid__text">
                  Lorem ipsum dolor sit amet.
                </span>
                <span className="search-mid__icon feather icon-external-link"></span>
              </div>
              <div className="search-mid__result">
                <span className="search-mid__text">Lorem, ipsum dolor.</span>
                <span className="search-mid__icon feather icon-external-link"></span>
              </div>
              <div className="search-mid__result">
                <span className="search-mid__text">
                  Lorem ipsum dolor sit amet.
                </span>
                <span className="search-mid__icon feather icon-external-link"></span>
              </div>
              <div className="search-mid__result">
                <span className="search-mid__text">
                  Lorem ipsum dolor sit amet.
                </span>
                <span className="search-mid__icon feather icon-external-link"></span>
              </div>
              <div className="search-mid__result">
                <span className="search-mid__text">
                  Lorem ipsum dolor sit amet.
                </span>
                <span className="search-mid__icon feather icon-external-link"></span>
              </div>
              <div className="search-mid__result">
                <span className="search-mid__text">
                  Lorem ipsum dolor sit amet.
                </span>
                <span className="search-mid__icon feather icon-external-link"></span>
              </div>
            </div>
            <div className="hidden xl:flex search-bottom">
              <div className="search-bottom__link-question">
                <h3 className="search-bottom__question">
                  ¿No encuentras lo que estas buscando?
                </h3>
                <ul className="search-bottom__list">
                  <li>
                    <a href={"#"}>
                      <span>•</span> Contáctanos
                    </a>
                  </li>
                  <li>
                    <a href={"#"}>
                      <span>•</span> Prueba con otra palabra
                    </a>
                  </li>
                  <li>
                    <a href={"#"}>
                      <span>•</span> Ir al mapa del sitio
                    </a>
                  </li>
                </ul>
              </div>
              <StaticImage src="//img/logo-search.svg"></StaticImage>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};
