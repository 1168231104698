import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CheckList } from '../../components/lists/checkList/checkList';
import "./tabbedLists.css";

export interface TabbedListsProps {
    className?: string;
    lists?: ListItemProps[];
    htmlContent?: boolean;
}

export interface ListItemProps {
    Titulo: string;
    Contenido: string;
    Tab: string;
    Tipo: string;
}

interface List {
    className?: string;
    title: string;
    listItems: ListItem[];
    type: string;
}

interface ListItem {
    className?: string,
    title?: string;
    text?: string;
}

export function TabbedLists(props: TabbedListsProps) {

    const [tabIndex, setTabIndex] = useState(0);
    const [lists, setLists] = useState<{ [key: string]: List }>({});
    const location = useLocation();

    useEffect(() => {
        if (props.lists && props.lists.length > 0)
            setLists(
                props.lists.reduce(
                    (result: { [key: string]: List }, currentItem: ListItemProps) => {
                        if (!result[currentItem.Tab]) {
                            result[currentItem.Tab] = {
                                title: currentItem.Tab,
                                type: currentItem.Tipo,
                                listItems: [{
                                    title: currentItem.Titulo,
                                    text: currentItem.Contenido
                                }]
                            }
                        }
                        else {
                            result[currentItem.Tab].listItems = [...result[currentItem.Tab].listItems, {
                                title: currentItem.Titulo,
                                text: currentItem.Contenido
                            }]
                        }
                        return result;
                    }, {}
                )
            );
    }, [props.lists]);

    useEffect(() => {
        setTabIndex(0);
    }, [location]);

    return (
        <div className={"tabs-container"}>
            <Tabs selectedIndex={tabIndex} onSelect={(index: number) => setTabIndex(index)}>
                <TabList>
                    {
                        Object.keys(lists).map((key: string, index: number) => {
                            return (<Tab key={index}>{lists[key].title}</Tab>);
                        })
                    }
                </TabList>

                {
                    Object.keys(lists).map((key: string, index: number) => {
                        return (
                            <TabPanel key={index}>
                                <CheckList type={lists[key].type} listElements={lists[key].listItems} htmlContent={true}></CheckList>
                            </TabPanel>
                        );
                    })
                }
            </Tabs>
        </div>
    );
}
