import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { StaticImage } from "../base/staticImage/staticImage";
import { Disclosure, Transition } from "@headlessui/react";
import "./sideMenu.css";

export interface GlobalSideMenuProps {
  className?: string;
  title?: string;
  listItems?: ListItemProps[];
}

export interface ListItemProps {
  className?: string;
  id?: number;
  title?: string;
  parentId?: number;
  route?: string;
  isActive?: boolean;
}

interface SideMenuItem {
  id?: number;
  className?: string;
  title?: string;
  children?: Array<SideMenuItem>;
  route?: string;
  isActive?: boolean;
}

export function GlobalSideMenu(props: GlobalSideMenuProps) {
  const [isShow, setIsShow] = useState(true);

  const handleClick = () => {
    setIsShow(!isShow);
    console.log("clicked");
  };

  const sideMenuRef = useRef<any>(null);
  const setHeight = () => {
    if (sideMenuRef.current) {
      sideMenuRef.current.style.maxHeight =
        sideMenuRef.current.scrollHeight + "px";
    }
  };
  const unsetHeight = () => {
    if (sideMenuRef.current) {
      sideMenuRef.current.style.maxHeight = "0px";
    }
  };

  const [menuItems, setMenuItems] = useState<{ [key: string]: SideMenuItem }>(
    {}
  );
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(true);

  useEffect(() => {
    if (props.listItems) {
      const _menuItems = props.listItems.reduce<{
        [key: string]: SideMenuItem;
      }>(
        (
          result: { [key: string]: SideMenuItem },
          currentItem: ListItemProps
        ) => {
          if (currentItem.id) {
            if (currentItem.parentId) {
              if (!result[currentItem.parentId])
                result[currentItem.parentId] = {
                  children: [currentItem],
                };
              else {
                result[currentItem.parentId].children = [
                  ...(result[currentItem.parentId].children || []),
                  currentItem,
                ];
              }
            } else {
              if (!result[currentItem.id]) result[currentItem.id] = currentItem;
              else
                result[currentItem.id] = {
                  id: currentItem.id,
                  title: currentItem.title,
                  className: currentItem.className,
                  children: result[currentItem.id].children,
                  route: currentItem.route,
                  isActive: currentItem.isActive,
                };
            }
          }
          return result;
        },
        {}
      );

      setMenuItems(_menuItems);
    }
  }, [props.listItems]);

  return (
    <>
      <Disclosure
        as="div"
        defaultOpen={true}
        className="overflow-hidden side-menu"
      >
        {({ open }) => (
          <>
            <Disclosure.Button as="div" className="side-menu__top">
              <button type="button" className="side-menu__trigger">
                <span className="side-menu__title">{props.title}</span>
                <span className="side-menu__more">
                  <StaticImage
                    src={"img/more-dots.svg"}
                    alt="Expandir menú"
                  ></StaticImage>
                </span>
              </button>
            </Disclosure.Button>
            <Transition
              unmount={false}
              className="relative max-h-full overflow-hidden side-menu__bottom"
              show={open}
              enter="transition-all ease-in-out duration-700 transform"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Disclosure.Panel
                static
                as="ul"
                className="side-menu__bottom-list"
              >
                {/* <ul className="side-menu__bottom-list"> */}
                {Object.keys(menuItems).map((key: string, index: number) => {
                  return (
                    <li
                      key={index}
                      className={menuItems[key].children ? "sub-acordeon" : ""}
                    >
                      <div className="flex items-baseline justify-between">
                        <NavLink
                          className={({ isActive }) =>
                            isActive
                              ? "trigger-sub-accordeon active"
                              : "trigger-sub-accordeon"
                          }
                          to={menuItems[key]?.route || ""}
                        >
                          {menuItems[key].title}{" "}
                        </NavLink>
                        <button
                          className="mx-4"
                          onClick={handleClick}
                          type="button"
                        >
                          {
                            <span
                              className={
                                menuItems[key].children
                                  ? `feather ${
                                      isShow
                                        ? "icon-chevron-down"
                                        : "icon-chevron-up"
                                    }`
                                  : ""
                              }
                            >
                              {" "}
                            </span>
                          }
                        </button>
                      </div>

                      {menuItems[key].children && (
                        <ul
                          className={`sub-menu ${isShow ? "hidden" : "block"}`}
                        >
                          {menuItems[key].children?.map((child, index) => {
                            return (
                              <li key={index}>
                                <NavLink
                                  className={({ isActive }) =>
                                    isActive ? " active" : ""
                                  }
                                  to={child.route || ""}
                                >
                                  {child.title}
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
                {/* </ul> */}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      {/* <div className="overflow-hidden side-menu">
                <div className="side-menu__top">
                    <button type="button" className="relative z-20 side-menu__trigger" onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}>
                        <span className="side-menu__title">{props.title}</span>
                        <span className="side-menu__more">
                            <StaticImage src={"img/more-dots.svg"} alt="Expandir menú"></StaticImage>
                        </span>
                    </button>
                </div>
                <Transition
                    className="relative overflow-hidden"
                    show={isSideMenuOpen}
                    enter="transition-all duration-700 ease-out"
                    enterFrom="transform -translate-y-full"
                    enterTo="transform -translate-y-0"
                    leave="transition-all duration-700 ease-out"
                    leaveFrom="transform -translate-y-0"
                    leaveTo="transform -translate-y-full "
                    ref={sideMenuRef}
                >
                    <ul className="side-menu__bottom-list">
                        {Object.keys(menuItems).map((key: string, index: number) => {
                            return (
                                <li key={index} className={menuItems[key].children ? "sub-acordeon" : ""}>
                                    <NavLink
                                        className={({ isActive }) => isActive ? 'trigger-sub-accordeon active' : 'trigger-sub-accordeon'}
                                        to={menuItems[key]?.route || ""}
                                    >
                                        {menuItems[key].title} {<span className={menuItems[key].children ? "feather icon-chevron-down" : ""}> </span>}
                                    </NavLink>

                                    {menuItems[key].children &&
                                        <ul className="sub-menu">
                                            {menuItems[key].children?.map((child, index) => {
                                                return (
                                                    <li key={index}>
                                                        <NavLink
                                                            className={({ isActive }) => isActive ? ' active' : ''}
                                                            to={child.route || ""}
                                                        >
                                                            {child.title}
                                                        </NavLink>
                                                    </li>
                                                );
                                            })}
                                        </ul>}
                                </li>
                            );
                        })}
                    </ul>
                </Transition>
            </div> */}
    </>
  );
}
