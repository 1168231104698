import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import './bannerTopBar.css';
import { NavLink } from 'react-router-dom'
import { StaticImage } from "../base/staticImage/staticImage";

export interface BannerTopBarProps {
    slides: BannerTopSlide[]
};

export interface BannerTopSlide {
    icon: string,
    title: string,
    href: string
}

export function BannerTopBar(props: BannerTopBarProps) {
    return (
        <section id="sub-bar">
            <div className="container">
                <Splide
                    id="sub-bar-slider"
                    className="splide"
                    options={{
                        type: 'loop',
                        perPage: 4,
                        autoplay: false,
                        arrows: true,
                        pagination: false,
                        loop: false,
                        gap: "1.5rem",

                        breakpoints: {
                            768: {
                                perPage: 1
                            },
                            1280: {
                                perPage: 2
                            },
                            1600: {
                                perPage: 3
                            }
                        }
                    }}
                    renderControls={() => (
                        <div className="splide__arrows">
                            <button type="button" className="splide__arrow splide__arrow--prev">
                                <i className="feather icon-chevron-left"></i>
                            </button>
                            <button type="button" className="splide__arrow splide__arrow--next">
                                <i className="feather icon-chevron-right"></i>
                            </button>
                        </div>
                    )}>

                    {
                        props.slides.map((slide: any, index: number) => (
                            <SplideSlide key={index} className="splide__slide">
                                <NavLink to={slide.href} className={({ isActive }) => 'slide-content' + (isActive ? ' active' : '')}>
                                    <StaticImage src={slide.icon} alt={slide.title}></StaticImage>
                                    <span>{slide.title}</span>
                                </NavLink>
                            </SplideSlide>
                        ))
                    }
                </Splide>
            </div>
        </section>
    );
}