import "./transparencia.css";

import React, { useContext, useState } from "react";

import AccordionDocument from "../../components/transparecy/AccordeonDocument";
import { BannerTop } from "../../components/bannerTop/bannerTop";
import DocumentListTransparecy from "../../components/transparecy/DocumentListTransparecy";
import FilterTransparecy from "../../components/transparecy/FilterTransparecy";
import { Footer } from "../../components/footer/footer";
import { Header } from "../../components/header/header";
import { StaticImage } from "../../components/base/staticImage/staticImage";
import { TransparenciaContext } from "../../context/Transparencia/Transparencia.context";
import dots from "../../assets/img/more-dots.svg";
import SingleDocumentBlock from "../../components/transparecy/SingleDocumentBlock";

function Transparencia() {
  const context = useContext(TransparenciaContext);
  const [hideSideMenu, setHideSideMenu] = useState(true);
  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const hideMenu = () => {
    setHideSideMenu((active) => !active);
  };

  return (
    <>
      <Header />
      <BannerTop title={"Transparencia"}></BannerTop>
      <main>
        <section id="Portada" className="article section">
          <div className="container mx-auto xl:flex">
            <div className="col xl:w-1/3 xl:px-4 xl:order-last">
              <div className="side-menu">
                <div className="side-menu__top">
                  <button
                    type="button"
                    onClick={hideMenu}
                    className="side-menu__trigger"
                  >
                    <span className="side-menu__title">Secciones</span>
                    <span className="side-menu__more">
                      <img src={dots} alt="Expandir menú"></img>
                    </span>
                  </button>
                </div>
                <div className={`${hideSideMenu ? "hidden" : "block"}`}>
                  <ul className="side-menu__bottom-list">
                    <li className="cursor-pointer">
                      <a
                        className={
                          context.menuTitleActive === "Portada" ? "active" : ""
                        }
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          context.handleMenuTitleClick(event);
                          context.resetShowDoc();
                        }}
                      >
                        Portada
                      </a>
                    </li>
                    <li className="cursor-pointer">
                      <a
                        className={
                          context.menuTitleActive === "Compras y Contrataciones"
                            ? "active"
                            : ""
                        }
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          context.handleMenuTitleClick(event);
                          context.resetShowDoc();
                        }}
                      >
                        Compras y Contrataciones
                      </a>
                    </li>
                    <li className="cursor-pointer">
                      <a
                        className={
                          context.menuTitleActive === "Hechos Relevantes"
                            ? "active"
                            : ""
                        }
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          context.handleMenuTitleClick(event);
                          context.resetShowDoc();
                        }}
                      >
                        Hechos Relevantes
                      </a>
                    </li>
                    <li className="cursor-pointer">
                      <a
                        className={
                          context.menuTitleActive === "Fiduciaria"
                            ? "active"
                            : ""
                        }
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          context.handleMenuTitleClick(event);
                          context.resetShowDoc();
                        }}
                      >
                        Fiduciaria
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {context.menuTitleActive === "Portada" && (
              <div className="col xl:w-2/3 xl:pr-14">
                <div className="article-text">
                  <h3 className="article-text__head-title">
                    {context.portada?.Subtitulo}
                  </h3>

                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: context.portada?.Contenido || "",
                    }}
                  />
                </div>
              </div>
            )}
            {context.menuTitleActive === "Compras y Contrataciones" && (
              <div className="col xl:w-2/3 xl:pr-14">
                <div className="article-text">
                  {context.showDocuments && (
                    <div
                      className="inline-flex justify-center w-full my-2 cursor-pointer md:hidden md:w-auto lg:justify-end return-transparency lg:order-last"
                      onClick={context.handleShowDocuments}
                    >
                      <span className="cursor-pointer feather icon-chevron-left"></span>
                      Volver atrás
                    </div>
                  )}
                  <div className="flex justify-between cursor-pointer lg:space-arround">
                    {context.showDocuments && (
                      <div
                        className="justify-center hidden w-full my-2 cursor-pointer md:w-auto lg:justify-end return-transparency lg:order-last md:inline-flex"
                        onClick={context.handleShowDocuments}
                      >
                        <span className="cursor-pointer feather icon-chevron-left"></span>
                        Volver atrás
                      </div>
                    )}
                    <h3 className="article-text__head-title">
                      {context.menuTitleActive}
                    </h3>
                    {!context.showDocuments && (
                      <span
                        onClick={context.handleToggle}
                        className="hidden lg:mt-3 lg:ml-3 filter-tooggle md:inline-block"
                      >
                        <StaticImage
                          src={"img/filter-icon.svg"}
                          alt="Mostrar Filtro"
                        ></StaticImage>
                      </span>
                    )}
                  </div>

                  {!context.showDocuments && (
                    <div className={`mt-5 ${context.isHidden ? "" : "hidden"}`}>
                      <FilterTransparecy
                        setSearchInput={context.handleSearchInput}
                        changeCurrDocType={context.changeCurrDocType}
                        changeCurrDate={context.changeCurrDate}
                      />
                    </div>
                  )}

                  {!context.showDocuments && (
                    <div className="my-5">
                      <AccordionDocument
                        currDocSelection={context.currDocSelection}
                        handleShowDocuments={(documents: any) => {
                          context.handleShowDocuments();
                          setSelectedDocuments(documents);
                        }}
                      />
                    </div>
                  )}

                  {context.showDocuments && (
                    <div className="my-5">
                      <FilterTransparecy
                        setSearchInput={context.handleSearchInput}
                        changeCurrDocType={context.changeCurrDocType}
                        changeCurrDate={context.changeCurrDate}
                      />
                      <DocumentListTransparecy
                        currDocSelection={selectedDocuments}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {context.menuTitleActive === "Hechos Relevantes" && (
              <div className="col xl:w-2/3 xl:pr-14">
                <div className="article-text">
                  {context.showDocuments && (
                    <div
                      className="justify-center w-full my-2 text-center cursor-pointer md:w-auto lg:justify-end return-transparency lg:hidden lg:order-last"
                      onClick={context.handleShowDocuments}
                    >
                      <span className="cursor-pointer feather icon-chevron-left"></span>
                      Volver atrás
                    </div>
                  )}
                  <div className="justify-between md:flex lg:space-arround">
                    {context.showDocuments && (
                      <div
                        className="justify-center hidden w-full my-2 cursor-pointer md:w-auto lg:justify-end return-transparency lg:inline-flex lg:order-last"
                        onClick={context.handleShowDocuments}
                      >
                        <span className="cursor-pointer feather icon-chevron-left"></span>
                        Volver atrás
                      </div>
                    )}
                    <h3 className="article-text__head-title">
                      {context.menuTitleActive}
                    </h3>
                    {!context.showDocuments && (
                      <span
                        onClick={context.handleToggle}
                        className="hidden lg:mt-3 lg:ml-3 filter-tooggle md:inline-block"
                      >
                        <StaticImage
                          src={"img/filter-icon.svg"}
                          alt="Mostrar Filtro"
                        ></StaticImage>
                      </span>
                    )}
                  </div>

                  {!context.showDocuments && (
                    <div className={`mt-5 ${context.isHidden ? "" : "hidden"}`}>
                      <FilterTransparecy
                        hideSelect={false}
                        setSearchInput={context.handleSearchInput}
                        changeCurrDocType={context.changeCurrDocType}
                        changeCurrDate={context.changeCurrDate}
                      />
                    </div>
                  )}
                  {!context.showDocuments && (
                    <div className="my-5">
                      <SingleDocumentBlock
                        hideRef={false}
                        currDocSelection={context.currDocSelection}
                        handleShowDocuments={(documents: any) => {
                          context.handleShowDocuments();
                          setSelectedDocuments(documents);
                        }}
                      />
                    </div>
                  )}

                  {context.showDocuments && (
                    <div className="my-5">
                      <FilterTransparecy
                        setSearchInput={context.handleSearchInput}
                        changeCurrDocType={context.changeCurrDocType}
                        changeCurrDate={context.changeCurrDate}
                      />
                      <DocumentListTransparecy
                        currDocSelection={selectedDocuments}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {context.menuTitleActive === "Fiduciaria" && (
              <div className="col xl:w-2/3 xl:pr-14">
                <div className="article-text">
                  <div className="justify-between md:flex lg:space-arround">
                    {context.showDocuments && (
                      <div
                        className="justify-center w-full my-2 text-center cursor-pointer md:w-auto lg:justify-end return-transparency lg:order-last md:text-left"
                        onClick={context.handleShowDocuments}
                      >
                        <span className="cursor-pointer feather icon-chevron-left"></span>
                        Volver atrás
                      </div>
                    )}
                    <h3 className="article-text__head-title">
                      {context.menuTitleActive}
                    </h3>
                    {!context.showDocuments && (
                      <span
                        onClick={context.handleToggle}
                        className="hidden lg:mt-3 lg:ml-3 filter-tooggle md:inline-block"
                      >
                        <StaticImage
                          src={"img/filter-icon.svg"}
                          alt="Mostrar Filtro"
                        ></StaticImage>
                      </span>
                    )}
                  </div>

                  {!context.showDocuments && (
                    <div className={`mt-5 ${context.isHidden ? "" : "hidden"}`}>
                      <FilterTransparecy
                        hideSelect={false}
                        setSearchInput={context.handleSearchInput}
                        changeCurrDocType={context.changeCurrDocType}
                        changeCurrDate={context.changeCurrDate}
                      />
                    </div>
                  )}
                  {!context.showDocuments && (
                    <div className="my-5">
                      <SingleDocumentBlock
                        hideRef={false}
                        currDocSelection={context.currDocSelection}
                        handleShowDocuments={(documents: any) => {
                          context.handleShowDocuments();
                          setSelectedDocuments(documents);
                        }}
                      />
                    </div>
                  )}

                  {context.showDocuments && (
                    <div className="my-5">
                      <FilterTransparecy
                        setSearchInput={context.handleSearchInput}
                        changeCurrDocType={context.changeCurrDocType}
                        changeCurrDate={context.changeCurrDate}
                      />
                      <DocumentListTransparecy
                        currDocSelection={selectedDocuments}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Transparencia;
