import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { HistoriaMockData } from "./mock/Historia.mock";
import { Historia, HistoriaHeader } from "./models/Historia.model";



type HistoriaProviderProps = { children: React.ReactNode; };
const HistoriaContext = React.createContext<Historia | undefined>(undefined);

type Message = { type: 'header'; value: HistoriaHeader | undefined; };

const reducer = (state: Historia, message: Message) => {
    switch (message.type) {
        case 'header':
            return {
                ...state, Header: message.value
            };
        default:
            return { ...state };
    }
};
const HistoriaContextProvider = ({ children }: HistoriaProviderProps) => {

    const [Historia, setHistoria] = useReducer(reducer, {} as Historia);



    useEffect(() => {
        fetchHistoria();
    }, []);

    async function fetchHistoria() {
        try {
            const historia = await axios.get("/Nosotros/_api/web/lists/GetByTitle('Nosotros - Historia Detalle')/items?$select=Activo,Title,SubTitulo,Contenido,Orden,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: HistoriaHeader[] = [];

                if (response.status === 200 && response.data && response.data.d && response.data.d.results) {
                    result = response.data.d.results.map((detail: any): HistoriaHeader => (
                        {
                            Title: detail.Title,
                            Subtitulo: detail.SubTitulo,
                            Image: detail.Attachments ? detail.AttachmentFiles.results[0].ServerRelativeUrl : "",
                            Contenido: detail.Contenido
                        }
                    ));
                }
                return result;
            });
            setHistoria({ type: 'header', value: historia[0] });
            // const mockData = HistoriaMockData.Header;
            // setHistoria({ type: 'header', value: mockData });

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <HistoriaContext.Provider value={Historia}>
            {children}
        </HistoriaContext.Provider>
    );

};
const useHistoria = () => {
    const context = useContext(HistoriaContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { useHistoria, HistoriaContextProvider };