import { Link } from "react-router-dom";
import { Article } from "../../components/article/article";
import { PageHeaders } from "../../components/pageHeaders/pageHeaders";
import { ProjectInfo } from "../../components/projectInfo/projectInfo";
import { ProjectPhotoGallery } from "../../components/projectPhotoGallery/projectPhotoGallery";
import { ProjectsSlider } from "../../containers/projectsSlider/projectsSlider";
import { useProjectDetails } from "../../context/Projects/ProjectDetails.context";
import './ProyectosDetails.css';


export const ProyectosDetails = () => {
    const context = useProjectDetails();

    return (
        <>
            <PageHeaders title={context?.Project?.name} />

            <section>
                <div className="project">
                    <div className="project-head">
                        <div className="container mx-auto">
                            <div className="items-start justify-center md:justify-between head-section xl:flex">
                                <div>
                                    <Link className="head-section__back" to="/Proyectos">
                                        <span className="feather icon-chevron-left"></span>
                                        Volver atrás
                                    </Link>
                                    <h3 className="my-1 xl:my-4 project-head__head-title">{context?.Project?.name}</h3>
                                    <h4 className="head-section__city">
                                        <span className="feather icon-clock"></span>
                                        {context?.Project?.status}
                                    </h4>
                                    &nbsp;
                                    <h4 className="head-section__city">
                                        <span className="feather icon-map-pin"></span>
                                        {context?.Project?.city}
                                    </h4>
                                </div>
                                {/* <span className="hidden xl:inline-block status-tag">
                                       <span className="feather icon-clock"></span>{project.status}
                                        {context?.Project?.status}
                                    </span> */}
                            </div>
                            {
                                context?.Project?.type === "Proyectos Privados" &&
                                <>
                                    <ProjectPhotoGallery images={context?.Imagenes} prices={context?.Project?.prices}></ProjectPhotoGallery>
                                    <Article articleDetails={context?.Summary} htmlContent={true}></Article>
                                    <ProjectInfo details={context?.Details} developer={context?.Builder}></ProjectInfo>
                                    {
                                        context?.RelatedProjects && context?.RelatedProjects.length > 0 &&
                                        <ProjectsSlider projects={context?.RelatedProjects}></ProjectsSlider>
                                    }
                                </>
                            }

                            {
                                (context?.Project?.type === "Proyectos Públicos" || context?.Project?.type === "Proyectos Público-Privado") &&
                                <>
                                    {
                                        (context?.Imagenes && context?.Imagenes?.length > 0) &&
                                        <div className="project-detail__cover">
                                            <img src={context?.Imagenes[0]} alt={"Portada Proyecto " + context?.Project?.name} />
                                        </div>
                                    }

                                    <section className="project-detail__project-info">
                                        <div className="project-detail__article-container">
                                            <Article articleDetails={context?.Summary} htmlContent={true}></Article>
                                        </div>
                                        <div className="project-detail__contact-info">
                                            <div className="project-right">
                                                <h3>Institución de Contacto</h3>
                                                <h4>{
                                                    context?.Builder?.name
                                                }</h4>
                                                <h5><span className="feather icon-phone"></span> {
                                                    context?.Builder?.phone
                                                }</h5>
                                                <h5>
                                                    <a target="_blank" rel="noreferrer" href={"mailto:" + context?.Builder?.email}>
                                                        <span className="feather icon-mail"></span> {
                                                            context?.Builder?.email
                                                        }
                                                    </a>
                                                </h5>
                                                <h5>
                                                    <a target="_blank" rel="noreferrer" href={context?.Builder?.website}>
                                                        <span className="feather icon-link"></span>{
                                                            context?.Builder?.website
                                                        }
                                                    </a>
                                                </h5>
                                            </div>
                                        </div>
                                    </section>

                                    {
                                        context?.RelatedProjects && context?.RelatedProjects.length > 0 &&
                                        <ProjectsSlider projects={context?.RelatedProjects}></ProjectsSlider>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};