import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { Project } from './models/Project.models';
import { Projects } from './models/Projects.model';

type ProjectListProviderProps = { children: React.ReactNode };
const ProjectListContext = React.createContext<Projects>({ Projects: [] });

type Message = { type: 'projects'; value: Project[] };

const reducer = (state: Projects, message: Message): Projects => {
  switch (message.type) {
    case 'projects':
      return { ...state, Projects: message.value };
    default:
      return { ...state };
  }
};

const ProjectListContextProvider = ({ children }: ProjectListProviderProps) => {
  const [state, dispatch] = useReducer(reducer, { Projects: [] });

  useEffect(() => {
    fetchProjects();
  }, []);

  async function fetchProjects() {
    try {
      const projects = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Proyectos')/items?$select=ID,Activo,Orden,Title,Ciudad,Estado,TipoProyecto,PrecioDesde,PrecioHasta,Moneda,Fecha,Attachments,AttachmentFiles,Constructora/Title&$expand=Constructora,AttachmentFiles&$filter=Activo eq 1&$orderby=Orden`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          var result = Array<Project>();

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((fideicomiso: any) => {
              result.push({
                id: fideicomiso.ID,
                name: fideicomiso.Title,
                city: fideicomiso.Ciudad,
                status: fideicomiso.Estado,
                type: fideicomiso.TipoProyecto,
                imageUrl: fideicomiso.Attachments ? fideicomiso.AttachmentFiles.results[0].ServerRelativeUrl : '',
                images: [],
                prices: {
                  base: fideicomiso.PrecioDesde,
                  top: fideicomiso.PrecioHasta,
                  currency: fideicomiso.Moneda
                },
                date: new Date(fideicomiso.Fecha),
                builderName: fideicomiso.Constructora.Title
              });
            });

          return result;
        });

      dispatch({ type: 'projects', value: projects });
    } catch (error) {
      console.log(error);
    }
  }

  return <ProjectListContext.Provider value={state}>{children}</ProjectListContext.Provider>;
};

const useProjectList = () => {
  const context = useContext(ProjectListContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};

export { useProjectList, ProjectListContextProvider };
