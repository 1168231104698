import { Splide, SplideSlide } from "@splidejs/react-splide";
import { Header } from "../../components/header/header";
import "@splidejs/splide/dist/css/splide.min.css";
import { useEffect } from "react";
import { Records } from "../../components/records/records";
import { Offers } from "../../components/offer/offer";
import { Footer } from "../../components/footer/footer";
import "./home.css";
import { useHomePage } from "../../context/Home.context";
import { HomeBanner, WelcomeSlide } from "../../context/models/Home.models";
import React from "react";
import { StaticImage } from "../../components/base/staticImage/staticImage";
import { DynamicImage } from "../../components/base/dynamicImage/dynamicImage";
import { Link } from "react-router-dom";
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.min.css";

export const Home = () => {
  const context = useHomePage();

  const mainSlider = React.createRef<Splide>();
  const thumbSlider = React.createRef<Splide>();

  const lightbox = GLightbox({
    autoplayVideos: true,
  });

  useEffect(() => {
    if (context?.Video !== undefined && context?.Video?.Link !== "") {
      lightbox.reload();
      setTimeout(() => {
        const videoLink = document.getElementById("video-link");
        if (videoLink) {
          videoLink.click();
        }
      }, 3000);
    }
  }, [context?.Video, lightbox]);

  useEffect(() => {
    if (
      mainSlider &&
      mainSlider.current &&
      thumbSlider &&
      thumbSlider.current &&
      thumbSlider.current.splide
    )
      mainSlider.current.sync(thumbSlider.current.splide);
  });

  return (
    <div>
      <Header></Header>
      {context?.Video !== undefined && context?.Video?.Link !== "" && (
        <a
          id="video-link"
          href={context?.Video?.Link}
          className={"glightbox"}
          rel="noreferrer"
          target="_blank"
          style={{ display: "none" }}
        ></a>
      )}
      <section className="slider-home" data-aos="fade-up">
        <Splide
          className="splide"
          options={{
            rewind: true,
            type: "loop",
            gap: "1rem",
            autoplay: true,
            interval: 5000,
          }}
          renderControls={() => (
            <div className="splide__arrows">
              <button
                className="splide__arrow splide__arrow--prev"
                type="button"
              >
                <StaticImage src="/img/left-arrow.svg" alt=""></StaticImage>
              </button>
              <button
                className="splide__arrow splide__arrow--next"
                type="button"
              >
                <StaticImage src="/img/right-arrow.svg" alt=""></StaticImage>
              </button>
            </div>
          )}
        >
          {context?.BannerPrincipal?.map(
            (banner: HomeBanner, index: number) => {
              return (
                <>
                  {banner.Url && banner.TextoEnlace && (
                    <SplideSlide
                      key={index}
                      className="slider-home__item splide__slide"
                    >
                      <div className="slider-home__overlay"></div>
                      <DynamicImage
                        className="slider-home__image"
                        src={banner.Imagen}
                        alt={"Imagen de " + banner.Titulo}
                      ></DynamicImage>
                      <div className="container mx-auto">
                        <div
                          className={
                            "banner-position " + banner.TextoOrientacion
                          }
                        >
                          <div className="col-lg-12">
                            <h2
                              style={{ color: banner.TituloColor }}
                              className="banner-home__title"
                            >
                              {banner.Titulo}
                            </h2>
                            <p
                              style={{ color: banner.ColorContenido }}
                              className="slider-home__text"
                            >
                              {banner.Contenido}
                            </p>
                            {banner.Url && banner.TextoEnlace && (
                              <div className="slider-home__anchor-block">
                                <a
                                  className="slider-home__link"
                                  href={banner.Url}
                                >
                                  {banner.TextoEnlace}
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </SplideSlide>
                  )}

                  {banner.Url && !banner.TextoEnlace && (
                    <SplideSlide
                      key={index}
                      className="slider-home__item splide__slide"
                    >
                      <div className="slider-home__overlay"></div>
                      <DynamicImage
                        className="slider-home__image"
                        src={banner.Imagen}
                        alt={"Imagen de " + banner.Titulo}
                      ></DynamicImage>
                      <a
                        className="container mx-auto full-link"
                        href={banner.Url}
                      >
                        <div className="grid gap-6 grid-col-1 md:grid-cols-2">
                          <div className="col-lg-12">
                            <h1 className="slider-home__title">
                              {banner.Titulo}
                            </h1>
                            <p className="slider-home__text">
                              {banner.Contenido}
                            </p>
                          </div>
                        </div>
                      </a>
                    </SplideSlide>
                  )}

                  {!banner.Url && !banner.TextoEnlace && (
                    <SplideSlide
                      key={index}
                      className="slider-home__item splide__slide"
                    >
                      <div className="slider-home__overlay"></div>
                      <DynamicImage
                        className="slider-home__image"
                        src={banner.Imagen}
                        alt={"Imagen de " + banner.Titulo}
                      ></DynamicImage>
                      <div className="container mx-auto">
                        <div className="grid gap-6 grid-col-1 md:grid-cols-2">
                          <div className="col-lg-12">
                            <h1 className="slider-home__title">
                              {banner.Titulo}
                            </h1>
                            <p className="slider-home__text">
                              {banner.Contenido}
                            </p>
                          </div>
                        </div>
                      </div>
                    </SplideSlide>
                  )}
                </>
              );
            }
          )}
        </Splide>
      </section>

      <section className="welcome-section section" data-aos="fade-up">
        <Splide
          className="welcome-section-slide"
          ref={mainSlider}
          options={{
            pagination: false,
            arrows: false,
            type: "slide",
          }}
        >
          {context?.WelcomeSlides?.map(
            (_slide: WelcomeSlide, index: number) => {
              return (
                <SplideSlide
                  key={index}
                  className="welcome-section-slide__item splide__slide"
                >
                  <div className="container mx-auto">
                    <div className="grid gap-6 place-content-center grid-col-1 md:grid-cols-2">
                      <div className="welcome-section__image-box">
                        <DynamicImage
                          className="welcome-section__image"
                          src={_slide.Imagen}
                          alt={"Imagen de " + _slide.Titulo}
                        ></DynamicImage>
                      </div>
                      <div className="flex flex-col flex-wrap content-center justify-center welcome-section__text-block">
                        <h2 className="text-center generic-title md:text-left">
                          {_slide.Titulo}
                        </h2>
                        <p className="text-center generic-text md:text-left">
                          {_slide.Contenido}
                        </p>
                      </div>
                    </div>
                  </div>
                </SplideSlide>
              );
            }
          )}
        </Splide>
        <div className="container mx-auto">
          <Splide
            id="thumbnail-slider"
            className="splide thumbnail-slider"
            ref={thumbSlider}
            options={{
              pagination: false,
              isNavigation: true,
              fixedWidth: 20,
              fixedHeight: 20,
              arrows: true,
            }}
            renderControls={() => (
              <div className="splide__arrows">
                <button
                  className="splide__arrow splide__arrow--prev"
                  type="button"
                >
                  <span className="feather icon-chevron-left"></span>
                </button>
                <button
                  className="splide__arrow splide__arrow--next"
                  type="button"
                >
                  <span className="feather icon-chevron-right"></span>
                </button>
              </div>
            )}
          >
            {context?.WelcomeSlides?.map(
              (slide: WelcomeSlide, index: number) => {
                return (
                  <SplideSlide key={index}>
                    <span className="thumbnail-slider__pagination"></span>
                  </SplideSlide>
                );
              }
            )}
          </Splide>
        </div>
      </section>
      <section className="background-image">
        <section className="pb-0 bg-no-repeat section record">
          <Records cifras={context?.HomeCifras}></Records>
        </section>

        <section className="section offer">
          <Offers
            offers={context?.Fideicomisos?.map((fideicomiso) => {
              return {
                id: fideicomiso.Id,
                title: fideicomiso.Titulo,
                data: {
                  image: fideicomiso.Imagen,
                  text: fideicomiso.Resumen,
                },
              };
            })}
          ></Offers>
        </section>
      </section>
      <Footer></Footer>
    </div>
  );
};
