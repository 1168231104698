import { Link } from "react-router-dom";
import { Project } from "../../../context/Projects/models/Project.models";
import { DynamicImage } from "../../base/dynamicImage/dynamicImage";

export interface ProjectCardProps {
    project: Project;
}

export const ProjectCard = (props: ProjectCardProps) => {
    const { project } = props;

    return (<>
        <Link to={"/Proyectos/Detalles?proyectoId=" + project.id} className="project-list__building block">
            <div className="project-list__top-box">
                <span className="project-list__tag">{project.type}</span>
                <DynamicImage src={project.imageUrl} className="project-list__image" alt={"Imagen del proyecto " + project.name}></DynamicImage>
            </div>
            <div className="project-list__bottom-box">
                <h3 className="project-list__name">{project.name}</h3>
                <p className="project-list__builder">{project.builder?.name || project.builderName}</p>
                <h4 className="project-list__city">
                    <span className="feather icon-clock"></span>{project.status}
                </h4>
                <h4 className="project-list__city">
                    <span className="feather icon-map-pin"></span>
                    {project.city}
                </h4>
            </div>
        </Link>
    </>);
};