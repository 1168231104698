import { Link } from "react-router-dom";
import {
  BannerTop,
  BannerTopProps,
} from "../../components/bannerTop/bannerTop";
import { Footer } from "../../components/footer/footer";
import { Header } from "../../components/header/header";
import { useSiteMap } from "../../context/SiteMap/SiteMap.context";
import "./sitemap.css";
export const SiteMap = () => {
  const context = useSiteMap();

  const bannerTopProps: BannerTopProps = {
    title: "Mapa del Sitio",
  };

  return (
    <article id="site-map">
      <Header></Header>
      <BannerTop {...bannerTopProps}></BannerTop>
      <main className="section">
        <div className="container mx-auto xl:flex">
          <div className="article-text col xl:w-full xl:px-12">
            <ul className="toggle-list toggle-list--fide">
              {context.modules?.map((module, index: number) => {
                if (
                  module.pages?.filter((page) => page.parent === module.id) &&
                  module.pages.length <= 1
                ) {
                  return (
                    <li
                      className="toggle-list__item toggle-list__item--faq"
                      key={index}
                    >
                      <label htmlFor={"toggle-" + index}>
                        <Link to={"/" + module.link}>{module.title}</Link>
                      </label>
                    </li>
                  );
                } else
                  return (
                    <li
                      className="toggle-list__item toggle-list__item--faq"
                      key={index}
                    >
                      <input type="checkbox" id={"toggle-" + index} />
                      <label htmlFor={"toggle-" + index}>
                        <Link to={"/" + module.link}>{module.title}</Link>
                        <span className="arrow-icon">
                          <span className="icon-container">
                            <i className="feather icon-chevron-right"></i>
                          </span>
                        </span>
                      </label>
                      <div className="content xl:pr-28 article-text__organigrama">
                        {module.pages?.map((page, pageKey) => (
                          <Link
                            key={page.title + "-" + pageKey}
                            to={"/" + module.link + "/" + page.link}
                            className="flex flex-wrap items-center article-text__organigrama-site-map article-text__organigrama-site-map--small"
                          >
                            <div className="article-text__organigrama-text">
                              <p className="article-text__organigrama-desc">
                                {page.title}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </li>
                  );
              })}
            </ul>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </article>
  );
};
