import { useEffect, useState } from 'react';
import { Media } from '../../context/Education/models/Multimedia.models';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import './mediaContainer.css';
import { DynamicImage } from '../../components/base/dynamicImage/dynamicImage';

export interface MediaContainerProps {
  className?: string;
  media: Array<Media>;
  tiposMedia: Array<string>;
}

export interface MediaCard extends Media {
  iconClass: string;
  lightbox: boolean;
}

export const MediaContainer = (props: MediaContainerProps) => {
  const lightbox = GLightbox();
  const [mediaCards, setMediaCards] = useState<Array<MediaCard>>([]);
  const [typeFilter, setTypeFilter] = useState('');
  const [dateOrder, setDateOrder] = useState('');
  const [filteredCards, setFilteredCards] = useState<Array<MediaCard>>([]);

  useEffect(() => {
    if (props.media && props.media.length > 0)
      setMediaCards(
        props.media.map((media) => {
          return {
            Title: media.Title,
            Type: media.Type,
            Thumbnail: media.Thumbnail,
            Url: media.Url,
            iconClass: media.Type.toLowerCase() === 'video' ? 'icon-play' : 'icon-download',
            lightbox: media.Type.toLowerCase() === 'video' || media.Type.toLowerCase() === 'imagen' ? true : false,
            FechaPublicacion: new Date()
          };
        })
      );
  }, [props.media]);

  useEffect(() => {
    setFilteredCards(
      mediaCards
        .filter((card: MediaCard) => {
          if (typeFilter) {
            if (card.Type.toLowerCase() === typeFilter.toLowerCase()) return card;
          } else return card;
        })
        .sort((prevCard: MediaCard, nextCard: MediaCard) => {
          if (dateOrder === 'desc') return prevCard.FechaPublicacion.getTime() - nextCard.FechaPublicacion.getTime();
          else return nextCard.FechaPublicacion.getTime() - prevCard.FechaPublicacion.getTime();
        })
    );
  }, [mediaCards, typeFilter, dateOrder]);

  useEffect(() => {
    lightbox.reload();
  }, [filteredCards, lightbox]);

  return (
    <section>
      <div className='head-section'>
        <h3 className='article-text__head-title'>Multimedia</h3>

        <form>
          <label>Filtrar:</label>

          <select name='tipo-documento' id='tipo-documento' onChange={(e) => setTypeFilter(e.target.value)} value={typeFilter}>
            <option value=''>Todos</option>
            {props.tiposMedia?.map((tipoMedia: string, index: number) => {
              return <option value={tipoMedia.toLowerCase()}>{tipoMedia}</option>;
            })}
          </select>

          <select name='fecha-documento' id='fecha-documento' onChange={(e) => setDateOrder(e.target.value)} value={dateOrder}>
            <option value='desc'>Más recientes</option>
            <option value='asc'>Más antiguos</option>
          </select>
        </form>
      </div>

      <div className='media-container'>
        {filteredCards?.map((card: MediaCard, index: number) => {
          return (
            <a href={card.Url} key={index} className={(card.lightbox ? 'glightbox ' : '') + 'media'} target='_blank' rel='noreferrer'>
              <div className='media__img'>
                <DynamicImage src={card.Thumbnail} alt={'Imagen de ' + card.Title}></DynamicImage>
                <div className='background'></div>
                <div className='media__img__icon'>
                  <span className={'feather ' + card.iconClass}></span>
                </div>
              </div>

              <div className='media__info'>
                <p className='media__info__title'>{card.Title}</p>

                <p className='media__info__details'>Formato: {card.Type}</p>
              </div>
            </a>
          );
        })}
      </div>
    </section>
  );
};
