import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";
import { Banner, BannerTop } from "./BannerTop.models";

type BannerTopProps = { children: React.ReactNode; };
const BannerTopContext = React.createContext<BannerTop | undefined>(undefined);

type Message =
    { type: 'banner'; value: Banner; };

const reducer = (state: BannerTop, message: Message) => {
    switch (message.type) {
        case 'banner':
            return {
                ...state, Banner: message.value
            }
        default:
            return { ...state };
    }
};

const BannerTopContextProvider = ({ children }: BannerTopProps) => {

    const [banner, setBanner] = useReducer(reducer, {} as BannerTop);
    const location = useLocation();

    useEffect(() => {
        fetchBanner();
    }, [location]);

    async function fetchBanner() {
        try {
            const banner = await axios.get(`/_api/web/lists/GetByTitle('Banners Paginas')/items?$select=Activo,Title,File/ServerRelativeUrl&$expand=File&$filter=Activo eq 1 and Pagina eq '${location.pathname.replace('/', '')}' or Pagina eq 'Default'`,
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: Banner[] = [];

                if (response.status == 200 && response.data && response.data.d && response.data.d.results)
                    response.data.d.results.forEach((slide: any) => {
                        result.push({
                            Titulo: slide.Title ? slide.Title : slide.File.Name,
                            Imagen: slide.File.ServerRelativeUrl
                        });
                    });

                return result;
            });

            setBanner({ type: 'banner', value: banner[0] });

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <BannerTopContext.Provider value={banner}>
            {children}
        </BannerTopContext.Provider>
    );

};

const useBanner = () => {
    const context = useContext(BannerTopContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};


export { useBanner, BannerTopContextProvider };