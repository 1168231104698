import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { FaqPage, Question } from "./PreguntasFrecuentes.models";


type FaqPageProviderProps = { children: React.ReactNode; };
const FaqPageContext = React.createContext<FaqPage>({ Questions: [] });

type Message = { type: 'questions'; value: Array<Question>; };

const reducer = (state: FaqPage, message: Message): FaqPage => {
    switch (message.type) {
        case 'questions':
            return { ...state, Questions: message.value };
        default:
            return { ...state };
    }
};

const FaqPageContextProvider = ({ children }: FaqPageProviderProps) => {
    const [state, dispatch] = useReducer(reducer, { Questions: [] });

    useEffect(() => {
        fetchQuestions();
    }, []);

    async function fetchQuestions() {
        try {
            const questions = await axios.get(`/_api/web/lists/GetByTitle('Preguntas Frecuentes')/items?$select=ID,Activo,Orden,Title,Respuesta,Categoria&$filter=Activo eq 1&$orderby=Orden`,
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                var result = Array<Question>();

                if (response.status === 200 && response.data && response.data.d && response.data.d.results)
                    response.data.d.results.forEach((pregunta: any) => {
                        result.push({
                            id: pregunta.ID,
                            title: pregunta.Title,
                            answer: pregunta.Respuesta,
                            categoria: pregunta.Categoria
                        });
                    });

                return result;
            });

            dispatch({ type: 'questions', value: questions });

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <FaqPageContext.Provider value={state} >
            {children}
        </FaqPageContext.Provider >
    );
};

const useFaqPage = () => {
    const context = useContext(FaqPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { useFaqPage, FaqPageContextProvider };

