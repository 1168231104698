import { useState } from "react";
import { BannerTop } from "../../../components/bannerTop/bannerTop";
import { Header } from "../../../components/header/header";
import { SideMenu } from "../../../components/sideMenu/sideMenu";
import { useBonusPage } from "../../../context/Fideicomiso/Bono/bono.context";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { PestanaBono } from "../../../context/Fideicomiso/Bono/bono.models";
import { Footer } from "../../../components/footer/footer";

import './bono.css';

export const BonoFideicomiso = () => {
    const context = useBonusPage();
    const [tabIndex, setTabIndex] = useState(0);

    return (
        <article>
            <Header />

            <BannerTop title={context?.Header?.Titulo} pageTitle={context?.Header?.Titulo} backgroundImage={context?.Header?.Imagen} />

            <div id="bono" className="article section">
                <div className="container mx-auto xl:flex">
                    <div className="col xl:w-1/3 xl:px-4 xl:order-last">
                        <SideMenu title="Productos" listItems={
                            context?.Fideicomisos?.map(
                                (fideicomiso) => {
                                    return {
                                        id: fideicomiso.Id,
                                        title: fideicomiso.Titulo,
                                        parentId: fideicomiso.PadreId
                                    };
                                })
                        }></SideMenu>
                    </div>
                    <div className="col xl:w-2/3 xl:pr-14">
                        <div className="article-text">
                            <section className="introduccion">
                                <div dangerouslySetInnerHTML={{ __html: context?.Introduccion || "" }}></div>
                            </section>

                            <section>
                                {
                                    (context?.Pestanas && context?.Pestanas.length > 0) &&
                                    < div className={"tabs-container"}>
                                        <Tabs selectedIndex={tabIndex} onSelect={(index: number) => setTabIndex(index)}>
                                            <TabList>
                                                {
                                                    context?.Pestanas &&
                                                    context?.Pestanas.map((tab: PestanaBono, index: number) => {
                                                        return (
                                                            <Tab key={tab.Id}>{tab.Titulo}</Tab>
                                                        );
                                                    })
                                                }
                                            </TabList>

                                            {
                                                context?.Pestanas &&
                                                context?.Pestanas.map((tab: PestanaBono, index: number) => {
                                                    return (
                                                        <TabPanel key={index}>
                                                            <div dangerouslySetInnerHTML={{ __html: tab.Contenido }}></div>
                                                        </TabPanel>
                                                    );
                                                })
                                            }
                                        </Tabs>
                                    </div>
                                }
                            </section>

                            <section className="conclusion">
                                <div dangerouslySetInnerHTML={{ __html: context?.Conclusion || "" }}></div>
                            </section>
                        </div>
                    </div>
                </div>
            </div >
            <Footer></Footer>
        </article >
    );
}