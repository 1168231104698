import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  ArticleWithImage,
  ArticleWithImageProps,
} from "../../../components/article/article-with-image";
import { BannerTop } from "../../../components/bannerTop/bannerTop";
import {
  GlobalSideMenu,
  ListItemProps,
} from "../../../components/sideMenu/globalSideMenu";
import { useFamiliaReservas } from "../../../context/Nosotros/FamiliaReservas.context";
import "./FamiliaReservas.css";
export interface FamiliaReservasProps {
  menu: ListItemProps[];
}
export const FamiliaReservas = (props: FamiliaReservasProps) => {
  const context = useFamiliaReservas();
  const location = useLocation();
  const { menu } = props;
  const [menuState, setMenu] = useState(menu);
  useEffect(() => {
    const _menu = menu.map((item) => {
      if (item.route === location.pathname) {
        //TODO: implement active route logic
      }
      return item;
    });

    // setMenu(_menu);
  }, [location.pathname, menu]);

  return (
    <article id="familia">
      <BannerTop title={context?.Header?.Titulo || ""}></BannerTop>
      <div className="article section">
        <div className="container mx-auto xl:flex">
          <div className="col xl:w-1/3 xl:px-4 xl:order-last">
            <GlobalSideMenu
              title="Secciones"
              listItems={menuState}
            ></GlobalSideMenu>
          </div>
          <div className="col xl:w-2/3 xl:pr-14">
            <div className="article-text">
              <h3 className="article-text__head-title">
                {context?.Header?.Subtitulo}
              </h3>
              <div className="article-text__organigrama">
                {context.Elementos?.map((filial, key) => {
                  if (filial.position === 1) {
                    return (
                      <a
                        key={key}
                        className="flex flex-wrap items-center article-text__organigrama-filial"
                        href={filial.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="article-text__image-box">
                          <img
                            src={filial.image}
                            alt=""
                            className="article-text__organigrama-image"
                          />
                        </div>
                        <div className="article-text__organigrama-text">
                          <p className="article-text__organigrama-desc">
                            {filial.detalle}
                          </p>
                        </div>
                      </a>
                    );
                  } else {
                    return (
                      <a
                        key={key}
                        href={filial.url}
                        target="_blank"
                        rel="noreferrer"
                        className="flex flex-wrap items-center article-text__organigrama-filial article-text__organigrama-filial--small"
                      >
                        <div className="article-text__image-box">
                          <img
                            src={filial.image}
                            alt=""
                            className="article-text__organigrama-image"
                          />
                        </div>
                        <div className="article-text__organigrama-text">
                          <p className="article-text__organigrama-desc">
                            {filial.detalle}
                          </p>
                        </div>
                      </a>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
