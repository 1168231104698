import axios from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { BannerTop } from '../../components/bannerTop/bannerTop';
import { StaticImage } from '../../components/base/staticImage/staticImage';
import { Footer } from '../../components/footer/footer';
import { Header } from '../../components/header/header';
import './Contacto.css';

export const Contacto = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  const onSubmit = (data: any) => {
    setShowConfirm(false);
    data['__metadata'] = { type: 'SP.Data.ContactoListItem' };
    data.Telefono = data.Telefono.toString();

    const post = axios
      .post("/Contacto/_api/web/lists/GetByTitle('Contacto')/items", data, {
        headers: {
          Accept: 'application/json;odata=verbose',
          'Content-Type': 'application/json;odata=verbose',
          'X-RequestDigest': document.querySelector<HTMLInputElement>('#__REQUESTDIGEST')?.value || ''
        }
      })
      .then((response) => {
        reset();
        if (response.status == 201) setShowConfirm(true);
      });
  };

  return (
    <>
      <Header></Header>
      <BannerTop title={'Contáctanos'} pageTitle='Contáctanos'></BannerTop>
      <main>
        <section className='contacto section'>
          <div className='container mx-auto'>
            <div className='contacto__row md:grid md:gap-6 md:grid-cols-2 lg:grid-cols-3'>
              <div className='contacto__info'>
                <span className='contacto__icon feather icon-message-circle'></span>
                <div className='contacto__text'>
                  <h3>Conversa con Nosotros</h3>
                  <p>Estamos dispuestos para responder tus preguntas y apoyarte en tus proyectos</p>
                </div>
              </div>
              <div className='contacto__info'>
                <span className='contacto__icon feather icon-mail'></span>
                <div className='contacto__text'>
                  <h3>Escríbenos</h3>
                  <p>
                    <Link
                      to='#'
                      className='contacto__text__email'
                      onClick={(e) => {
                        window.location.href = 'mailto:servicioalclienteFI@fiduciariareservas.com';
                        e.preventDefault();
                      }}>
                      servicioalclienteFI@fiduciariareservas.com
                    </Link>
                    <br />
                    Siempre disponibles para ti
                  </p>
                </div>
              </div>
              <div className='contacto__info'>
                <span className='contacto__icon feather icon-phone'></span>
                <div className='contacto__text'>
                  <h3>Llámanos</h3>
                  <p>
                    {' '}
                    <Link
                      to='#'
                      onClick={(e) => {
                        window.location.href = 'tel:8099604580';
                        e.preventDefault();
                      }}>
                      {' '}
                      (809) 960-4580
                    </Link>
                    <br />
                    Lunes a viernes de 8:00 a.m. a 5:00 p.m.
                  </p>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className='xl:flex contacto__row contacto__row--info'>
              <div className='map xl:w-4/12 xl:ml-auto xl:order-last'>
                <h3 className='contact-title'>Acércate a nosotros</h3>
                <p className='map__text'>
                  Ave. Roberto Pastoriza #358, Torre Roberto Pastoriza, Piso 7, Ensanche Piantini, Santo Domingo, D. N., República Dominicana.
                </p>
                <a href='https://goo.gl/maps/WNArbX1i1fFcuyvw6' target='_blank'>
                  <StaticImage src='//img/map/mapa-fiduciaria.jpg' alt='map' className='map__image' />
                  <p className='map__text map__text--blue'>
                    <span className='feather icon-map-pin'></span> Cómo llegar a nuestras oficinas
                  </p>
                </a>
              </div>
              <div className='xl:col-span-7 xl:w-7/12 contact-info'>
                <h3 className='items-center justify-between contact-title xl:flex'>
                  Envíanos un mensaje
                  <span>Los campos con (*) son obligatorios</span>
                </h3>

                <div className={showConfirm ? 'success-alert active' : 'success-alert'}>
                  <div className='icon-container'>
                    <span className='feather icon-check'></span>
                  </div>

                  <div className='message'>
                    <p>Tu mensaje ha sido enviado exitosamente.</p>
                    <p>Uno de nuestros agentes se pondrá en contacto contigo.</p>
                  </div>

                  <div className='button-container'>
                    <button
                      type='button'
                      onClick={() => {
                        setShowConfirm(false);
                      }}>
                      &times;
                    </button>
                  </div>
                </div>

                <div className='form-control'>
                  <input
                    placeholder='Nombre *'
                    className={errors.Title?.type === 'required' ? 'global-input invalid' : 'global-input'}
                    type='text'
                    {...register('Title', { required: true })}
                  />
                  <span className='invalid-text'>{errors.Title?.type === 'required' && 'Este campo es obligatorio'}</span>
                </div>
                <div className='justify-between md:flex'>
                  <div className='md:mr-6 md:w-1/3 form-control'>
                    <input
                      placeholder='Teléfono *'
                      className={'appearance-none ' + errors.Title?.type === 'required' ? 'global-input invalid' : 'global-input'}
                      type='number'
                      {...register('Telefono', { required: true, valueAsNumber: true })}
                    />
                    <span className='invalid-text'>{errors.Telefono?.type === 'required' && 'Este campo es obligatorio'}</span>
                    <span className='invalid-text'>{errors.Telefono?.type === 'valueAsNumber' && 'Formato de numero incorrecto'}</span>
                  </div>
                  <div className='flex-1 form-control'>
                    <input
                      placeholder='Correo Electrónico *'
                      className={errors.Title?.type === 'required' ? 'global-input invalid' : 'global-input'}
                      type='text'
                      {...register('Email', {
                        required: true,
                        pattern: /\S+@\S+\.\S+/
                      })}
                    />
                    <span className='invalid-text'>{errors.Email?.type === 'required' && 'Este campo es obligatorio'}</span>
                    <span className='invalid-text'>{errors.Email?.type === 'pattern' && 'Formato de correo incorrecto'}</span>
                  </div>
                </div>
                <div className='form-control'>
                  <textarea placeholder='Mensaje' className='global-textarea' id='mensaje' cols={30} rows={5} {...register('Mensaje', {})}></textarea>
                </div>
                <div className='justify-between xl:flex xl:space-x-4'>
                  <button
                    className='py-4 generic-button generic-button--transparent'
                    type='button'
                    onClick={() => {
                      reset();
                    }}>
                    Cancelar
                  </button>
                  <button className='py-4 generic-button' type='submit'>
                    Enviar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </>
  );
};
