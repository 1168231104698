import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { EducationGlosario, EducationPalabra } from "./models/Glosario.models";


type EducationGlosarioProviderProps = { children: React.ReactNode; };
const EducationGlosarioContext = React.createContext<EducationGlosario | undefined>(undefined);

type Message = { type: 'palabras'; value: EducationPalabra[]; };

const reducer = (state: EducationGlosario, message: Message) => {
    switch (message.type) {
        case 'palabras':
            return {
                ...state, Palabras: message.value
            };
        default:
            return { ...state };
    }
};
const EducationGlosarioContextProvider = ({ children }: EducationGlosarioProviderProps) => {

    const [glosario, setGlosario] = useReducer(reducer, {} as EducationGlosario);

    useEffect(() => {
        fetchPalabras();
    }, []);

    async function fetchPalabras() {
        try {
            const palabras = await axios.get("/Educativa/_api/web/lists/GetByTitle('Glosario - Palabras')/items?$select=Activo,Title,Contenido&$filter=Activo eq 1",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: EducationPalabra[] = [];

                if (response.status == 200 && response.data && response.data.d && response.data.d.results)
                    response.data.d.results.forEach((palabra: any) => {
                        result.push({
                            Palabra: palabra.Title,
                            Significado: palabra.Contenido
                        });
                    });

                return result;
            });

            setGlosario({ type: 'palabras', value: palabras });

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <EducationGlosarioContext.Provider value={glosario}>
            {children}
        </EducationGlosarioContext.Provider>
    );

};
const useEducationGlosario = () => {
    const context = useContext(EducationGlosarioContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { useEducationGlosario, EducationGlosarioContextProvider };