/* eslint-disable react/jsx-no-target-blank */
import './fideicomisoHome.css';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import { useEffect, useState } from 'react';
import { DynamicImage } from '../../components/base/dynamicImage/dynamicImage';
import { MediaVideo } from '../../context/Education/models/Multimedia.models';

interface Video {
  Thumbnail: string;
  Url: string;
  iconClass: string;
  lightbox: boolean;
}

export interface FideicomisoHomeProps {
  Video: MediaVideo;
  Descripcion: string;
}
export const FideicomisoHome = (props: FideicomisoHomeProps) => {
  var lightbox: any;
  const [video, setVideo] = useState<Video>();

  useEffect(() => {
    const _video = {
      Thumbnail: props.Video.Thumbnail,
      Url: props.Video.Url,
      iconClass: props.Video.Type.toLowerCase() === 'video' ? 'icon-play' : 'icon-download',
      lightbox: props.Video.Type.toLowerCase() === 'video' || props.Video.Type.toLowerCase() === 'imagen' ? true : false
    };
    setVideo(_video);
  }, [props]);

  useEffect(() => {
    if (video?.Url) {
      if (!lightbox)
        lightbox = GLightbox();
      else
        lightbox.reload();
    }
  }, [video]);

  return (
    <>
      <section>
        <a href={video?.Url} className={'glightbox media'} target='_blank'>
          <div className='media__img'>
            <DynamicImage src={video?.Thumbnail} alt={'Imagen de  Productos'}></DynamicImage>
            <div className='background'></div>
            <div className='media__img__icon'>
              <span className={video?.iconClass}></span>
            </div>
          </div>
        </a>
      </section>
      <section className='description mb-12'>
        <div dangerouslySetInnerHTML={{ __html: props?.Descripcion || '' }}></div>
      </section>
    </>
  );
};
