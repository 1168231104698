import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BannerTop } from "../../../components/bannerTop/bannerTop";
import {
  GlobalSideMenu,
  ListItemProps,
} from "../../../components/sideMenu/globalSideMenu";
import { useQuienesSomos } from "../../../context/Nosotros/QuienesSomos.context";
import "./QuienesSomos.css";
export interface QuienesSomosProps {
  menu: ListItemProps[];
}
export const QuienesSomos = (props: QuienesSomosProps) => {
  const context = useQuienesSomos();
  const location = useLocation();
  const { menu } = props;
  const [menuState, setMenu] = useState(menu);
  useEffect(() => {
    const _menu = menu.map((item) => {
      if (item.route === location.pathname) {
        //TODO: implement active route logic
      }
      return item;
    });

    // setMenu(_menu);
  }, [location.pathname, menu]);

  return (
    <article id="quienes-somos">
      <BannerTop title={context?.Header?.Title || ""}></BannerTop>
      <div className="article section">
        <div className="container mx-auto xl:flex">
          <div className="col xl:w-1/3 xl:px-4 xl:order-last">
            <GlobalSideMenu
              title="Secciones"
              listItems={menuState}
            ></GlobalSideMenu>
          </div>
          <div className="col xl:w-2/3 xl:pr-14">
            <div className="article-text">
              <h3 className="article-text__head-title">
                {context?.Header?.Subtitulo}
              </h3>
              <img
                className="article-text__image"
                src={context?.Header?.Image}
                alt=""
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: context?.Header?.Contenido || "",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};
