import { useState } from 'react';
import { Link } from 'react-router-dom';
import { DynamicImage } from '../base/dynamicImage/dynamicImage';

export interface OffersProps {
  offers: Array<Offer>;
}

export interface Offer {
  id: number;
  title: string;
  data: {
    image: string;
    text: string;
  };
}

export const Offers = (props: OffersProps) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <div className='container mx-auto' data-aos='fade-up'>
        <h1 className='text-center generic-title'>
          ¿Qué te ofrecemos?
          <span>Selecciona el Fideicomiso según tu actividad económica</span>
        </h1>
      </div>
      <div className='container offer__container mx-auto xl:flex'>
        <div className='offer__tabs-list xl:block xl:w-1/3 xl:px-4 order-2 xl:order-1' data-aos='fade-right'>
          {props?.offers?.map((tab, index) => (
            <div key={index + '-button'}>
              <div onClick={() => setActiveTab(index)} className={(activeTab === index ? 'active' : '') + ' offer__tab xl:block'}>
                <button className='offer__action-tab' type='button'>
                  {tab.title}
                </button>
              </div>
              <div className={`xl:hidden md:grid md:grid-cols-2 md:gap-6 offer__box-mobile ${activeTab === index ? ' active' : ''}`}>
                <div className='offer__image-box'>
                  <DynamicImage className='offer__image' src={tab.data.image} alt={'Imagen del servicio de ' + tab.title}></DynamicImage>
                </div>
                <div className='offer__text-box'>
                  <p className='offer__text'>{tab.data.text}</p>
                  <div className='button-container'>
                    <Link className='generic-button' to={'/Fideicomisos?fideicomisoId=' + tab.id}>
                      Conoce más
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className='offer__box xl:w-2/3 order-1 xl:order-2 mt-8 xl:mt-0' data-aos='fade-left'>
          {props?.offers?.map((tab, index) => (
            <>
              <div
                style={{ display: activeTab === index ? 'grid' : 'none' }}
                x-show={activeTab === index}
                className='offer__grid md:grid md:grid-cols-2 md:gap-6'
                key={index + '-box'}>
                <div className='order-2 offer__image-box'>
                  <DynamicImage className='offer__image' src={tab.data.image} alt={'Imagen del servicio de ' + tab.title}></DynamicImage>
                </div>
                <div className='offer__text-box'>
                  <p className='offer__text'>{tab.data.text}</p>
                  <Link className='generic-button' to={'/Fideicomisos?fideicomisoId=' + tab.id}>
                    Conoce más
                  </Link>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};
