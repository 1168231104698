import { PersonalDetail } from "../../../context/Nosotros/models/EquipoEjecutivo.model";
import './personalSpotlight.css';

export interface PersonalSpotlightProps {
    details: PersonalDetail;
}
export const PersonalSpotlight = (props: PersonalSpotlightProps) => {
    const { details } = props;
    return (
        <div className="personal-spotlight article-text">
            <h3 className="article-text__head-title">
                {details?.name}
            </h3>
            <h4 className="article-text__head-sub-title">{details?.position}</h4>
            <div className="exec-info">
                <div className="exec-info__img-container">
                    <img className="exec-info__img" src={details?.image} alt="" />
                </div>

                {details?.history ? <div className="exec-info__history" dangerouslySetInnerHTML={{ __html: details?.history }}>
                </div> : ""}
            </div>
        </div>
    );
};