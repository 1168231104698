import { Link } from "react-router-dom";
import { StaticImage } from "../../components/base/staticImage/staticImage";
import { NewsSlider } from "../../containers/newsSlider/newsSlider";
import { useNewsDetails } from "../../context/Noticias/NewsDetails.context";


export const NoticiasDetails = () => {
    const context = useNewsDetails();


    return (
        <section>
            {/* <div className="project">
                <div className="project-head">
                    <div className="container mx-auto">
                        <div className="items-start justify-between head-section xl:flex">
                            <div>

                                <h3 className="my-1 xl:my-4 project-head__head-title">{context?.News?.title}</h3>
                                <h4 className="head-section__city">
                                    <span className="feather icon-map-pin"></span>
                                    {context?.Project?.city}
                                </h4>
                            </div>
                            <span className="hidden xl:inline-block status-tag">
                                <span className="circle">●</span>
                                {context?.Project?.status}
                            </span>
                        </div>
                        <ProjectPhotoGallery images={context?.Imagenes} prices={context?.Project?.prices}></ProjectPhotoGallery>
                        <Article articleDetails={context?.Summary}></Article>
                        <ProjectInfo details={context?.Details} developer={context?.Builder}></ProjectInfo>
                        <ProjectsSlider projects={context?.RelatedProjects}></ProjectsSlider>
                    </div>
                </div>
            </div> */}

            <div className="noticias noticias--open">
                <div className="container mx-auto">
                    <div className="project-head">
                        <Link className="head-section__back" to="/Noticias">
                            <span className="feather icon-chevron-left"></span>
                            Volver atrás
                        </Link>
                    </div>
                    <div className="noticias__box lg:flex ">
                        <div className="noticias__text noticias__text--no-padding">
                            <h3 className="noticias__title noticias__title--open">{context?.News?.title}</h3>
                            <div className="noticias__date">
                                <h4><span className="feather icon-calendar"></span>{context?.News?.dateString}</h4>
                            </div>
                            <div className="noticias__col lg:flex lg:justify-between">
                                <div className="social-share lg:order-last">
                                    <div className="social-share__box">
                                        <h4 className="hidden mt-0 lg:block">Compartir</h4>
                                        <a
                                            href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href}
                                        >
                                            <StaticImage className="inline-block" src="//img/noticias/fb-icon.svg" alt="Logo Facebook"></StaticImage></a>
                                        <a
                                            href={"http://www.linkedin.com/shareArticle?mini=true&url=" + window.location.href}
                                        >
                                            <StaticImage className="inline-block" src="//img/noticias/linkedin-icon.svg" alt="Logo LinkedIn"></StaticImage>
                                        </a>
                                        <a
                                            href={"https://twitter.com/share?url=" + window.location.href}
                                        >
                                            <StaticImage className="inline-block" src="//img/noticias/twitter-icon.svg" alt="Logo Twitter"></StaticImage>
                                        </a>
                                        {/* <a
                                            href={"https://twitter.com/share?url=" + window.location.href}
                                        >
                                            <StaticImage className="inline-block" src="//img/noticias/instagram-icon.svg" alt="Logo Instagram"></StaticImage>
                                        </a> */}
                                        <h4 className="hidden mt-6 lg:block">Enviar</h4>
                                        <a href={"mailto:?body=" + window.location.href}>
                                            <StaticImage className="inline-block" src="//img/noticias/email-icon.svg" alt="Email"></StaticImage>
                                        </a>
                                        <a href={"https://api.whatsapp.com/send?text=" + window.location.href}>
                                            <StaticImage className="inline-block" src="//img/noticias/whatsapp-icon.svg" alt="Logo WhatsApp"></StaticImage>
                                        </a>
                                        <h4 className="hidden mt-6 lg:block">Imprimir</h4>
                                        <a href="##" onClick={() => window.print()}>
                                            <StaticImage className="inline-block" src="//img/noticias/print-icon.svg" alt="Imprimir"></StaticImage>
                                        </a>

                                    </div>

                                </div>
                                <div className="noticias__parragraf noticias__parragraf--open">
                                    <div className="noticias__image-box ">
                                        <img src={context?.News?.imageDetailsUrl} alt="" className="noticias__image noticias__image--open" />
                                    </div>
                                    <div id="content" dangerouslySetInnerHTML={{ __html: context?.News?.content }}>

                                    </div>
                                    <NewsSlider news={context?.RelatedNews} ></NewsSlider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};