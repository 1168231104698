import React from "react";
import { Route, Routes } from "react-router-dom";
import { BannerTop } from "../../components/bannerTop/bannerTop";
import { Footer } from "../../components/footer/footer";
import { Header } from "../../components/header/header";
import { NoticiasList } from "../../containers/noticiasList/noticiasList";
import { NewsListContextProvider } from "../../context/Noticias/NewsList.context";
import "./Noticias.css";
import "./../proyectos/Proyectos.css";
import { NewsDetailsContextProvider } from "../../context/Noticias/NewsDetails.context";
import { NoticiasDetails } from "./NoticiasDetail";

export const Noticias = () => {



    return (
        <>
            <Header></Header>
            <BannerTop title={"Sala de Prensa"}></BannerTop>

            <Routes>
                <Route path="" element={
                    <React.Suspense fallback={<>...</>}>
                        <NewsListContextProvider>
                            <NoticiasList />
                        </NewsListContextProvider>
                    </React.Suspense>
                }>
                </Route>
                <Route path="Noticia" element={
                    <React.Suspense fallback={<>...</>}>
                        <NewsDetailsContextProvider>
                            <NoticiasDetails />
                        </NewsDetailsContextProvider>
                    </React.Suspense>
                }>
                </Route>
            </Routes>
            <Footer></Footer>
        </>
    );
};