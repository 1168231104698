import { BannerTop, BannerTopProps } from "../../components/bannerTop/bannerTop";
import { Footer } from "../../components/footer/footer";
import { Header } from "../../components/header/header";
import { usePoliticasDePrivacidad } from "../../context/PoliticasDePrivacidad/PoliticasDePrivacidad..context";
import "./politicasDePrivacidad.css";
export const PoliticasDePrivacidad = () => {
    const context = usePoliticasDePrivacidad();

    const bannerTopProps: BannerTopProps = {
        title: 'Políticas de Privacidad'
    };

    return (
        <article id="politicas-privacidad">
            <Header></Header>
            <BannerTop {...bannerTopProps}></BannerTop>
            <main>
                <div className="container mx-auto xl:flex">
                    <div className="article-text">
                        <h3 className="article-text__head-title">
                            {context?.title}
                        </h3>
                        <div className="content" dangerouslySetInnerHTML={{ __html: context?.content || "" }}></div>
                    </div>
                </div>
            </main>
            <Footer></Footer>
        </article>
    );
};