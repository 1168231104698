import { BannerTop, BannerTopProps } from "../../components/bannerTop/bannerTop";
import { Footer } from "../../components/footer/footer";
import { Header } from "../../components/header/header";
import { useTerminosDeUso } from "../../context/TerminosDeUso/TerminosDeUso.context";
import "./terminosDeUsos.css";
export const TerminosDeUso = () => {
    const context = useTerminosDeUso();

    const bannerTopProps: BannerTopProps = {
        title: 'Términos de Uso'
    };

    return (
        <article id="terminos-uso">
            <Header></Header>
            <BannerTop {...bannerTopProps}></BannerTop>
            <main>
                <div className="container mx-auto xl:flex">
                    <div className="article-text">
                        <h3 className="article-text__head-title">
                            {context?.title}
                        </h3>
                        <div className="content" dangerouslySetInnerHTML={{ __html: context?.content || "" }}></div>
                    </div>
                </div>
            </main>
            <Footer></Footer>
        </article>
    );
};