import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { EducationMultimedia, Media } from './models/Multimedia.models';

type EducationMultimediaProviderProps = { children: React.ReactNode };
const EducationMultimediaContext = React.createContext<EducationMultimedia | undefined>(undefined);

type Message = { type: 'medias'; value: Media[] } | { type: 'tiposMedia'; value: string[] };

const reducer = (state: EducationMultimedia, message: Message) => {
  switch (message.type) {
    case 'medias':
      return {
        ...state,
        Multimedia: message.value
      };
    case 'tiposMedia':
      return {
        ...state,
        TiposMedia: message.value
      };
    default:
      return { ...state };
  }
};
const EducationMultimediaContextProvider = ({ children }: EducationMultimediaProviderProps) => {
  const [multimedia, setMultimedia] = useReducer(reducer, {} as EducationMultimedia);

  useEffect(() => {
    fetchMedia();
    fetchFileTypes();
  }, []);

  async function fetchMedia() {
    try {
      const medias = await axios
        .get(
          "/Educativa/_api/web/lists/GetByTitle('Multimedia - Media')/items?$select=Activo,Title,Enlace,TipoMedia,Created,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1",
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          let result: Media[] = [];

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((media: any) => {
              result.push({
                Title: media.Title,
                Type: media.TipoMedia,
                Thumbnail: media.AttachmentFiles.results.length > 0 ? media.AttachmentFiles.results[0].ServerRelativeUrl : '',
                Url: media.Enlace,
                FechaPublicacion: new Date(media.Created)
              });
            });

          return result;
        });

      setMultimedia({ type: 'medias', value: medias });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchFileTypes() {
    try {
      const tipos = await axios
        .get("/Educativa/_api/web/lists/GetByTitle('Multimedia - Media')/fields?$filter=EntityPropertyName eq 'TipoMedia'", {
          headers: {
            Accept: 'application/json;odata=verbose',
            'Content-Type': 'application/json;odata=verbose'
          }
        })
        .then((response) => {
          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            return response.data.d.results[0].Choices.results;
          else return [];
        });

      setMultimedia({ type: 'tiposMedia', value: tipos });
    } catch (error) {
      console.log(error);
    }
  }

  return <EducationMultimediaContext.Provider value={multimedia}>{children}</EducationMultimediaContext.Provider>;
};

const useEducationMultimedia = () => {
  const context = useContext(EducationMultimediaContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};

export { useEducationMultimedia, EducationMultimediaContextProvider };
