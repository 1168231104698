import { Header } from "../../components/header/header";
import { Routes, Route, Navigate } from "react-router-dom";
import { Footer } from "../../components/footer/footer";
import React from "react";
import { QuienesSomosContextProvider } from "../../context/Nosotros/QuienesSomos.context";
import { QuienesSomos } from "./quienes-somos/QuienesSomos";
import { EquipoEjecutivoContextProvider } from "../../context/Nosotros/EquipoEjecutivo.contex";
import { EquipoEjecutivo } from "./equipo-ejecutivo/EquipoEjecutivo";
import { ListItemProps } from "../../components/sideMenu/globalSideMenu";
import { GobiernoCorporativoContextProvider } from "../../context/Nosotros/GobiernoCorporativo.context";
import { GobiernoCorporativo } from "./gobierno-corporativo/GobiernoCorporativo";
import { MiembrosConsejoContextProvider } from "../../context/Nosotros/MiembrosConsejo.contex";
import { MiembrosConsejo } from "./miembros-consejo/MiembrosConsejo";
import { HistoriaContextProvider } from "../../context/Nosotros/Historia.context";
import { Historia } from "./historia/Historia";
import { FamiliaReservasContextProvider } from "../../context/Nosotros/FamiliaReservas.context";
import { FamiliaReservas } from "./familia-reservas/FamiliaReservas";

export const Nosotros = () => {
  const sideMenuProps: ListItemProps[] = [
    { id: 1, title: "¿Quiénes Somos?", route: "/Nosotros/QuienesSomos" },
    { id: 2, title: "Historia", route: "/Nosotros/Historia" },
    {
      id: 3,
      title: "Gobierno Corporativo",
      route: "/Nosotros/GobiernoCorporativo",
    },
    {
      id: 4,
      title: "Miembros del Consejo",
      route: "/Nosotros/GobiernoCorporativo/MiembrosConsejo",
      parentId: 3,
    },
    { id: 5, title: "Equipo Ejecutivo", route: "/Nosotros/EquipoEjecutivo" },
    {
      id: 6,
      title: "Filiales Familia Reservas",
      route: "/Nosotros/FamiliaReservas",
    },
  ];

  return (
    <div>
      <Header></Header>
      <Routes>
        <Route path="" element={<Navigate replace to="QuienesSomos" />} />
        <Route
          path="QuienesSomos"
          element={
            <React.Suspense fallback={<>...</>}>
              <QuienesSomosContextProvider>
                <QuienesSomos menu={sideMenuProps} />
              </QuienesSomosContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="Historia"
          element={
            <React.Suspense fallback={<>...</>}>
              <HistoriaContextProvider>
                <Historia menu={sideMenuProps} />
              </HistoriaContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="EquipoEjecutivo"
          element={
            <React.Suspense fallback={<>...</>}>
              <EquipoEjecutivoContextProvider>
                <EquipoEjecutivo menu={sideMenuProps} />
              </EquipoEjecutivoContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="GobiernoCorporativo"
          element={
            <React.Suspense fallback={<>...</>}>
              <GobiernoCorporativoContextProvider>
                <GobiernoCorporativo menu={sideMenuProps} />
              </GobiernoCorporativoContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="GobiernoCorporativo/MiembrosConsejo"
          element={
            <React.Suspense fallback={<>...</>}>
              <MiembrosConsejoContextProvider>
                <MiembrosConsejo menu={sideMenuProps} />
              </MiembrosConsejoContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="FamiliaReservas"
          element={
            <React.Suspense fallback={<>...</>}>
              <FamiliaReservasContextProvider>
                <FamiliaReservas menu={sideMenuProps} />
              </FamiliaReservasContextProvider>
            </React.Suspense>
          }
        ></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
};
