import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { Link } from "react-router-dom";
import { StaticImage } from "../../components/base/staticImage/staticImage";
import { News } from "../../context/Noticias/models/News.models";

export interface NewsSliderProps {
    news: News[];
}
export const NewsSlider = (props: NewsSliderProps) => {

    const { news: newsList } = props;
    const sliderOptions = {
        perPage: 2,
        perMove: 1,
        arrows: true,
        pagination: false,
        breakpoints: {
            '768': {
                perPage: 2,
                perMove: 1,
                arrows: false,
                pagination: true,
            },
            425: {
                perPage: 1,
                arrows: false,
                pagination: true,
            },
        }
    };

    return (
        <div className="noticias__other">
            <div className="justify-between md:flex">
                <h3 className="article-text__title article-text__title--project">Otras Noticias</h3>
                <Link to={"/Noticias"}>Ver todas las noticias</Link>
            </div>

            <hr className="mt-6" />
            <Splide
                id="moreNewsSlide"
                options={sliderOptions}
                className="other__slide"
                renderControls={() => (
                    <div className="splide__arrows">
                        <button className="splide__arrow splide__arrow--prev" type="button">
                            <StaticImage src="/img/left-arrow.svg" alt=""></StaticImage>
                        </button>
                        <button className="splide__arrow splide__arrow--next" type="button">
                            <StaticImage src="/img/right-arrow.svg" alt=""></StaticImage>
                        </button>
                    </div>)
                }
            >
                {
                    newsList?.map((news, index) => (
                        <SplideSlide key={index}>
                            <div className="card">
                                <Link to={"/Noticias/Noticia?noticiaId=" + news.id}><p>{news.title}</p></Link>

                                <div className="noticias__date noticias__date--other">
                                    <h4><span className="feather icon-calendar"></span>{news.dateString}
                                    </h4>
                                </div>
                            </div>
                        </SplideSlide>
                    ))
                }
            </Splide>
        </div>
    );
};