import { Link } from "react-router-dom";
import { HomeCifras } from "../../context/models/Home.models";
import { StaticImage } from "../base/staticImage/staticImage";

export interface RecordsProps {
    cifras: HomeCifras;
}

export const Records = (props: RecordsProps) => {
    return (
        <div className="container mx-auto" data-aos="fade-up">
            <h1 className=" generic-title generic-title--record">
                ¿Por qué nosotros?
                <span>Porque nuestros números brindan tranquilidad</span>
            </h1>
            <div className="items-center record__box md:flex md:justify-between xl:justify-evenly">
                <div className="record__info">
                    <h2 className="record__number">
                        <span className="record__plus feather icon-plus"></span>{props?.cifras?.Fideicomiso}
                    </h2>
                    <h3 className="record__detail">Fideicomisos <br />
                        Constituidos</h3>
                </div>
                <div className="hidden record__separator md:inline-block invisible">
                    <StaticImage src="/img/right-arrow.svg" alt="Flecha Derecha"></StaticImage>
                </div>
                <div className="record__info">
                    <h2 className="record__number">
                        <span className="record__plus feather icon-plus"></span>{props?.cifras?.Patrimonio}
                    </h2>
                    <h3 className="record__detail">RD$ MM en Activos</h3>
                </div>
                <div className="hidden record__separator md:inline-block invisible">
                    <StaticImage src="/img/right-arrow.svg" alt="Flecha Derecha"></StaticImage>
                </div>
                <div className="record__info">
                    <h2 className="record__number">
                        <span className="record__plus feather icon-plus"></span>{props?.cifras?.Ranking}
                    </h2>
                    <h3 className="record__detail">RD$ MM en Activos Administrados</h3>
                </div>
            </div>
            <div className="text-center transform -translate-y-6 anchor-block">
                <Link to="/Nosotros" className="generic-button">
                    Más sobre nosotros
                </Link>
            </div>
        </div>
    );
};