import { useEffect, useState } from 'react';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';
import './horizontalMediaContainer.css';
import { Media } from '../../context/Education/models/Multimedia.models';
import { HorizontalMediaCard } from '../../components/cards/mediaHorizontalCard/mediaHorizontalCard';

export interface HorizontalMediaContainerProps {
    className?: string;
    media: Array<MediaProps>;
}

export interface MediaProps extends Media {
    Descripcion: string;
}

interface HorizontalMediaCard extends MediaProps {
    iconClass: string;
    lightbox: boolean;
}

export const HorizontalMediaContainer = (props: HorizontalMediaContainerProps) => {

    const lightbox = GLightbox();
    const [mediaCards, setMediaCards] = useState<Array<HorizontalMediaCard>>([]);

    useEffect(() => {
        if (props.media && props.media.length > 0)
            setMediaCards(
                props.media.map(
                    (media) => {
                        return {
                            Title: media.Title,
                            Descripcion: media.Descripcion,
                            Thumbnail: media.Thumbnail,
                            Url: media.Url,
                            iconClass: media.Type.toLowerCase() == "video" ? "icon-play" : "icon-download",
                            lightbox: (media.Type.toLowerCase() == "video" || media.Type.toLowerCase() == "imagen") ? true : false,
                            FechaPublicacion: new Date(),
                            Type: media.Type
                        };
                    })
            );
    }, [props.media]);

    useEffect(() => {
        lightbox.reload();
    }, [mediaCards]);

    return (
        <div>
            {
                mediaCards?.map((card: HorizontalMediaCard, index: number) => {
                    return (
                        <HorizontalMediaCard title={card.Title}
                            descripcion={card.Descripcion}
                            thumbnail={card.Thumbnail}
                            iconClass={card.iconClass}
                            lightbox={card.lightbox}
                            url={card.Url}></HorizontalMediaCard>

                    );
                })
            }
        </div>
    );
};