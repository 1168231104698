import { Dialog, Transition } from "@headlessui/react";
import { useState } from "react";
import { PersonalDetail } from "../../../context/Nosotros/models/EquipoEjecutivo.model";
import "./personalDetails.css";
export interface PersonalDetailsProps {
    details: PersonalDetail;
    modal?: boolean;
}
export const PersonalDetails = (props: PersonalDetailsProps) => {
    const { details } = props;
    const [modalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!modalOpen);
    return (

        <li className="board-container__member" onClick={() => { if (props.modal) toggleModal() }}>
            <div className="img-container">
                <img src={details.image} alt="" />
            </div>
            <strong className="name">{details.name}</strong>
            <p className="position">{details.position}</p>


            <Dialog open={modalOpen} onClose={() => setModalOpen(false)} >
                <Transition show={modalOpen}
                    className="overlay-member"
                    enter=""
                    enterFrom=""
                    enterTo="transition duration-150"
                    leave=""
                    leaveFrom="transition duration-150"
                    leaveTo="" onClick={() => setModalOpen(false)}></Transition>
                <div id="memberModal" className="ease-out transform adressmodal duration-400 adressmodal--member">

                    <button  type="button" id="closeMember" className="adressmodal__close" onClick={() => setModalOpen(false)} >
                        <span className="feather icon-x"></span>
                    </button>
                    <div className="member-modal">
                        <div className="member-modal__content-wrapper hide-on-desktop" >
                            <h4 className="member-modal__name">{details.name}</h4>
                            <h4 className="member-modal__position">{details.position}</h4>
                        </div>
                        <div className="member-modal__image">
                            <img src={details.imageBig || details.image} alt="" />
                        </div>
                        <div style={{ height: "521px" }}>
                            <div className="member-modal__content-wrapper hide-on-mobile" >
                                <h4 className="member-modal__name">{details.name}</h4>
                                <h4 className="member-modal__position">{details.position}</h4>
                            </div>
                            {details?.history ? <div className="member-modal__text">
                                <div className="exec-info__history" dangerouslySetInnerHTML={{ __html: details?.history }}>
                                </div>
                            </div> : ""}
                        </div>
                    </div>
                </div>
            </Dialog>
        </li >);
};