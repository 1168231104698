import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { politicasDePrivacidadMockData } from "./mock/PoliticasDePrivacidad.mock";
import { PoliticasDePrivacidad } from "./models/PoliticasDePrivacidad.model";


type PoliticasDePrivacidadProviderProps = { children: React.ReactNode; };
const PoliticasDePrivacidadContext = React.createContext<PoliticasDePrivacidad>({} as PoliticasDePrivacidad);

type Message = { type: 'politicas'; value: PoliticasDePrivacidad; };

const reducer = (state: PoliticasDePrivacidad, message: Message): PoliticasDePrivacidad => {
    switch (message.type) {
        case 'politicas':
            return { ...state, ...message.value };
        default:
            return { ...state };
    }
};

const PoliticasDePrivacidadContextProvider = ({ children }: PoliticasDePrivacidadProviderProps) => {
    const [state, dispatch] = useReducer(reducer, {} as PoliticasDePrivacidad);

    useEffect(() => {
        fetchDocuments();
    }, []);

    async function fetchDocuments() {
        try {
            const terminos = await axios.get("/_api/web/lists/GetByTitle('Politicas De Privacidad')/items?$select=Title,Contenido",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: PoliticasDePrivacidad[] = [];

                if (response.status === 200 && response.data && response.data.d && response.data.d.results)
                    response.data.d.results.forEach((terminos: any) => {
                        result.push({
                            title: terminos.Title,
                            content: terminos.Contenido
                        });
                    });

                return result;
            });

            dispatch({ type: 'politicas', value: terminos[0] });

            // const mockData = politicasDePrivacidadMockData;
            // dispatch({ type: 'politicas', value: mockData });

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <PoliticasDePrivacidadContext.Provider value={state} >
            {children}
        </PoliticasDePrivacidadContext.Provider >
    );
};

const usePoliticasDePrivacidad = () => {
    const context = useContext(PoliticasDePrivacidadContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { usePoliticasDePrivacidad, PoliticasDePrivacidadContextProvider };

