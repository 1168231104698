import React, { useContext, useEffect, useReducer } from "react";

import { News } from "./models/News.models";
import { NewsList } from "./models/NewsList.model";
import { NewsMockData } from "./mock/News.mock";
import axios from "axios";

type NewsListProviderProps = { children: React.ReactNode };
const NewsListContext = React.createContext<NewsList>({ NewsList: [] });

type Message = { type: "news"; value: News[] };

const reducer = (state: NewsList, message: Message): NewsList => {
  switch (message.type) {
    case "news":
      return { ...state, NewsList: message.value };
    default:
      return { ...state };
  }
};

const NewsListContextProvider = ({ children }: NewsListProviderProps) => {
  const [state, dispatch] = useReducer(reducer, { NewsList: [] });

  useEffect(() => {
    fetchNewsList();
  }, []);

  async function fetchNewsList() {
    try {
      const news = await axios
        .get(
          `/Noticias/_api/web/lists/GetByTitle('Noticias')/items?$filter=Activo eq 1&$orderby=Fecha desc`,
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          var result = Array<News>();

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((news: any) => {
              result.push({
                id: news.ID,
                title: news.Title,
                imageDetailsUrl: news?.Detalle?.Url,
                imageThumbnailUrl: news?.Lista?.Url,
                content: news.Contenido,
                date: new Date(news.Fecha),
                dateString: new Date(news.Fecha).toLocaleDateString("es-DO", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }),
                externalLink: news?.Link_x0020_Externo?.Url,
                social: news?.Tipo_x0020_de_x0020_Red_x0020_So,
              });
            });
          return result;
        });

      dispatch({ type: "news", value: news });

      // const newsMockData = NewsMockData;
      // dispatch({ type: 'news', value: newsMockData });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <NewsListContext.Provider value={state}>
      {children}
    </NewsListContext.Provider>
  );
};

const useNewsList = () => {
  const context = useContext(NewsListContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useNewsList, NewsListContextProvider };
