import React from "react";
import { Route, Routes } from "react-router-dom";
import { BannerTop, BannerTopProps } from "../../components/bannerTop/bannerTop";
import { Footer } from "../../components/footer/footer";
import { Header } from "../../components/header/header";
import { ProjectList } from "../../containers/projectList/projectList";
import { ProjectDetailsContextProvider } from "../../context/Projects/ProjectDetails.context";
import { ProjectListContextProvider } from "../../context/Projects/ProjectList.context";
import "./Proyectos.css";
import { ProyectosDetails } from "./ProyectosDetails";
export const Proyectos = () => {

    const bannerTopProps: BannerTopProps = {
        title: 'Proyectos'
    };

    return (<>
        <Header></Header>
        <BannerTop {...bannerTopProps}></BannerTop>
        <Routes>
            <Route path="" element={
                <React.Suspense fallback={<>...</>}>
                    <ProjectListContextProvider>
                        <ProjectList />
                    </ProjectListContextProvider>
                </React.Suspense>
            }>
            </Route>
            <Route path="Detalles" element={
                <React.Suspense fallback={<>...</>}>
                    <ProjectDetailsContextProvider>
                        <ProyectosDetails />
                    </ProjectDetailsContextProvider>
                </React.Suspense>
            }>
            </Route>
        </Routes>
        <Footer></Footer>
    </>);
};