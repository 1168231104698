import { TermsContainer } from '../../../containers/termsContainer/termsContainer';
import { useEducationGlosario } from '../../../context/Education/glosario.context';
import './Glosario.css';

export const EducativaGlosario = () => {
    const context = useEducationGlosario();

    return (
        <div className="educativa-glosario section">
            <div className="article">
                <div className="container mx-auto">
                    <div className="article-text">
                        <TermsContainer words={context?.Palabras || []}></TermsContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};