import axios from "axios";
import DOMPurify from "dompurify";
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { useLocation } from "react-router-dom";
import { Fideicomiso } from "../models/fideicomiso.models";
import { BonusPage, HeaderBono, PestanaBono } from "./bono.models";
type BonusPageProps = { children: ReactNode; };
const BonusPageContext = createContext<BonusPage | undefined>(undefined);
type Message =
    { type: "banner"; value: HeaderBono; }
    | { type: "titulo"; value: string; }
    | { type: "introduccion"; value: string; }
    | { type: "pestanas"; value: Array<PestanaBono>; }
    | { type: "conclusion"; value: string; }
    | { type: "fideicomisos"; value: Array<Fideicomiso>; };
const reducer = (state: BonusPage, message: Message) => {
    switch (message.type) {
        case "banner":
            return {
                ...state,
                Header: message.value,
            };
        case "titulo":
            return {
                ...state,
                Titulo: message.value,
            };
        case "introduccion":
            return {
                ...state,
                Introduccion: message.value,
            };
        case "pestanas":
            return {
                ...state,
                Pestanas: message.value,
            };
        case "conclusion":
            return {
                ...state,
                Conclusion: message.value,
            };
        case "fideicomisos":
            return {
                ...state,
                Fideicomisos: message.value,
            };
        default:
            return { ...state };
    }
};
const BonusPageContextProvider = ({ children }: BonusPageProps) => {
    const [state, dispatch] = useReducer(reducer, {} as BonusPage);
    const location = useLocation();
    const [bonoId, setBonoId] = useState(
        new URLSearchParams(location.search).get("bonoId")
    );
    useEffect(() => {
        setBonoId(new URLSearchParams(location.search).get("bonoId"));
    }, [location]);
    useEffect(() => {
        if (bonoId) {
            fetchHeader();
            fetchBonus();
            fetchTabs();
            fetchFideicomisos();
        }
    }, [
        bonoId
    ]);
    const fetchHeader = useCallback(async () => {
        try {
            const banner = await axios
                .get(
                    `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - Bonos Banner')/items?$select=Activo,Title,Attachments,AttachmentFiles,BonoId&$expand=AttachmentFiles&$filter=Activo eq 1 and Bono/ID eq ${bonoId}&$top=1`,
                    {
                        headers: {
                            Accept: "application/json;odata=verbose",
                            "Content-Type": "application/json;odata=verbose",
                        },
                    }
                )
                .then((response) => {
                    if (
                        response.status === 200 &&
                        response.data &&
                        response.data.d &&
                        response.data.d.results &&
                        response.data.d.results.length
                    )
                        return {
                            Titulo: response.data.d.results[0].Title,
                            Imagen: response.data.d.results[0].Attachments
                                ? response.data.d.results[0].AttachmentFiles.results[0].ServerRelativeUrl
                                : ""
                        };
                    else
                        return {};
                });
            if (banner)
                dispatch({ type: "banner", value: banner });
        } catch (error) {
            console.log(error);
        }
    }, []);
    const fetchBonus = useCallback(async () => {
        try {
            const bono = await axios
                .get(
                    `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - Bonos')/items?$select=Activo,Title,Introduccion,Conclusion&$filter=Activo eq 1 and Id eq ${bonoId}&$top=1`,
                    {
                        headers: {
                            Accept: "application/json;odata=verbose",
                            "Content-Type": "application/json;odata=verbose",
                        },
                    }
                )
                .then((response) => {
                    let result: BonusPage = {};
                    if (
                        response.status === 200 &&
                        response.data &&
                        response.data.d &&
                        response.data.d.results &&
                        response.data.d.results.length
                    ) {
                        result.Titulo = response.data.d.results[0].Title;
                        result.Introduccion = DOMPurify.sanitize(
                            response.data.d.results[0].Introduccion,
                            {
                                USE_PROFILES: { html: true },
                            }
                        );
                        result.Conclusion = DOMPurify.sanitize(
                            response.data.d.results[0].Conclusion,
                            {
                                USE_PROFILES: { html: true },
                            }
                        );
                    }
                    return result;
                });
            if (bono.Introduccion)
                dispatch({ type: "introduccion", value: bono.Introduccion });
            if (bono.Conclusion)
                dispatch({ type: "conclusion", value: bono.Conclusion });
        } catch (error) {
            console.log(error);
        }
    }, []);
    const fetchTabs = useCallback(async () => {
        try {
            const tabs = await axios
                .get(
                    `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos - Bonos Pestanas')/items?$select=ID,Activo,Title,Contenido,Orden,BonoId&$filter=Activo eq 1 and Bono/ID eq ${bonoId}&$orderby=Orden&$top=1`,
                    {
                        headers: {
                            Accept: "application/json;odata=verbose",
                            "Content-Type": "application/json;odata=verbose",
                        },
                    }
                )
                .then((response) => {
                    let result: PestanaBono[] = [];
                    if (
                        response.status === 200 &&
                        response.data &&
                        response.data.d &&
                        response.data.d.results &&
                        response.data.d.results.length
                    )
                        response.data.d.results.forEach((data: any) => {
                            result.push({
                                Id: data.ID,
                                Titulo: data.Title,
                                Contenido: DOMPurify.sanitize(
                                    data.Contenido,
                                    {
                                        USE_PROFILES: { html: true },
                                    }
                                ),
                                Orden: data.Orden
                            });
                        });
                    return result;
                });
            if (tabs)
                dispatch({ type: "pestanas", value: tabs });
        } catch (error) {
            console.log(error);
        }
    }, []);
    const fetchFideicomisos = useCallback(async () => {
        try {
            const fideicomisos = await axios
                .get(
                    `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos')/items?$select=ID,Activo,Orden,Title,PadreId&$filter=Activo eq 1&$orderby=Orden`,
                    {
                        headers: {
                            Accept: "application/json;odata=verbose",
                            "Content-Type": "application/json;odata=verbose",
                        },
                    }
                )
                .then((response) => {
                    var result = Array<Fideicomiso>();
                    if (
                        response.status === 200 &&
                        response.data &&
                        response.data.d &&
                        response.data.d.results
                    )
                        response.data.d.results.forEach((fideicomiso: any) => {
                            result.push({
                                Id: fideicomiso.ID,
                                Titulo: fideicomiso.Title,
                                PadreId: fideicomiso.PadreId,
                            });
                        });
                    return result;
                });
            dispatch({ type: "fideicomisos", value: fideicomisos });
        } catch (error) {
            console.log(error);
        }
    }, []);
    return (
        <BonusPageContext.Provider value={state}>
            {children}
        </BonusPageContext.Provider>
    );
};
const useBonusPage = () => {
    const context = useContext(BonusPageContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};
export { useBonusPage, BonusPageContextProvider };

