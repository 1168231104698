import { PersonalDetail } from "../../../context/Nosotros/models/EquipoEjecutivo.model";
import { PersonalDetails } from "../personalDetail/personalDetails";
import "../../article/article.css";
import "./personalList.css";

export interface PersonalListProps {
    title: string;
    personalList: PersonalDetail[];
    modal?: boolean;
}
export const PersonalList = (props: PersonalListProps) => {
    const { personalList } = props;
    return (<div className="article-text">
        <div className="board-container">
            <h3 className="article-text__head-title">{props.title}</h3>

            <ul className="board-container__member-list">
                {
                    personalList?.map((personalDetail) => (
                        <PersonalDetails key={personalDetail.name} details={personalDetail} modal={props.modal}></PersonalDetails>
                    ))
                }
            </ul>
        </div>


    </div>);
};