import { Route, Routes } from "react-router-dom";

import { BannerTopContextProvider } from "../context/Global/BannerTop/BannerTop.context";
import { BonoFideicomiso } from "../hoc/fideicomiso/bono/bono";
import { BonusPageContextProvider } from "../context/Fideicomiso/Bono/bono.context";
import { Contacto } from "../hoc/contacto/Contacto";
import { Educativa } from "../hoc/educativa/Educativa";
import { FaqPageContextProvider } from "../context/PreguntasFrecuentes/PreguntasFrecuentes.context";
import { Fideicomiso } from "../hoc/fideicomiso/fideicomiso";
import { FideicomisoContextProvider } from "../context/Fideicomiso/fideicomiso.context";
import { Home } from "../hoc/home/Home";
import { HomePageContextProvider } from "../context/Home.context";
import { Nosotros } from "../hoc/nosotros/Nosotros";
import { Noticias } from "../hoc/noticias/Noticias";
import { PageHeaders } from "../components/pageHeaders/pageHeaders";
import { PoliticasDePrivacidad } from "../hoc/politicasDePrivacidad/politicasDePrivacidad";
import { PoliticasDePrivacidadContextProvider } from "../context/PoliticasDePrivacidad/PoliticasDePrivacidad..context";
import { PreguntasFrecuentes } from "../hoc/preguntasFrecuentes/PreguntasFrecuentes";
import { Proyectos } from "../hoc/proyectos/Proyectos";
import React from "react";
import { SiteMap } from "../hoc/sitemap/sitemap";
import { SiteMapContextProvider } from "../context/SiteMap/SiteMap.context";
import { TerminosDeUso } from "../hoc/terminosDeUsos/terminosDeUsos";
import { TerminosDeUsoContextProvider } from "../context/TerminosDeUso/TerminosDeUso.context";
import Transparencia from "../hoc/transparencia/Transparencia";
import TransparenciaData from "../context/Transparencia/Transparencia.context";

// import CompraYContrataciones from "../hoc/transparencia/compraycontrataciones/CompraYContrataciones";
// import ComprasYContratacionesData from "../context/Transparencia/ComprasYContrataciones.context";
// import Fiduciaria from "../hoc/transparencia/fiduciaria/Fiduciaria";
// import FiduciariaData from "../context/Transparencia/Fiduciaria.context";
// import HechosRelevantes from "../hoc/transparencia/hechosrelevantes/HechosReleVantes";
// import HechosRelevantesData from "../context/Transparencia/HechosRelevantes.context";
// import { Portada } from "../hoc/transparencia/portada/Portada";

export const Router = () => {
  return (
    <BannerTopContextProvider>
      <PageHeaders />

      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<>...</>}>
              <HomePageContextProvider>
                <Home />
              </HomePageContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route path="/Educativa/*" element={Educativa()}></Route>
        <Route
          path="/Fideicomisos"
          element={
            <React.Suspense fallback={<>...</>}>
              <FideicomisoContextProvider>
                <Fideicomiso />
              </FideicomisoContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/Bonos"
          element={
            <React.Suspense fallback={<>...</>}>
              <BonusPageContextProvider>
                <BonoFideicomiso />
              </BonusPageContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route path="/Proyectos/*" element={Proyectos()}></Route>
        <Route
          path="/Transparencia"
          element={
            <React.Suspense fallback={<>...</>}>
              <TransparenciaData>
                <Transparencia />
              </TransparenciaData>
            </React.Suspense>
          }
        ></Route>
        {/* <Route
          path="/Transparencia/Portada"
          element={
            <React.Suspense fallback={<>...</>}>
              <TransparenciaData>
                <Portada />
              </TransparenciaData>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/Transparencia/ComprasYContrataciones"
          element={
            <React.Suspense fallback={<>...</>}>
              <TransparenciaData>
                <CompraYContrataciones />
              </TransparenciaData>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/Transparencia/Fiduciaria"
          element={
            <React.Suspense fallback={<>...</>}>
              <TransparenciaData>
                <Fiduciaria />
              </TransparenciaData>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/Transparencia/HechosRelevantes"
          element={
            <React.Suspense fallback={<>...</>}>
              <TransparenciaData>
                <HechosRelevantes />
              </TransparenciaData>
            </React.Suspense>
          }
        ></Route> */}

        <Route path="/Nosotros/*" element={Nosotros()}></Route>

        <Route
          path="/PreguntasFrecuentes"
          element={
            <React.Suspense fallback={<>...</>}>
              <FaqPageContextProvider>
                <PreguntasFrecuentes />
              </FaqPageContextProvider>
            </React.Suspense>
          }
        ></Route>

        <Route path="/Contacto" element={Contacto()}></Route>

        <Route path="/Noticias/*" element={Noticias()}></Route>

        <Route
          path="/TerminosDeUso"
          element={
            <React.Suspense fallback={<>...</>}>
              <TerminosDeUsoContextProvider>
                <TerminosDeUso />
              </TerminosDeUsoContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/PoliticasDePrivacidad"
          element={
            <React.Suspense fallback={<>...</>}>
              <PoliticasDePrivacidadContextProvider>
                <PoliticasDePrivacidad />
              </PoliticasDePrivacidadContextProvider>
            </React.Suspense>
          }
        ></Route>
        <Route
          path="/MapaDelSitio"
          element={
            <React.Suspense fallback={<>...</>}>
              <SiteMapContextProvider>
                <SiteMap />
              </SiteMapContextProvider>
            </React.Suspense>
          }
        ></Route>
      </Routes>
    </BannerTopContextProvider>
  );
};
