import { Header } from "../../components/header/header";
import { BannerTop, BannerTopProps } from "../../components/bannerTop/bannerTop";
import { BannerTopBar, BannerTopSlide } from "../../components/bannerTopBar/bannerTopBar";
import { Routes, Route, Navigate } from 'react-router-dom';
import { EducativaFideicomiso } from "./Fideicomiso";
import { Footer } from "../../components/footer/footer";
import { EducativaMarcoLegal } from "./MarcoLegal";
import React from "react";
import { EducationFideicomisoContextProvider } from "../../context/Education/Fideicomiso.context";
import { EducationLegalContextProvider } from "../../context/Education/legal.context";
import { EducativaGlosario } from "./Glosario/Glosario";
import { EducationGlosarioContextProvider } from "../../context/Education/glosario.context";
import { EducationMultimediaContextProvider } from "../../context/Education/multimedia.context";
import { EducativaMultimedia } from "./Multimedia/Multimedia";

export const Educativa = () => {

    const subBarSlides: BannerTopSlide[] = [
        {
            icon: "/img/educativa/icons/que-son-icon.svg",
            title: "¿Qué es un Fideicomiso?",
            href: "/Educativa/Fideicomiso"
        },
        {
            icon: "/img/educativa/icons/multimedia-icon.svg",
            title: "Multimedia",
            href: "/Educativa/Multimedia"
        },
        {
            icon: "/img/educativa/icons/legal-icon.svg",
            title: "Marco Legal",
            href: "/Educativa/Legal"
        },
        {
            icon: "/img/educativa/icons/glosario-icon.svg",
            title: "Glosario",
            href: "/Educativa/Glosario"
        }
    ];

    return (
        <div>
            <Header></Header>
            <BannerTop title="Conoce Más"></BannerTop>
            <BannerTopBar slides={subBarSlides}></BannerTopBar>
            <Routes>
                <Route path="" element={<Navigate replace to="Fideicomiso" />} />
                <Route path="Fideicomiso" element={
                    <React.Suspense fallback={<>...</>}>
                        <EducationFideicomisoContextProvider>
                            <EducativaFideicomiso />
                        </EducationFideicomisoContextProvider>
                    </React.Suspense>
                }>
                </Route>
                <Route path="Multimedia" element={
                    <React.Suspense fallback={<>...</>}>
                        <EducationMultimediaContextProvider>
                            <EducativaMultimedia />
                        </EducationMultimediaContextProvider>
                    </React.Suspense>
                }>
                </Route>
                <Route path="Legal" element={
                    <React.Suspense fallback={<>...</>}>
                        <EducationLegalContextProvider>
                            <EducativaMarcoLegal />
                        </EducationLegalContextProvider>
                    </React.Suspense>
                }>
                </Route>
                <Route path="Glosario" element={
                    <React.Suspense fallback={<>...</>}>
                        <EducationGlosarioContextProvider>
                            <EducativaGlosario />
                        </EducationGlosarioContextProvider>
                    </React.Suspense>
                }>
                </Route>
            </Routes>
            <Footer></Footer>
        </div >
    );
};