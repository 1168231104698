import { useEffect, useMemo, useState } from "react";
import { NewsCard } from "../../components/cards/newsCard/NewsCard";
import { useNewsList } from "../../context/Noticias/NewsList.context";
import _, { orderBy } from "lodash";

export const NoticiasList = () => {
  const context = useNewsList();

  const { NewsList } = context;

  const sorters: {
    [index: string]: { by: string; direction: "desc" | "asc" };
  } = useMemo(
    () => ({
      "0": { by: "date", direction: "desc" },
      "1": { by: "date", direction: "asc" },
      "2": { by: "title", direction: "asc" },
      "3": { by: "title", direction: "desc" },
    }),
    []
  );

  const pageSkip = 5;
  const [filters, setFilters] = useState<{ years: number[] }>({ years: [] });
  const [totalPages, setTotalPages] = useState(
    Math.ceil((NewsList.length || 1) / pageSkip)
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [filteredNews, setFilteredNews] = useState([...NewsList]);
  const [newssPage, setNewsPage] = useState([...NewsList.slice(0, pageSkip)]);
  const [selectedYearFilter, setSelectedYearFilter] = useState("0");
  const [selectedOrder, setSelectedOrder] = useState("0");

  useEffect(() => {
    if (currentPage === 1) {
      return setNewsPage([...filteredNews.slice(0, pageSkip)]);
    } else {
      return setNewsPage([
        ...filteredNews.slice(
          (currentPage - 1) * pageSkip,
          (currentPage - 1) * pageSkip + pageSkip
        ),
      ]);
    }
  }, [currentPage, filteredNews]);

  useEffect(() => {
    setFilters({
      years: Array.from(
        new Set(NewsList.map((news) => news.date.getFullYear()))
      ).sort(),
    });

    if (selectedYearFilter === "0" && selectedOrder === "0") {
      setTotalPages(Math.ceil((NewsList.length || 1) / pageSkip));
      setFilteredNews([...NewsList]);
    }

    if (!(selectedYearFilter === "0") && selectedOrder === "0") {
      const _filteredNews = [
        ..._.filter(
          NewsList,
          (news) => news.date.getFullYear().toString() === selectedYearFilter
        ),
      ];
      setTotalPages(Math.ceil((_filteredNews.length || 1) / pageSkip));
      setFilteredNews(_filteredNews);
    }

    if (selectedYearFilter === "0" && !(selectedOrder === "0")) {
      const by = sorters[selectedOrder].by;
      const direction = sorters[selectedOrder].direction;
      const _filteredNews = [...orderBy(NewsList, by, direction)];
      setTotalPages(Math.ceil((_filteredNews.length || 1) / pageSkip));
      setFilteredNews(_filteredNews);
    }

    if (!(selectedYearFilter === "0") && !(selectedOrder === "0")) {
      const by = sorters[selectedOrder].by;
      const direction = sorters[selectedOrder].direction;
      const _filteredNews = [
        ...orderBy(
          _.filter(
            NewsList,
            (news) => news.date.getFullYear().toString() === selectedYearFilter
          ),
          by,
          direction
        ),
      ];
      setTotalPages(Math.ceil((_filteredNews.length || 1) / pageSkip));
      setFilteredNews(_filteredNews);
    }
  }, [selectedYearFilter, selectedOrder, NewsList, sorters]);

  return (
    <>
      <section>
        <div className="project project--noticias">
          <div className="project-head">
            <div className="mx-auto">
              <div className="container mx-auto justify-between project__filter project__filter--prensa xl:flex">
                <h3 className="project-head__head-title">Noticias</h3>
                <div>
                  <div className="project__filter">
                    <span className="project__filter-text">Filtrar:</span>
                    <select
                      onChange={(e) => setSelectedYearFilter(e.target.value)}
                      value={selectedYearFilter}
                      className="project__select"
                      name="year"
                      id="year"
                    >
                      <option value="0">Año</option>
                      {filters?.years.map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => setSelectedOrder(e.target.value)}
                      value={selectedOrder}
                      className="project__select"
                      name="orderFilter"
                      id="orderFilter"
                    >
                      <option value="0">Ver más reciente</option>
                      <option value="1">Ver más Antiguo</option>
                      <option value="2">De la A a la Z</option>
                      <option value="3">De la Z a la A</option>
                      {/* <option value="4">Todos</option> */}
                    </select>
                  </div>
                </div>
              </div>
              {newssPage.map((news, index) => (
                <div
                  key={index}
                  className={
                    index % 2 === 0 ? "noticias" : "noticias noticias--blue"
                  }
                >
                  <NewsCard news={news}></NewsCard>
                </div>
              ))}
              <div className="project-pagination project-pagination--noticias mt-12">
                <button
                  type="button"
                  onClick={() => setCurrentPage(currentPage - 1)}
                  style={{ display: currentPage <= 1 ? "none" : "grid" }}
                  className="project-pagination__number project-pagination__number--action"
                >
                  Anterior
                </button>
                {[...Array(totalPages)].map((_, index) => (
                  <button
                    type="button"
                    key={index}
                    onClick={() => setCurrentPage(index + 1)}
                    className={`project-pagination__number ${
                      currentPage === index + 1
                        ? "project-pagination__number--active"
                        : ""
                    }`}
                  >
                    {index + 1}
                  </button>
                ))}
                <button
                  type="button"
                  onClick={() => setCurrentPage(currentPage + 1)}
                  style={{
                    display: currentPage >= totalPages ? "none" : "grid",
                  }}
                  className="project-pagination__number project-pagination__number--action"
                >
                  Siguiente
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
