import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BannerTop } from "../../../components/bannerTop/bannerTop";
import { RuleFileList } from "../../../components/ruleFileList/ruleFileList";
import { GlobalSideMenu, ListItemProps } from "../../../components/sideMenu/globalSideMenu";
import { useGobiernoCorporativo } from "../../../context/Nosotros/GobiernoCorporativo.context";

import './GobiernoCorporativo.css';

export interface GobiernoCorporativoProps {
    menu: ListItemProps[];
}
export const GobiernoCorporativo = (props: GobiernoCorporativoProps) => {
    const context = useGobiernoCorporativo();
    const location = useLocation();
    const { menu } = props;
    const [menuState, setMenu] = useState(menu);
    useEffect(() => {
        const _menu = menu.map((item) => {
            if (item.route === location.pathname) {
                //TODO: implement active route logic
            }
            return item;
        });

        // setMenu(_menu);
    }, [location.pathname, menu]);


    return (
        <article id="gobierno-corporativo">
            <BannerTop title={context?.Article?.Titulo || ""}></BannerTop>
            <div className="article section">
                <div className="container mx-auto xl:flex">
                    <div className="col xl:w-1/3 xl:px-4 xl:order-last">
                        <GlobalSideMenu title="Secciones" listItems={menuState}></GlobalSideMenu>
                    </div>
                    <div className="col xl:w-2/3 xl:pr-14">
                        <div className="intro article-text">
                            <h3 className="article-text__head-title">
                                {context?.Article?.Subtitulo1}
                            </h3>
                            <div className="content" dangerouslySetInnerHTML={{ __html: context?.Article?.Contenido || "" }}></div>
                            <h3 className="article-text__head-title mt-12">
                                {context?.Article?.Subtitulo2}
                            </h3>
                        </div>
                        <RuleFileList rules={context?.Rules}></RuleFileList>
                    </div>
                </div>
            </div>
        </article>
    );
};