import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { HomeBanner, HomeCifras, HomeFideicomiso, HomePage, Video, WelcomeSlide } from './models/Home.models';

type HomePageProviderProps = { children: React.ReactNode };
const HomePageContext = React.createContext<HomePage | undefined>(undefined);

type Message =
  | { type: 'banner'; value: HomeBanner[] }
  | { type: 'welcomeSlider'; value: WelcomeSlide[] }
  | { type: 'homeCifras'; value: HomeCifras }
  | { type: 'fideicomisos'; value: HomeFideicomiso[] }
  | { type: 'video'; value: Video };

const reducer = (state: HomePage, message: Message) => {
  switch (message.type) {
    case 'banner':
      return {
        ...state,
        BannerPrincipal: message.value
      };
    case 'welcomeSlider':
      return {
        ...state,
        WelcomeSlides: message.value
      };
    case 'homeCifras':
      return {
        ...state,
        HomeCifras: message.value
      };
    case 'fideicomisos':
      return {
        ...state,
        Fideicomisos: message.value
      };
    case 'video':
      return {
        ...state,
        Video: message.value
      };
    default:
      return { ...state };
  }
};
const HomePageContextProvider = ({ children }: HomePageProviderProps) => {
  const [home, setHome] = useReducer(reducer, {} as HomePage);

  useEffect(() => {
    fetchBanners();
    fetchWelcomeSlider();
    fetchCifras();
    fetchFideicomisos();
    fetchVideo();
  }, []);

  async function fetchBanners() {
    try {
      const banner = await axios
        .get(
          "/_api/web/lists/GetByTitle('Banner Principal')/items?$select=*,File/ServerRelativeUrl&$expand=File&$orderby=Orden&$filter=Activo eq 1",
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          let result: HomeBanner[] = [];

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((slide: any) => {
              result.push({
                Titulo: slide.Title ? slide.Title : slide.File.Name,
                Contenido: slide.Contenido,
                Imagen: slide.File.ServerRelativeUrl,
                Url: slide.Enlace,
                TextoEnlace: slide.TextoEnlace,
                TituloColor: slide.TituloColor,
                ColorContenido: slide.ColorContenido,
                TextoOrientacion: slide.TextoOrientacion
              });
            });

          return result;
        });

      setHome({ type: 'banner', value: banner });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchWelcomeSlider() {
    try {
      const slides = await axios
        .get(
          "/_api/web/lists/GetByTitle('Slider Bienvenida')/items?$select=Orden,Activo,Title,Contenido,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1",
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          let result: WelcomeSlide[] = [];

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((slide: any) => {
              result.push({
                Titulo: slide.Title ? slide.Title : slide.File.Name,
                Contenido: slide.Contenido,
                Imagen: slide.Attachments ? slide.AttachmentFiles.results[0].ServerRelativeUrl : ''
              });
            });

          return result;
        });

      setHome({ type: 'welcomeSlider', value: slides });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCifras() {
    try {
      const cifras = await axios
        .get("/_api/web/lists/GetByTitle('Cifras')/items?$select=Title,Valor", {
          headers: {
            Accept: 'application/json;odata=verbose',
            'Content-Type': 'application/json;odata=verbose'
          }
        })
        .then((response) => {
          let result: HomeCifras = {
            Fideicomiso: 0,
            Patrimonio: 0,
            Ranking: '0'
          };

          if (response.status === 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((slide: any) => {
              switch (slide.Title) {
                case 'Fideicomisos Constituidos':
                  result.Fideicomiso = slide.Valor;
                  break;
                case 'Activos (RD$ MM)':
                  result.Patrimonio = slide.Valor;
                  break;
                case 'Activos Administrados (RD$ MM)':
                  result.Ranking = slide.Valor;
                  break;
              }
            });

          return result;
        });

      setHome({ type: 'homeCifras', value: cifras });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchFideicomisos() {
    try {
      const fideicomisos = await axios
        .get(
          `/Fideicomisos/_api/web/lists/GetByTitle('Fideicomisos')/items?$select=ID,Activo,Orden,Title,Resumen,Descripcion,Nota,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1&$orderby=Orden`,
          {
            headers: {
              Accept: 'application/json;odata=verbose',
              'Content-Type': 'application/json;odata=verbose'
            }
          }
        )
        .then((response) => {
          var result = Array<HomeFideicomiso>();

          if (response.status == 200 && response.data && response.data.d && response.data.d.results)
            response.data.d.results.forEach((fideicomiso: any) => {
              result.push({
                Id: fideicomiso.ID,
                Titulo: fideicomiso.Title,
                Resumen: fideicomiso.Resumen,
                Imagen: fideicomiso.Attachments ? fideicomiso.AttachmentFiles.results[0].ServerRelativeUrl : ''
              });
            });

          return result;
        });

      setHome({ type: 'fideicomisos', value: fideicomisos });
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchVideo() {
    try {
      const video = await axios
        .get("/_api/web/lists/GetByTitle('Video')/items?$select=Title,Link&$filter=Activo eq 1", {
          headers: {
            Accept: 'application/json;odata=verbose',
            'Content-Type': 'application/json;odata=verbose'
          }
        })
        .then((response) => {
          let result: Video = {
            Link: ''
          };
          if (response.status === 200 && response.data && response.data.d && response.data.d.results) {
            result = { Link: response.data.d.results[0].Link.Url };
          }
          return result;
        });
      setHome({ type: 'video', value: video });
    } catch (error) {
      console.log(error);
    }
  }

  return <HomePageContext.Provider value={home}>{children}</HomePageContext.Provider>;
};

const useHomePage = () => {
  const context = useContext(HomePageContext);
  if (context === undefined) {
    throw new Error('Context must be used within a Provider');
  }
  return context;
};

export { useHomePage, HomePageContextProvider };
