import { Header } from "../../components/header/header";
import { Footer } from "../../components/footer/footer";
import { useFideicomiso } from "../../context/Fideicomiso/fideicomiso.context";
import { InfoText } from "../../components/infoText/infoText";
import { ToggleList } from "../../components/lists/toggleList/toggleList";
import { BannerTop } from "../../components/bannerTop/bannerTop";
import { TabbedLists } from "../../containers/tabbedLists/tabbedLists";
import "./fideicomiso.css";
import { HorizontalProjectCard } from "../../components/cards/projectHorizontalCard/projectHorizontalCard";
import { HorizontalMediaContainer } from "../../containers/horizontalMediaContainer/horizontalMediaContainer";
import { SideMenu } from "../../components/sideMenu/sideMenu";
import { Link } from "react-router-dom";
import { StaticImage } from "../../components/base/staticImage/staticImage";
import { FideicomisoHome } from "./fideicomisoHome";
import { useEffect, useState } from "react";

export const Fideicomiso = () => {
  const context = useFideicomiso();
  const [banner, setBanner] = useState("");

  useEffect(() => {
    if (
      context?.FideicomisoHeader !== undefined &&
      context?.Fideicomiso !== undefined
    ) {
      const header = context?.FideicomisoHeader.find(
        (element) => element.Parent === context?.Fideicomiso.Titulo
      );
      setBanner(header?.Banner || "");
    } else {
      setBanner("");
    }
  }, [context?.Fideicomiso, context?.FideicomisoHeader]);

  return (
    <div>
      <Header></Header>

      <BannerTop
        title={
          context?.InHome
            ? context?.Home?.Title
            : context?.Fideicomiso?.Titulo || ""
        }
        pageTitle={
          context?.InHome
            ? context?.Home?.Title
            : context?.Fideicomiso?.Titulo || ""
        }
        {...(banner !== "" ? { backgroundImage: banner } : {})}
      />

      <div id="fideicomiso" className="article section">
        <div className="container mx-auto xl:flex">
          <div className="col xl:w-1/3 xl:px-4 xl:order-last">
            <SideMenu
              title="Productos"
              listItems={context?.OtrosFideicomisos?.map((fideicomiso) => {
                return {
                  id: fideicomiso.Id,
                  title: fideicomiso.Titulo,
                  parentId: fideicomiso.PadreId,
                };
              })}
            ></SideMenu>
          </div>
          <div className="col xl:w-2/3 xl:pr-14">
            {context?.InHome && (
              <div className="article-text">
                <FideicomisoHome
                  Video={{
                    Thumbnail: context?.Home?.Thumbnail,
                    Type: "video",
                    Url: context?.Home?.Url,
                  }}
                  Descripcion={context?.Home?.Descripcion}
                ></FideicomisoHome>
              </div>
            )}

            {!context?.InHome && (
              <div className="article-text">
                {context?.Fideicomiso && context?.Fideicomiso?.Resumen && (
                  <section>
                    <h3 className="article-text__head-title">
                      ¿Cuál es su finalidad?
                    </h3>
                    <InfoText
                      title="¿Cuál es su finalidad?"
                      text={context?.Fideicomiso?.Resumen}
                      image={context?.Fideicomiso?.Imagen}
                    ></InfoText>
                  </section>
                )}

                {context?.Fideicomiso && context?.Fideicomiso.Descripcion && (
                  <section className="mb-12 description">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: context?.Fideicomiso?.Descripcion || "",
                      }}
                    ></div>
                  </section>
                )}

                {context?.ListasFideicomiso &&
                  context?.ListasFideicomiso.length > 0 && (
                    <section>
                      <TabbedLists
                        htmlContent={true}
                        lists={context.ListasFideicomiso.map((lista) => {
                          return {
                            Titulo: lista.Titulo,
                            Contenido: lista.Contenido,
                            Tab: lista.Lista,
                            Tipo: lista.Tipo,
                          };
                        })}
                      ></TabbedLists>
                    </section>
                  )}

                {context?.Fideicomiso && context?.Fideicomiso.Nota && (
                  <section className="nota article-text">
                    <p>
                      <strong>Nota:</strong> {context?.Fideicomiso.Nota}
                    </p>
                  </section>
                )}

                {context?.BeneficiosFideicomiso &&
                  context?.BeneficiosFideicomiso.length > 0 && (
                    <section>
                      <h3 className="article-text__title">
                        Conoce las ventajas y beneficios de este producto
                      </h3>

                      <HorizontalMediaContainer
                        media={context?.BeneficiosFideicomiso.map(
                          (beneficio) => {
                            return {
                              Title: beneficio.Titulo,
                              Descripcion: beneficio.Descripcion,
                              FechaPublicacion: new Date(),
                              Thumbnail: beneficio.Thumbnail,
                              Type: beneficio.TipoMedia,
                              Url: beneficio.Enlace,
                            };
                          }
                        )}
                      ></HorizontalMediaContainer>
                    </section>
                  )}

                {context?.FAQFideicomiso &&
                  context?.FAQFideicomiso.length > 0 && (
                    <section>
                      <h3 className="article-text__title">
                        Preguntas Frecuentes
                      </h3>

                      <ToggleList
                        listItems={context?.FAQFideicomiso?.map((pregunta) => {
                          return {
                            title: pregunta.Titulo,
                            body: pregunta.Contenido,
                          };
                        })}
                        htmlContent={true}
                      ></ToggleList>
                    </section>
                  )}

                {context?.Bonos && context?.Bonos.length > 0 && (
                  <section className="bonos">
                    <h3 className="article-text__title">
                      Bonos para Adquisición de Viviendas
                    </h3>

                    <ul>
                      {context.Bonos.map((bono) => {
                        return (
                          <li key={bono.Id}>
                            <Link to={"/Bonos?bonoId=" + bono.Id}>
                              <img src={bono.Icono} alt={bono.Title} />
                              {bono.Title}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </section>
                )}

                {context?.ProyectosFideicomiso &&
                  context?.ProyectosFideicomiso.length > 0 && (
                    <section>
                      <h3 className="article-text__title">
                        Proyectos de bajo costo
                      </h3>
                      <div className="article-text__project">
                        {context?.ProyectosFideicomiso?.map((proyecto) => {
                          return (
                            <HorizontalProjectCard
                              id={proyecto.Id}
                              title={proyecto.Titulo}
                              thumbnail={proyecto.Thumbnail}
                              descripcion={proyecto.Descripcion}
                              ubicacion={proyecto.Ubicacion}
                            ></HorizontalProjectCard>
                          );
                        })}
                      </div>

                      <div className="mt-6 link-container">
                        <Link className="cta-button" to="/Proyectos">
                          Ver todos los proyectos
                        </Link>
                      </div>
                    </section>
                  )}
              </div>
            )}
          </div>
        </div>
        <div className="container mx-auto xl:flex">
          <div className="col xl:w-2/3 xl:pr-14">
            <div className="banner-container">
              <div className="banner">
                <a href="/SitePages/formulario-web.aspx" target="_blank">
                  <StaticImage
                    src="//img/banner-fideicomisos.png"
                    alt="Crear Fideicomiso"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};
