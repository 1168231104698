import { MediaContainer } from '../../../containers/mediaContainer/mediaContainer';
import { useEducationMultimedia } from '../../../context/Education/multimedia.context';
import './Multimedia.css';

export const EducativaMultimedia = () => {
    const context = useEducationMultimedia();

    return (
        <div className="educativa-multimedia section">
            <div className="article">
                <div className="container mx-auto">
                    <div className="article-text">
                        <MediaContainer media={context?.Multimedia || []} tiposMedia={context?.TiposMedia || []}></MediaContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};