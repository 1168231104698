import { Link } from "react-router-dom";
import { StaticImage } from "../base/staticImage/staticImage";

export function Footer() {
  return (
    <footer className="footer" data-aos="fade-up">
      <div className="footer__top section">
        <div className="container gap-6 mx-auto md:grid-cols-2 xl:flex md:grid xl:justify-between footer__container-force">
          <div className="footer__logo-box">
            <StaticImage
              src="//img/footer/logo-footer.svg"
              className="mx-auto md:m-0 footer__logo"
              alt="Logo Fiduciaria Reservas"
            ></StaticImage>
          </div>
          <div className="footer__adress">
            <p className="footer__text">
              <span className="feather icon-map-pin footer__icon"></span>
              Ave. Roberto Pastoriza #358, Torre Roberto Pastoriza, Piso 7,
              Ensanche Piantini, Santo Domingo, D. N., República Dominicana.
            </p>
            <p className="footer__text footer__text--phone">
              <span className="feather icon-phone footer__icon"></span>
              <a className="footer__link" href="tel:+1809-960-4580">
                (809) 960-4580
              </a>
            </p>
            <p className="footer__text footer__text--phone">
              <span className="feather icon-mail footer__icon"></span>
              <a
                className="footer__link"
                href="mailto:servicioalclienteFI@fiduciariareservas.com"
              >
                servicioalclienteFI@fiduciariareservas.com
              </a>
            </p>
          </div>
          <div className="footer__link-box">
            <ul className="footer__link-list">
              <li>
                <Link to="/Nosotros" className="footer__link">
                  Nosotros
                </Link>
              </li>
              <li>
                <Link to="/Fideicomisos" className="footer__link">
                  Fideicomisos
                </Link>
              </li>
              <li>
                <Link to="/Contacto" className="footer__link">
                  Contáctanos
                </Link>
              </li>
              <li>
                <Link to="/Transparencia" className="footer__link">
                  Transparencia
                </Link>
              </li>
              <li>
                <a className="footer__link" href="##">
                  TuFideocomiso
                </a>
              </li>
            </ul>
          </div>
          <div className="footer__link-box md:order-4 xl:order-none">
            <ul className="footer__link-list">
              <li>
                <Link to="/Educativa" className="footer__link">
                  Conoce Más
                </Link>
              </li>
              <li>
                <Link to="/PreguntasFrecuentes" className="footer__link">
                  Preguntas Frecuentes
                </Link>
              </li>
              <li>
                <Link to="/Noticias" className="footer__link">
                  Sala de Prensa
                </Link>
              </li>
              <li>
                <Link to="/MapaDelSitio" className="footer__link">
                  Mapa del Sitio
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <div className="footer__social-box">
              <a
                className="footer__social-media"
                target="_blank"
                href="https://twitter.com/FiduReservas"
              >
                <StaticImage
                  className="inline-block"
                  src="//img/footer/tw-icon.svg"
                  alt="Logo Twitter"
                ></StaticImage>
              </a>
              <a
                className="footer__social-media"
                target="_blank"
                href="https://instagram.com/fidureservas"
              >
                <StaticImage
                  className="inline-block"
                  src="//img/footer/pt-icon.svg"
                  alt="Logo Instagram"
                ></StaticImage>
              </a>
              <a
                className="footer__social-media"
                target="_blank"
                href="https://www.facebook.com/fidureservas/"
              >
                <StaticImage
                  className="inline-block"
                  src="//img/footer/fb-icon.svg"
                  alt="Logo Facebook"
                ></StaticImage>
              </a>
              <a
                className="footer__social-media"
                target="_blank"
                href="https://www.linkedin.com/company/fidureservas/"
              >
                <StaticImage
                  className="inline-block"
                  src="//img/footer/in-icon.svg"
                  alt="Logo LinkedIn"
                ></StaticImage>
              </a>
              <a
                className="footer__social-media"
                target="_blank"
                href="https://www.youtube.com/c/FiduciariaReservasoficial"
              >
                <StaticImage
                  className="inline-block"
                  src="//img/footer/yt-icon.svg"
                  alt="Logo Youtube"
                ></StaticImage>
              </a>
            </div>

            <div className="footer__cert-box">
              <span id="CertificacionUAF">
                <a
                  href="https://certificaciones.uaf.gob.do/certificaciones_so_view.php?editid1=217"
                  target="_blank"
                  rel="noreferrer"
                  title="Cerficación Sujeto Obligado - Unidad de Análisis Financiero"
                >
                  <img
                    src="https://certificaciones.uaf.gob.do/certificados/UAF00217O1S5.png"
                    alt="Sello de Certificaci&oacute;n de Sujeto Obligado"
                    width="83"
                    height="100"
                    lang="es"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container mx-auto text-center">
          <div className="footer__copy">
            <p className="footer__disclaimer">
              © 2022 Fiduciaria Reservas, S.A.
              <span>Todos los derechos reservados.</span>
            </p>
          </div>
          <div className="footer__closing-links">
            <Link to="/TerminosDeUso">
              <span>Términos de uso</span>
            </Link>
            <span className="mx-3"> | </span>
            <Link to="/PoliticasDePrivacidad">
              <span>Políticas de privacidad</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
