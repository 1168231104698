import { useEffect, useState } from "react";
import "./transparency-document.css";

export interface DocumentBlockProps {
  currDocSelection: singleDocGroup[];
}
interface singleDocGroup {
  nombre: string;
  referencia: string;
  descripcion: string;
  id: string;
  peso: string;
  modificado: string;
  tipo: string;
  creacion: string;
  clasificacion: string;
  enlace?: string;
  hideRef: boolean;
}
function DocumentBlock({
  currDocSelection,
  handleShowDocuments,
  hideRef = true,
}: any) {
  const [groupedSelection, setGroupedSelection] = useState<{
    [key: string]: singleDocGroup[];
  }>();
  function formatBytes(bytes: string, decimals: number = 2): string {
    const bytesNum: number = Number(bytes);
    if (bytesNum === 0) return "0Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytesNum) / Math.log(k));
    return parseFloat((bytesNum / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  }
  function formatDate(inputDate: string) {
    return new Date(inputDate).toLocaleDateString("es-ES", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  const groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    let grouped = groupBy(currDocSelection, "referencia");
    setGroupedSelection(grouped);
  }, [currDocSelection]);

  return (
    <>
      {groupedSelection && (
        <>
          {Object.keys(groupedSelection).map((key: string, index: number) => (
            <div key={key} className="document">
              {hideRef ? (
                <div className="document__title">
                  <h3 className="lg:mr-1">Referencia: </h3>
                  <span>{groupedSelection[key][0].referencia}</span>
                </div>
              ) : (
                <div className="document__title">
                  <h3> {groupedSelection[key][0].referencia}</h3>
                </div>
              )}

              <div className="document__info">
                <div
                  className="document__desc"
                  dangerouslySetInnerHTML={{
                    __html: groupedSelection[key][0].descripcion,
                  }}
                ></div>
                {hideRef ? (
                  <div id="Specs" className="document__spec">
                    <h4>
                      <b>Tipo: </b>
                      {groupedSelection[key][0].tipo}
                    </h4>
                    <h4>
                      <b>Inicia: </b>
                      {`${formatDate(groupedSelection[key][0].creacion)}`}
                    </h4>
                    <div className="modified">
                      <h4>
                        <b>Modificado: </b>
                        {`${formatDate(groupedSelection[key][0].modificado)}`} -
                        Tamaño:
                        {` ${formatBytes(groupedSelection[key][0].peso, 1)}`}
                      </h4>
                    </div>
                  </div>
                ) : (
                  <div id="Specs" className="document__spec">
                    <h4>
                      <b>Documentos: </b>
                      {groupedSelection[key].length}
                    </h4>
                    <h4>
                      <b>Actulizado: </b>
                      {`${formatDate(groupedSelection[key][0].modificado)}`}
                    </h4>
                  </div>
                )}
                <div className="md:text-right">
                  <div
                    className="flex items-center justify-center cursor-pointer mt-7 md:mt-1 md:ml-6 document__link md:inline-flex"
                    onClick={() => handleShowDocuments(groupedSelection[key])}
                  >
                    <span className="flex-1 md:flex-none">Ver Documentos</span>
                    <span className="ml-6 feather icon-chevron-right"></span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default DocumentBlock;
