import { ArticleWithImage, ArticleWithImageProps } from "../../../components/article/article-with-image";
import { BannerTop } from "../../../components/bannerTop/bannerTop";
import { GlobalSideMenu, ListItemProps } from "../../../components/sideMenu/globalSideMenu";
import { useEquipoEjecutivo } from "../../../context/Nosotros/EquipoEjecutivo.contex";
import { PersonalSpotlight } from "../../../components/personal/personalSpotlight/personalSpotlight";
import { PersonalList } from "../../../components/personal/personalList/personalList";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./EquipoEjecutivo.css";

export interface EquipoEjecutivoProps {
    menu: ListItemProps[];
}
export const EquipoEjecutivo = (props: EquipoEjecutivoProps) => {
    const context = useEquipoEjecutivo();
    const location = useLocation();
    const { menu } = props;
    const [menuState, setMenu] = useState(menu);
    useEffect(() => {
        const _menu = menu.map((item) => {
            if (item.route === location.pathname) {
                //TODO: implement active route logic
            }
            return item;
        });

        // setMenu(_menu);
    }, [location.pathname, menu]);

    return (
        <article id="equipo-ejecutivo">
            <BannerTop title={context?.Titulo || ""}></BannerTop>
            <div className="article section">
                <div className="container mx-auto xl:flex">
                    <div className="col xl:w-1/3 xl:px-4 xl:order-last">
                        <GlobalSideMenu title="Secciones" listItems={menuState}></GlobalSideMenu>
                    </div>
                    <div className="col xl:w-2/3 xl:pr-14">
                        <PersonalSpotlight details={context?.mainExec}></PersonalSpotlight>
                        <PersonalList title="Directores" personalList={context?.personalList} modal={true}></PersonalList>
                        <div className="article-text__help">
                            <span className="article-text__help-icon feather icon-mail"></span>
                            <div className="article-text__help-text">
                                <h4>¿Preguntas o sugerencias? ¡Contáctanos!</h4>
                                <p>
                                    <Link
                                        to="#"
                                        onClick={(e) => {
                                            window.location.href = "mailto:servicioalclienteFI@fiduciariareservas.com";
                                            e.preventDefault();
                                        }}
                                    >
                                        servicioalclienteFI@fiduciariareservas.com
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    );
};