import { useEffect, useState } from "react";
import { FileCard } from "../../components/cards/fileCard/fileCard";
import { CustomCheckbox } from "../../components/checkbox/checkbox";
import { Documento } from "../../context/Education/models/Legal.models";
import "./filesContainer.css";

export interface FilesContainerProps {
  className?: string;
  documentos: Documento[];
  anos: string[];
  tipos: string[];
}

interface documentGroup {
  group: string;
  documents: Documento[];
}

export function FilesContainer(props: FilesContainerProps) {
  const [filterProperties, setFilterProperties] = useState<Array<string>>([]);
  const [propertyFilters, setPropertyFilters] = useState<Array<string>>([]);
  const [fileYear, setYearFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [groupedDocuments, setGroupedDocuments] = useState(
    Array<documentGroup>()
  );

  useEffect(() => {
    if (props.documentos && props.documentos.length > 0) {
      const properties = props.documentos.reduce(
        (result: Array<string>, currentDocument: Documento) => {
          if (!result.includes(currentDocument.Categoria))
            result.push(currentDocument.Categoria);
          return result;
        },
        []
      );
      setFilterProperties(properties);
      setPropertyFilters(properties);
    }
  }, [props.documentos]);

  useEffect(() => {
    return setGroupedDocuments(getGroupedDocuments());
  }, [propertyFilters, fileYear, nameFilter, props.documentos]);

  function getGroupedDocuments() {
    return props.documentos
      .filter((document) => {
        let valid = true;

        if (!propertyFilters.includes(document.Categoria)) valid = false;
        if (fileYear && document.Ano != fileYear) valid = false;
        if (
          nameFilter &&
          document.Titulo &&
          document.Titulo.toLowerCase().indexOf(nameFilter.toLowerCase()) < 0
        )
          valid = false;

        if (valid) return document;
      })
      .reduce((result: Array<documentGroup>, currentDocument: Documento) => {
        if (currentDocument.Categoria) {
          const yearIndex = result.findIndex((group: documentGroup) => {
            return group.group == currentDocument.Categoria;
          });
          if (yearIndex < 0)
            result.push({
              group: currentDocument.Categoria,
              documents: [currentDocument],
            });
          else
            result[yearIndex].documents = [
              ...result[yearIndex].documents,
              currentDocument,
            ];
        }
        return result;
      }, [])
      .sort((firstGroup: documentGroup, secondGroup: documentGroup) => {
        return parseInt(secondGroup.group) - parseInt(firstGroup.group);
      });
  }

  return (
    <div className="documents">
      <form className="documents__filter">
        <div className="documents__filter__fields">
          <div className="property-container">
            {filterProperties.map((property: string) => {
              return (
                <>
                  {/* <div className="filter-property">
                                        <input key={index}
                                            id={"property-" + property}
                                            type="checkbox"
                                            defaultChecked={true}
                                            onChange={(e) => {
                                                setPropertyFilters(
                                                    () => {
                                                        if (propertyFilters.includes(property))
                                                            return propertyFilters.filter((prop) => {
                                                                return prop != property;
                                                            });
                                                        else
                                                            return [...propertyFilters, property];
                                                    }
                                                );
                                            }} />
                                        <label htmlFor={"property-" + property}>{property}</label>
                                    </div> */}
                  <div className="filter-property">
                    <CustomCheckbox
                      id={"property-" + property}
                      defaultChecked={false}
                      onChange={(event) => {
                        setPropertyFilters((propertyFilters) => {
                          if (
                            event.target.checked &&
                            propertyFilters.length === filterProperties.length
                          ) {
                            return [property];
                          }
                          if (propertyFilters.includes(property)) {
                            const filteredProperties = propertyFilters.filter(
                              (prop) => {
                                return prop !== property;
                              }
                            );
                            return filteredProperties.length === 0
                              ? filterProperties
                              : filteredProperties;
                          }
                          return [...propertyFilters, property];
                        });
                      }}
                      label={property}
                    />
                  </div>
                </>
              );
            })}
          </div>

          <div className="field-container">
            <select
              name="doc-tipo"
              id="doc-tipo"
              className="documents__select"
              onChange={(e) => setYearFilter(e.target.value)}
              value={fileYear}
            >
              <option value="">Año</option>
              {props.anos?.map((ano: string, index: number) => {
                return (
                  <option key={index} value={ano}>
                    {ano}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="documents__filter__fields global-container">
          <input
            type="text"
            placeholder="Buscar"
            className="global"
            onChange={(e) => setNameFilter(e.target.value)}
            value={nameFilter}
          />
        </div>
      </form>

      <div className="documents__list">
        {groupedDocuments.map((group: documentGroup, groupIndex: number) => {
          return (
            <div key={groupIndex} className="documents__list__section">
              <h4>{group.group}</h4>

              <ul className="files">
                {group.documents.map(
                  (file: Documento, documentIndex: number) => {
                    return (
                      <FileCard
                        key={documentIndex}
                        title={file.Titulo}
                        type={file.Tipo}
                        fileSize={file.Size}
                        year={file.Ano}
                        fileUrl={file.Url}
                      ></FileCard>
                    );
                  }
                )}
              </ul>
            </div>
          );
        })}
      </div>
    </div>
  );
}
