import { CSSProperties } from "react";
import { DynamicImage } from "../../base/dynamicImage/dynamicImage";
import "./hoverBackgroundCard.css";

export interface HoverBackgroundCardProps {
    className?: string;
    image?: string;
    title?: string;
    Contenido?: string;
    hoverText?: string;
    enlace?: string;
    style?: CSSProperties;
}

export function HoverBackgroundCard(props: HoverBackgroundCardProps) {
    return (
        <div className={"hover-background-card" + (props.className ? ` ${props.className}` : "")}
            style={props.style}>
            <DynamicImage src={props.image} alt={props.title} className="hover-background-card__image"></DynamicImage>

            <div className="hover-background-card__info">
                <h4 className="text-center">{props.title}</h4>

                <div className="hover-background-card__info__expanded">
                    <p>{props.Contenido}</p>

                    <div className="hover-background-card__info__expanded__link-container">
                        <a href={props.enlace}>{props.hoverText} <span className="feather icon-chevron-right"></span></a>
                    </div>
                </div>
            </div>
        </div>
    );
}
