
import './article.css';
// import './article-text.css';
export interface ArticleProps {
    articleDetails?: { title: string; paragraph: string; }[];
    htmlContent?: boolean;
}
export const Article = (props: ArticleProps) => {
    const { articleDetails } = props;
    return (
        <div className="article-text">
            {
                articleDetails?.map((detail, index) => (
                    <div className="article-text-detail" key={index}>
                        <h3 className="article-text__title">
                            {detail.title}
                        </h3>
                        {
                            (!props.htmlContent) &&
                            <p className="article-text__parra">
                                {detail.paragraph}
                            </p>
                        }
                        {
                            (props.htmlContent) &&
                            < div className="article-text__parra" dangerouslySetInnerHTML={{ __html: detail.paragraph }}></div>
                        }
                    </div>))
            }
        </div >);
};