import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { GobiernoCorporativoMockData } from "./mock/GobiernoCorporativo.mock";
import { GobiernoCorporativo, GobiernoCorporativoArticulo, RuleFile, Rules } from "./models/GobiernoCorporativo.model";


type GobiernoCorporativoProviderProps = { children: React.ReactNode; };
const GobiernoCorporativoContext = React.createContext<GobiernoCorporativo | undefined>(undefined);

type Message = { type: 'gobierno-corporativo'; value: GobiernoCorporativoArticulo | undefined; }
    | { type: 'archivos'; value: Rules | undefined; };

const reducer = (state: GobiernoCorporativo, message: Message) => {
    switch (message.type) {
        case "gobierno-corporativo":
            return {
                ...state, Article: message.value
            };
        case "archivos":
            return {
                ...state, Rules: message.value
            };
        default:
            return { ...state };
    }
};
const GobiernoCorporativoContextProvider = ({ children }: GobiernoCorporativoProviderProps) => {

    const [GobiernoCorporativo, setGobiernoCorporativo] = useReducer(reducer, {} as GobiernoCorporativo);



    useEffect(() => {
        fetchGobiernoCorporativo();
        fetchGobiernoCorporativoArchivos();
    }, []);

    async function fetchGobiernoCorporativo() {
        try {
            const articulo = await axios.get("/Nosotros/_api/web/lists/GetByTitle('Nosotros - Gobierno Corporativo Detalle')/items?$select=Activo,Title,SubTitulo1,SubTitulo2,Contenido&$filter=Activo eq 1",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: GobiernoCorporativoArticulo[] = [];

                if (response.status === 200 && response.data && response.data.d && response.data.d.results) {
                    result = response.data.d.results.map((detail: any): GobiernoCorporativoArticulo => (
                        {
                            Titulo: detail.Title,
                            Subtitulo1: detail.SubTitulo1,
                            Subtitulo2: detail.SubTitulo2,
                            Contenido: detail.Contenido
                        }
                    ));
                }
                return result;
            });
            setGobiernoCorporativo({ type: "gobierno-corporativo", value: articulo[0] });
            // const mockData = GobiernoCorporativoMockData;
            // setGobiernoCorporativo({ type: "gobierno-corporativo", value: mockData.Article });

        } catch (error) {
            console.log(error);
        }
    }

    async function fetchGobiernoCorporativoArchivos() {
        try {
            const rules = await axios.get("/Nosotros/_api/web/lists/GetByTitle('Nosotros - Gobierno Corporativo Archivos')/items?$select=Orden,Activo,Title,Modified,File/Length,File/Name,File/ServerRelativeUrl,PortadaId&$expand=File&$orderby=Orden&$filter=Activo eq 1",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: Rules = { ruleFileList: [] };

                if (response.status === 200 && response.data && response.data.d && response.data.d.results)
                    result.ruleFileList = response.data.d.results.map((documento: any): RuleFile => ({
                        name: documento.Title ? documento.Title : documento.File.Name,
                        size: `${documento.File.Length / 1000000}MB`,
                        link: documento.File.ServerRelativeUrl,
                        date: new Date(documento.Modified),
                        tumbnail: documento.Attachments ? documento.AttachmentFiles.results[0].ServerRelativeUrl : "",
                        portadaId: documento.PortadaId
                    }));

                return result;
            });
            // const mockData = GobiernoCorporativoMockData;
            // setGobiernoCorporativo({ type: "archivos", value: mockData.Rules });

            const rulesWithThumbnails = await fetchGobiernoCorporativoArchivosPortadas(rules.ruleFileList);
            setGobiernoCorporativo({ type: 'archivos', value: { ruleFileList: rulesWithThumbnails } });

        } catch (error) {
            console.log(error);
        }
    }

    async function fetchGobiernoCorporativoArchivosPortadas(documentos: RuleFile[]): Promise<RuleFile[]> {
        try {
            const portadas = await axios.get("/Nosotros/_api/web/lists/GetByTitle('Nosotros - Gobierno Corporativo Portadas')/items?$select=Id,File/ServerRelativeUrl&$expand=File",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result = documentos;

                if (response.status === 200 && response.data && response.data.d && response.data.d.results)
                    result = result.map((rule: RuleFile) => {
                        const portada = response.data.d.results.find((portada: any) => { return portada.Id === rule.portadaId; });
                        return {
                            ...rule,
                            tumbnail: (portada && portada.File && portada.File.ServerRelativeUrl ? portada.File.ServerRelativeUrl : "")
                        };
                    });

                return result;
            });
            return portadas;

        } catch (error) {
            console.log(error);
        }

        return [];
    }


    return (
        <GobiernoCorporativoContext.Provider value={GobiernoCorporativo}>
            {children}
        </GobiernoCorporativoContext.Provider>
    );

};
const useGobiernoCorporativo = () => {
    const context = useContext(GobiernoCorporativoContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { useGobiernoCorporativo, GobiernoCorporativoContextProvider };