import { Link } from "react-router-dom";

export interface ProjectInfoProps {
    details?: { title: string; text: string; }[];
    developer?: { name: string; phone: string; email: string; website: string; };
}
export const ProjectInfo = (props: ProjectInfoProps) => {
    const { details, developer } = props;
    return (
        <div className="project__info xl:flex">
            <div className="my-9 xl:my-0 w-100 xl:w-2/3 xl:pr-9">
                <div className="project-left">
                    <h3 className="project__info-title">Detalles</h3>
                    <div className="project__info-details xl:flex xl:flex-wrap">
                        {
                            details?.map((detail, index) => (
                                <div key={index}>
                                    <h3>{detail.title}: </h3>
                                    <p>{detail.text}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="xl:w-1/3 my-9 xl:my-0">
                <div className="project-right">
                    <h3>Desarrollador</h3>
                    <h4>{
                        developer?.name
                    }</h4>
                    <h5><span className="feather icon-phone"></span> {
                        developer?.phone
                    }</h5>
                    <h5><a target="_blank" rel="noreferrer" href={"mailto:" + developer?.email}>
                        <span className="feather icon-mail"></span> {
                            developer?.email
                        }
                    </a></h5>
                    {developer?.website && <h5>
                        <a target="_blank" rel="noreferrer" href={developer?.website}>
                            <span className="feather icon-link"></span>{
                                developer?.website
                            }</a></h5>
                    }

                </div>
            </div>
        </div>);
};