import { DynamicImage } from "../../base/dynamicImage/dynamicImage";
import "./hoverProfileCard.css";

export interface HoverProfileCardProps {
    className?: string;
    image?: string;
    title?: string;
    text?: string
}

export function HoverProfileCard(props: HoverProfileCardProps) {
    return (
        <div className="hover-profile-card">
            <DynamicImage src={props.image} className="hover-profile-card__photo" alt={'Imagen de ' + props.title}></DynamicImage>

            <h3 className="hover-profile-card__name">{props.title}</h3>

            <p className="hover-profile-card__info">{props.text}</p>
        </div>
    );
}
