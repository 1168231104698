import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BannerTop } from "../../components/bannerTop/bannerTop";
import { Footer } from "../../components/footer/footer";
import { Header } from "../../components/header/header";
import { GlobalSideMenu, ListItemProps } from "../../components/sideMenu/globalSideMenu";
import { useFaqPage } from "../../context/PreguntasFrecuentes/PreguntasFrecuentes.context";
import { Question } from "../../context/PreguntasFrecuentes/PreguntasFrecuentes.models";
import "./PreguntasFrecuentes.css";

export const PreguntasFrecuentes = () => {
    const context = useFaqPage();
    const [menuState, setMenu] = useState(Array<ListItemProps>());
    const [filteredQuestions, setFilteredQuestions] = useState(Array<Question>());
    const [selectedCategory, setSelectedCategory] = useState("");

    const location = useLocation();
    const initialCategory = new URLSearchParams(location.search).get("Categoria") || "";

    useEffect(() => {
        if (context?.Questions && context.Questions.length > 0) {
            setMenu(getCategories());
        }
    }, [context.Questions]);

    useEffect(() => {
        if (!selectedCategory) {
            if (initialCategory)
                setActiveCategory(initialCategory);
            else if (menuState && menuState.length)
                setActiveCategory(menuState[0].title || "");
        }
    }, [menuState]);

    useEffect(() => {
        setActiveCategory(new URLSearchParams(location.search).get("Categoria") || "");
    }, [location.search]);

    useEffect(() => {
        setFilteredQuestions(filterQuestions());
    }, [selectedCategory, context.Questions]);

    function filterQuestions() {
        if (selectedCategory)
            return context.Questions.filter(question => question.categoria == selectedCategory);
        else
            return context.Questions;
    }

    function getCategories() {
        let result: Array<ListItemProps> = [];

        if (context.Questions && context.Questions.length > 0)
            context.Questions.forEach((question: Question, index: number) => {
                if (result.findIndex((category) => { return category.title == question.categoria; }) < 0)
                    result.push({
                        id: index + 1,
                        title: question.categoria,
                        isActive: false,
                        route: "/PreguntasFrecuentes?Categoria=" + question.categoria
                    });
            });

        return result;
    }

    function setActiveCategory(category: string) {
        setSelectedCategory(category);

        setMenu(menuState.map(menu => {
            return { ...menu, isActive: menu.title == category };
        }));
    }

    return (
        <>
            <Header></Header>

            <BannerTop title="Preguntas Frecuentes"></BannerTop>

            <div id="faq" className="article section">
                <div className="container mx-auto xl:flex">
                    <div className="col xl:w-1/3 xl:px-4 xl:order-last">
                        <GlobalSideMenu title="Secciones" listItems={menuState}></GlobalSideMenu>
                    </div>
                    <div className="col xl:w-2/3 xl:pr-14">
                        <div className="article-text">
                            <h3 className="article-text__head-title article-text__head-title--center">
                                {selectedCategory}
                            </h3>

                            <ul className="toggle-list toggle-list--fide">
                                {
                                    filteredQuestions.map((question: Question, index: number) => {
                                        return (
                                            <li key={"question-" + question.id} className="toggle-list__item toggle-list__item--faq">
                                                <input type="checkbox" id={"toggle-" + question.id} />
                                                <label htmlFor={"toggle-" + question.id}>
                                                    <span>{question.title}</span>
                                                    <span className="arrow-icon">
                                                        <span className="icon-container">
                                                            <i className="feather icon-chevron-right"></i>
                                                        </span>
                                                    </span>
                                                </label>

                                                <div className="content content--faq">
                                                    <p>{question.answer}</p>
                                                </div>
                                            </li>
                                        );
                                    })
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </>
    );
};