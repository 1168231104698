import { FilesContainer } from '../../containers/filesContainer/filesContainer';
import { useEducationLegal } from '../../context/Education/legal.context';
import './MarcoLegal.css';

export const EducativaMarcoLegal = () => {

    const context = useEducationLegal();

    return (
        <div className="educativa-marco-legal section">
            <div className="article">
                <div className="container mx-auto">
                    <div className="article-text">
                        <h3 className="article-text__head-title">
                            Marco Legal
                        </h3>

                        <FilesContainer documentos={context?.Documentos || []} anos={context?.AnosDocumento || []} tipos={context?.TiposDocumento || []}></FilesContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};