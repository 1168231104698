import { StaticImage } from "../../base/staticImage/staticImage";
import "./fileCard.css";

export interface FileCardProps {
    className?: string;
    type?: string;
    title?: string;
    publishedYear?: string;
    fileSize?: number;
    fileUrl?: string;
    year?: string;
}

export function FileCard(props: FileCardProps) {
    return (
        <li className="file">
            <div className="file__info">
                <div className="file-type-container">
                    <StaticImage src={'/img/file-icons/' + props.type?.toLowerCase() + '.svg'} alt="Ícono del tipo de archivo" className="file-type"></StaticImage>
                </div>

                <div className="file-name-container">
                    <p className="file__info__name">{props.title}</p>

                    <p className="file__info__details">Año: {props.year} - {(props.fileSize || (1 / 10000000)).toFixed(2)}MB</p >
                </div>
            </div>

            <div className="file__details">
                <p>Año: {props.year} - Tamaño: {(props.fileSize || (1 / 10000000)).toFixed(2)}MB</p >
            </div>

            <div className="file__download-link">
                <a href={props.fileUrl} target="_blank" rel="noreferrer"><i className="feather icon-download"></i> Descargar</a>
            </div>
        </li>
    );
}
