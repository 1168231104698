import "./toggleList.css";

export interface ToggleListProps {
    className?: string;
    listItems?: ToggleListItem[];
    htmlContent?: boolean;
}

export interface ToggleListItem {
    className?: string,
    title?: string;
    body?: string;
}

export function ToggleList(props: ToggleListProps) {
    return (
        <ul className={'toggle-list' + (props.className ? " " + props.className : "")}>
            {
                props.listItems?.map((listItem: any, index: number) => (
                    <li key={'toggle-item-' + index} className={'toggle-list__item' + (listItem.className ? " " + listItem.className : "")}>
                        <input type="checkbox" id={'toggle-' + index} />
                        <label htmlFor={'toggle-' + index}>
                            <span>{listItem.title}</span>
                            <span className="arrow-icon">
                                <span className="icon-container">
                                    <i className="feather icon-chevron-right"></i>
                                </span>
                            </span>
                        </label>
                        {
                            !props.htmlContent &&
                            <div className="content">
                                <p>{listItem.body}</p>
                            </div>
                        }

                        {
                            props.htmlContent &&
                            <div className="content" dangerouslySetInnerHTML={{__html: listItem.body}}></div>
                        }
                    </li>
                ))
            }
        </ul>
    );
}
