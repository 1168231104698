import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { Documento, EducationLegal } from "./models/Legal.models";


type EducationLegalProviderProps = { children: React.ReactNode; };
const EducationLegalContext = React.createContext<EducationLegal | undefined>(undefined);

type Message = { type: 'documentos'; value: Documento[]; }
    | { type: 'tiposDocumento'; value: string[]; }
    | { type: 'anosDocumento'; value: string[]; };

const reducer = (state: EducationLegal, message: Message) => {
    switch (message.type) {
        case 'documentos':
            return {
                ...state, Documentos: message.value
            };
        case 'tiposDocumento':
            return {
                ...state, TiposDocumento: message.value
            };
        case 'anosDocumento':
            return {
                ...state, AnosDocumento: message.value
            };
        default:
            return { ...state };
    }
};
const EducationLegalContextProvider = ({ children }: EducationLegalProviderProps) => {

    const [legal, setLegal] = useReducer(reducer, {} as EducationLegal);

    useEffect(() => {
        fetchDocumentos();
        fetchFileTypes();
        fetchFileYears();
    }, []);

    async function fetchDocumentos() {
        try {
            const tipos = await axios.get("/Educativa/_api/web/lists/GetByTitle('Marco Legal - Documentos')/items?$select=Orden,Activo,Title,Modified,TipoDocumento,Categoria,Ano,File/Length,File/Name,File/ServerRelativeUrl&$expand=File&$orderby=Orden&$filter=Activo eq 1",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: Documento[] = [];

                if (response.status == 200 && response.data && response.data.d && response.data.d.results)
                    response.data.d.results.forEach((documento: any) => {
                        result.push({
                            Titulo: documento.Title ? documento.Title : documento.File.Name,
                            Ano: documento.Ano,
                            Categoria: documento.Categoria,
                            Tipo: documento.TipoDocumento,
                            Size: Number(documento.File.Length) / 1000000,
                            Url: documento.File.ServerRelativeUrl,
                            Modified: new Date(documento.Modified)
                        });
                    });

                return result;
            });

            setLegal({ type: 'documentos', value: tipos });

        } catch (error) {
            console.log(error);
        }
    }

    async function fetchFileTypes() {
        try {
            const tipos = await axios.get("/Educativa/_api/web/lists/GetByTitle('Marco Legal - Documentos')/fields?$filter=EntityPropertyName eq 'TipoDocumento'",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                if (response.status == 200 && response.data && response.data.d && response.data.d.results)
                    return response.data.d.results[0].Choices.results;
                else
                    return [];
            });

            setLegal({ type: 'tiposDocumento', value: tipos });

        } catch (error) {
            console.log(error);
        }
    }

    async function fetchFileYears() {
        try {
            const anos = await axios.get("/Educativa/_api/web/lists/GetByTitle('Marco Legal - Documentos')/fields?$filter=EntityPropertyName eq 'Ano'",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                if (response.status === 200 && response.data && response.data.d && response.data.d.results)
                    return response.data.d.results[0].Choices.results;
                else
                    return [];
            });

            setLegal({ type: 'anosDocumento', value: anos.sort((firstYear: string, secondYear: string) => { return parseInt(secondYear) - parseInt(firstYear); }) });

        } catch (error) {
            console.log(error);
        }
    }

    return (
        <EducationLegalContext.Provider value={legal}>
            {children}
        </EducationLegalContext.Provider>
    );

};

const useEducationLegal = () => {
    const context = useContext(EducationLegalContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { useEducationLegal, EducationLegalContextProvider };