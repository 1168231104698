import { useLayoutEffect, useState } from "react";
import "./transparency-document.css";
import { DocumentBlockProps } from "./DocumentBlock";
import SweetPagination from "sweetpagination";

function DocumentListTransparecy({ currDocSelection }: DocumentBlockProps) {
  const [currentPageData, setCurrentPageData] = useState(new Array(2));
  const paginationButtons = document.querySelectorAll("button.pageButton");
  paginationButtons.forEach((x) => x.setAttribute("type", "button"));

  return (
    <>
      {/* <div className="my-5">
        <FilterTransparecy
          hideSelect={false}
          setSearchInput={setSearchInput}
          changeCurrDocType={changeCurrDocType}
          changeCurrDate={changeCurrDate}
        />
      </div> */}
      <div className="my-5 document-list">
        <div className="document-list__title">
          <h3>Documentos</h3>
        </div>
        {currentPageData.map((document, i) => (
          <div
            key={i}
            className="items-center justify-between document-list__item lg:flex xl:items-start"
          >
            <h3>{document.nombre}</h3>
            <div className="xl:ml-2">
              <a className="inline-block mt-3 lg:mt-0" href={document.enlace}>
                <span className="mr-4 feather icon-download"></span> Descargar
              </a>
            </div>
          </div>
        ))}
      </div>
      <div>
        <SweetPagination
          currentPageData={setCurrentPageData}
          getData={currDocSelection}
          navigation={false}
          dataPerPage={16}
          getStyle={"pagination-costum"}
        />
      </div>
    </>
  );
}

export default DocumentListTransparecy;
