import { useEffect, useState } from 'react';
import { useBanner } from '../../context/Global/BannerTop/BannerTop.context';
import { DynamicImage } from '../base/dynamicImage/dynamicImage';
import { PageHeaders } from '../pageHeaders/pageHeaders';
import './bannerTop.css';

export interface BannerTopProps {
    title?: string;
    pageTitle?: string;
    backgroundImage?: string;
}

export function BannerTop(props: BannerTopProps) {
    const context = useBanner();
    const [image, setImage] = useState(""); //"/ReactApp/img/banner/banner-bg.jpg"
    const [title, setTitle] = useState("");
    const [backgroundStyle, setBackgroundStyle] = useState({});

    useEffect(() => {
        if (props.backgroundImage)
            setImage(props.backgroundImage);
        else if (context?.Banner?.Imagen)
            setImage(context?.Banner?.Imagen);
        // else
        //     setImage("/ReactApp/img/banner/banner-bg.jpg");

        if (props.title)
            setTitle(props.title);
        else if (context?.Banner?.Titulo)
            setTitle(context?.Banner?.Titulo);
    }, [props, context.Banner]);

    useEffect(() => {
        const url = new URL(`${window.location.origin}${image}`);
        setBackgroundStyle({ backgroundImage: `url(${url.href})` });
    }, [image]);

    return (
        <>
            <PageHeaders title={props.pageTitle || props.title} />

            <section className="banner-top section" style={{ ...backgroundStyle }}>
                <div className="container mx-auto" >
                    <h3 className="banner-top__title">{title}</h3>
                </div>

                {/* {
                    image &&
                    <DynamicImage className="banner-top__background" src={image} alt="Imagen de Banner"></DynamicImage>
                } */}
            </section>
        </>
    );
}