import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import { EquipoEjecutivoMockData } from "./mock/EquipoEjecutivo.mock";
import { EquipoEjecutivo, PersonalDetail } from "./models/EquipoEjecutivo.model";


type EquipoEjecutivoProviderProps = { children: React.ReactNode; };
const EquipoEjecutivoContext = React.createContext<EquipoEjecutivo | undefined>(undefined);

type Message = { type: 'main-exec'; value: PersonalDetail; } | { type: 'equipo-ejecutivo'; value: PersonalDetail[]; };

const reducer = (state: EquipoEjecutivo, message: Message) => {
    switch (message.type) {
        case 'main-exec':
            return {
                ...state, mainExec: message.value
            };
        case 'equipo-ejecutivo':
            return {
                ...state, personalList: [...message.value]
            };
        default:
            return { ...state };
    }
};
const EquipoEjecutivoContextProvider = ({ children }: EquipoEjecutivoProviderProps) => {

    const [EquipoEjecutivo, setEquipoEjecutivo] = useReducer(reducer, { Titulo: "Equipo Ejecutivo" } as EquipoEjecutivo);

    useEffect(() => {
        fetchEquipoEjecutivo();
        fetchMainExec();
    }, []);

    async function fetchEquipoEjecutivo() {
        try {
            const equipoEjecutivo = await axios.get("/Nosotros/_api/web/lists/GetByTitle('Nosotros - Equipo Ejecutivo')/items?$select=Activo,Title,Posicion,Order0,Historia,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Order0&$filter=Activo eq 1",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: PersonalDetail[] = [];

                if (response.status === 200 && response.data && response.data.d && response.data.d.results) {
                    result = response.data.d.results.map((detail: any): PersonalDetail => (
                        {
                            name: detail.Title,
                            image: detail.Attachments ? detail.AttachmentFiles.results.filter((file: any) => file.FileName.indexOf("cara") > -1)[0].ServerRelativeUrl : "",
                            imageBig: detail.Attachments ? detail.AttachmentFiles.results.filter((file: any) => file.FileName.indexOf("full") > -1)[0].ServerRelativeUrl : "",
                            history: detail.Historia,
                            position: detail.Posicion
                        }
                    ));
                }
                return result;
            });
            setEquipoEjecutivo({ type: 'equipo-ejecutivo', value: equipoEjecutivo });
            // const mockData = EquipoEjecutivoMockData;
            // setEquipoEjecutivo({ type: 'equipo-ejecutivo', value: mockData.personalList });

        } catch (error) {
            console.log(error);
        }
    }

    async function fetchMainExec() {
        try {
            const equipoEjecutivo = await axios.get("/Nosotros/_api/web/lists/GetByTitle('Nosotros - Gerente General')/items?$select=Activo,Title,Posicion,Historia,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Posicion eq 'Gerente General'",
                {
                    headers: {
                        "Accept": "application/json;odata=verbose",
                        "Content-Type": "application/json;odata=verbose",
                    }
                }
            ).then(response => {
                let result: PersonalDetail[] = [];

                if (response.status === 200 && response.data && response.data.d && response.data.d.results) {
                    result = response.data.d.results.map((detail: any): PersonalDetail => (
                        {
                            name: detail.Title,
                            image: detail.Attachments ? detail.AttachmentFiles.results[0].ServerRelativeUrl : "",
                            imageBig: detail.Attachments ? detail.AttachmentFiles.results[0].ServerRelativeUrl : "",
                            history: detail.Historia,
                            position: detail.Posicion
                        }
                    ));
                }
                return result;
            });
            setEquipoEjecutivo({ type: 'main-exec', value: equipoEjecutivo[0] });
            // const mockData = EquipoEjecutivoMockData;
            // setEquipoEjecutivo({ type: 'main-exec', value: mockData.mainExec });

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <EquipoEjecutivoContext.Provider value={EquipoEjecutivo}>
            {children}
        </EquipoEjecutivoContext.Provider>
    );

};
const useEquipoEjecutivo = () => {
    const context = useContext(EquipoEjecutivoContext);
    if (context === undefined) {
        throw new Error("Context must be used within a Provider");
    }
    return context;
};

export { useEquipoEjecutivo, EquipoEjecutivoContextProvider };