import { useEffect, useMemo, useState } from "react";
import { ProjectCard } from "../../components/cards/projectCard/ProjectCard";
import { useProjectList } from "../../context/Projects/ProjectList.context";
import _, { orderBy } from "lodash";
import { Project } from "../../context/Projects/models/Project.models";

export const ProjectList = () => {
    const context = useProjectList();

    const { Projects } = context;

    const filters = {
        statuses: Array.from(new Set(Projects.map((project) => project.status))).sort(),
        cities: Array.from(new Set(Projects.map((project) => project.city))).sort(),
        types: Array.from(new Set(Projects.map((project) => project.type))).sort(),
    };

    const sorters: { [index: string]: { by: keyof Project; direction: 'desc' | 'asc'; }; } = useMemo(() => ({
        "0": { by: 'date', direction: 'desc' },
        "1": { by: 'date', direction: 'asc' },
        "2": { by: 'name', direction: 'asc' },
        "3": { by: 'name', direction: 'desc' },
    }), []);

    const pageSkip = 6;
    const [totalPages, setTotalPages] = useState(Math.ceil((Projects.length || 1) / 6));

    const [currentPage, setCurrentPage] = useState(1);
    const [filteredProjects, setFilteredProjects] = useState([...Projects]);
    const [projectsPage, setProjectsPage] = useState([...Projects.slice(0, pageSkip)]);
    const [selectedStatusFilter, setSelectedStatusFilter] = useState("0");
    const [selectedCityFilter, setSelectedCityFilter] = useState("0");
    const [selectedTypeFilter, setSelectedTypeFilter] = useState("0");
    const [selectedOrder, setSelectedOrder] = useState("0");
    const [filter, setFilter] = useState('');

    useEffect(() => {
        if (currentPage === 1) {
            return setProjectsPage([...filteredProjects.slice(0, pageSkip)]);
        } else {
            return setProjectsPage([...filteredProjects.slice((currentPage - 1) * pageSkip, ((currentPage - 1) * pageSkip) + pageSkip)]);
        }
    }, [currentPage, filteredProjects]);

    useEffect(() => {
        if (selectedStatusFilter === "0" && selectedCityFilter === "0" && selectedTypeFilter === "0") {
            const by = sorters[selectedOrder].by;
            const direction = sorters[selectedOrder].direction;
            const orderedProjects = selectedOrder === "0" ? [...Projects] : [...orderBy(Projects, by, direction)];
            setTotalPages(Math.ceil((orderedProjects.length || 1) / 6));
            setFilteredProjects([...orderedProjects]);
            return;
        }

        const isStatusSelected = selectedStatusFilter !== "0";
        const isCitySelected = selectedCityFilter !== "0";
        const isTypeSelected = selectedTypeFilter !== "0";

        const by = sorters[selectedOrder].by;
        const direction = sorters[selectedOrder].direction;
        const orderedProjects = selectedOrder === "0" ? [...Projects] : [...orderBy(Projects, by, direction)];

        const __filteredProjects = [...orderedProjects.filter((project) => {
            return (isStatusSelected && project.status === selectedStatusFilter)
                || (isCitySelected && project.city === selectedCityFilter)
                || (isTypeSelected && project.type === selectedTypeFilter);
        })];

        setTotalPages(Math.ceil((__filteredProjects.length || 1) / 6));
        setFilteredProjects(__filteredProjects);

        // if (!(selectedStatusFilter === "0") && selectedCityFilter === "0" && selectedTypeFilter === "0") {
        //     const _filteredProjects = [...Projects.filter((project) => project.status === selectedStatusFilter)];
        //     debugger;
        //     // setTotalPages(Math.ceil((_filteredProjects.length || 1) / 6));
        //     // setFilteredProjects(_filteredProjects);
        // }
        // if (selectedStatusFilter === "0" && !(selectedCityFilter === "0") && selectedTypeFilter === "0") {
        //     const _filteredProjects = [...Projects.filter((project) => project.city === selectedCityFilter)];
        //     debugger;
        //     // setTotalPages(Math.ceil((_filteredProjects.length || 1) / 6));
        //     // setFilteredProjects(_filteredProjects);
        // }
        // if (!(selectedStatusFilter === "0") && !(selectedCityFilter === "0") && selectedTypeFilter === "0") {
        //     const _filteredProjects = [...Projects.filter(
        //         (project) => project.status === selectedStatusFilter && project.city === selectedCityFilter
        //     )];
        //     debugger;
        //     // setTotalPages(Math.ceil((_filteredProjects.length || 1) / 6));
        //     // setFilteredProjects(_filteredProjects);
        // }


        setCurrentPage(1);
    }, [selectedStatusFilter, selectedCityFilter, selectedTypeFilter, Projects, sorters, selectedOrder]);

    return (
        <>
            <section>
                <div className="project">
                    <div className="project-head">
                        <div className="container mx-auto">
                            <div className="project__filter xl:flex xl:justify-between">
                                <div>
                                    <span className="project__filter-text">Filtrar:</span>
                                    <select
                                        onChange={(e) => setSelectedStatusFilter(e.target.value)}
                                        value={selectedStatusFilter}
                                        className="project__select xl:w-1/6"
                                        name="status"
                                        id="status">
                                        <option value="0">Estatus</option>
                                        {
                                            filters.statuses.map((status, index) => (
                                                <option key={index} value={status}>{status}</option>
                                            ))
                                        }

                                    </select>
                                    <select
                                        onChange={(e) => setSelectedCityFilter(e.target.value)}
                                        value={selectedCityFilter}
                                        className="project__select xl:w-1/5" name="city" id="city">
                                        <option value="0">Zona</option>
                                        {
                                            filters.cities.map((city, index) => (
                                                <option key={index} value={city}>{city}</option>
                                            ))
                                        }

                                    </select>
                                    <select
                                        onChange={(e) => setSelectedTypeFilter(e.target.value)}
                                        value={selectedTypeFilter}
                                        className="project__select xl:w-1/4" name="type" id="type">
                                        <option value="0">Tipos de Proyectos</option>
                                        {
                                            filters.types.map((type, index) => (
                                                <option key={index} value={type}>{type}</option>
                                            ))
                                        }

                                    </select>
                                    <select
                                        onChange={(e) => setSelectedOrder(e.target.value)}
                                        value={selectedOrder}
                                        className="project__select xl:w-1/4" name="orderFilter" id="orderFilter">
                                        <option value="0">Ver más reciente</option>
                                        <option value="1">Ver más Antiguo</option>
                                        <option value="2">De la A a la Z</option>
                                        <option value="3">De la Z a la A</option>
                                        {/* <option value="4">Todos</option> */}
                                    </select>
                                </div>

                                <div>
                                    <div className="mt-3 xl:mt-0 project__icon-search">
                                        <input
                                            placeholder="Buscar"
                                            className="project__input"
                                            type="text"
                                            value={filter}
                                            onChange={event => setFilter(event.target.value)} />
                                    </div>
                                </div>

                            </div>
                            <div className="project-list">
                                <div className="md:grid md:grid-cols-2 xl:grid-cols-3 gap-9 project-list__row">
                                    {
                                        projectsPage.filter(project => project.name.toUpperCase().includes(filter.toUpperCase()) || filter === '').map(
                                            (project, index) => (
                                                <ProjectCard key={index} project={project} />
                                            )
                                        )
                                    }
                                </div>
                            </div>
                            <div className="project-pagination">
                                <button type="button"
                                    onClick={
                                        () => setCurrentPage(currentPage - 1)
                                    }
                                    style={
                                        { display: currentPage <= 1 ? 'none' : 'grid' }
                                    }
                                    className="project-pagination__number project-pagination__number--action"
                                >
                                    Anterior
                                </button>
                                {
                                    [...Array(totalPages)].map((_, index) => (
                                        <button type="button"
                                            key={index}
                                            onClick={() => setCurrentPage(index + 1)}
                                            className={
                                                `project-pagination__number ${(currentPage === index + 1 ? "" : "project-pagination__number--active")}`
                                            }>
                                            {index + 1}
                                        </button>
                                    ))
                                }
                                <button type="button"
                                    onClick={
                                        () => setCurrentPage(currentPage + 1)
                                    }
                                    style={
                                        { display: currentPage >= totalPages ? 'none' : 'grid' }
                                    }
                                    className="project-pagination__number project-pagination__number--action">Siguiente</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};