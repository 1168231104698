import { DynamicImage } from "../base/dynamicImage/dynamicImage";
import "./infoText.css";

export interface InfoTextProps {
    className?: string;
    image?: string;
    title?: string;
    text?: string
}

export function InfoText(props: InfoTextProps) {
    return (
        <div className={'info-text' + (props.className ? " " + props.className : "")}>
            <div className="img-container">
                <DynamicImage src={props.image} alt={props.title}></DynamicImage>
            </div>

            <div className="side-text-container">
                <p>{props.text}</p>
            </div>
        </div>
    );
}
