import axios from "axios";
import React, { useContext, useEffect, useReducer } from "react";
import {
  BeneficioGeneral,
  EducationFideicomiso,
  Intro,
  Naturaleza,
  Participante,
  TipoFideicomiso,
} from "./models/Fideicomiso.models";

type EducationFideicomisoProviderProps = { children: React.ReactNode };
const EducationFideicomisoContext = React.createContext<
  EducationFideicomiso | undefined
>(undefined);

type Message =
  | { type: "intro"; value: Intro }
  | { type: "beneficiosGenerales"; value: BeneficioGeneral[] }
  | { type: "naturalezas"; value: Naturaleza[] }
  | { type: "participantes"; value: Participante[] }
  | { type: "tipos"; value: TipoFideicomiso[] };

const reducer = (state: EducationFideicomiso, message: Message) => {
  switch (message.type) {
    case "intro":
      return {
        ...state,
        Intro: message.value,
      };
    case "beneficiosGenerales":
      return {
        ...state,
        BeneficiosGenerales: message.value,
      };
    case "naturalezas":
      return {
        ...state,
        Naturaleza: message.value,
      };
    case "participantes":
      return {
        ...state,
        Participantes: message.value,
      };
    case "tipos":
      return {
        ...state,
        Tipos: message.value,
      };
    default:
      return { ...state };
  }
};
function EducationFideicomisoContextProvider({
  children,
}: EducationFideicomisoProviderProps) {
  const [fideicomiso, setFideicomiso] = useReducer(
    reducer,
    {} as EducationFideicomiso
  );

  useEffect(() => {
    fetchIntro();
    fetchBeneficiosGenerales();
    fetchNaturalezas();
    fetchParticipantes();
    fetchTipos();
  }, []);

  async function fetchIntro() {
    try {
      const intro = await axios
        .get(
          "/Educativa/_api/web/lists/GetByTitle('Que es - Intro')/items?$select=Activo,Title,Contenido,Attachments,AttachmentFiles&$expand=AttachmentFiles&$filter=Activo eq 1",
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: Intro = {};

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((intro: any) => {
              result = {
                Titulo: intro.Title,
                Contenido: intro.Contenido,
                Imagen:
                  intro.AttachmentFiles.results.length > 0
                    ? {
                        FileName: intro.AttachmentFiles.results[0].FileName,
                        ServerRelativeUrl:
                          intro.AttachmentFiles.results[0].ServerRelativeUrl,
                      }
                    : { FileName: "", ServerRelativeUrl: "" },
              };
            });

          return result;
        });

      setFideicomiso({ type: "intro", value: intro });
      // setFideicomiso(reducer({ type: 'intro', value: intro }, fideicomiso));
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchBeneficiosGenerales() {
    try {
      const beneficios = await axios
        .get(
          "/Educativa/_api/web/lists/GetByTitle('Que es - Beneficios Generales')/items?$select=Orden,Activo,Title&$orderby=Orden&$filter=Activo eq 1",
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: BeneficioGeneral[] = [];

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((beneficio: any) => {
              result.push({
                Orden: beneficio.Orden,
                Activo: beneficio.Activo,
                Titulo: beneficio.Title,
              });
            });

          return result;
        });

      setFideicomiso({ type: "beneficiosGenerales", value: beneficios });
      // setFideicomiso(reducer({ type: 'beneficiosGenerales', value: beneficios }, fideicomiso));
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchNaturalezas() {
    try {
      const naturalezas = await axios
        .get(
          "/Educativa/_api/web/lists/GetByTitle('Que es - Naturaleza')/items?$select=Orden,Activo,Title,Contenido&$orderby=Orden&$filter=Activo eq 1",
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: Naturaleza[] = [];

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((naturaleza: any) => {
              result.push({
                Orden: naturaleza.Orden,
                Activo: naturaleza.Activo,
                Titulo: naturaleza.Title,
                Contenido: naturaleza.Contenido,
              });
            });

          return result;
        });

      setFideicomiso({ type: "naturalezas", value: naturalezas });
      // setFideicomiso(reducer({ type: 'naturalezas', value: naturalezas }, fideicomiso));
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchParticipantes() {
    try {
      const participantes = await axios
        .get(
          "/Educativa/_api/web/lists/GetByTitle('Que es - Participantes')/items?$select=Orden,Activo,Title,Contenido,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1",
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: Participante[] = [];

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((participante: any) => {
              result.push({
                Orden: participante.Orden,
                Activo: participante.Activo,
                Titulo: participante.Title,
                Contenido: participante.Contenido,
                Imagen:
                  participante.AttachmentFiles.results.length > 0
                    ? {
                        FileName:
                          participante.AttachmentFiles.results[0].FileName,
                        ServerRelativeUrl:
                          participante.AttachmentFiles.results[0]
                            .ServerRelativeUrl,
                      }
                    : { FileName: "", ServerRelativeUrl: "" },
              });
            });

          return result;
        });

      setFideicomiso({ type: "participantes", value: participantes });
      //setFideicomiso(reducer({ type: 'participantes', value: participantes }, fideicomiso));
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchTipos() {
    try {
      const tipos = await axios
        .get(
          "/Educativa/_api/web/lists/GetByTitle('Que es - Tipos')/items?$select=Orden,Activo,Title,Contenido,Enlace,TextoEnlace,Attachments,AttachmentFiles&$expand=AttachmentFiles&$orderby=Orden&$filter=Activo eq 1",
          {
            headers: {
              Accept: "application/json;odata=verbose",
              "Content-Type": "application/json;odata=verbose",
            },
          }
        )
        .then((response) => {
          let result: TipoFideicomiso[] = [];

          if (
            response.status === 200 &&
            response.data &&
            response.data.d &&
            response.data.d.results
          )
            response.data.d.results.forEach((tipo: any) => {
              result.push({
                Orden: tipo.Orden,
                Activo: tipo.Activo,
                Titulo: tipo.Title,
                Contenido: tipo.Contenido,
                Imagen:
                  tipo.AttachmentFiles.results.length > 0
                    ? {
                        FileName: tipo.AttachmentFiles.results[0].FileName,
                        ServerRelativeUrl:
                          tipo.AttachmentFiles.results[0].ServerRelativeUrl,
                      }
                    : { FileName: "", ServerRelativeUrl: "" },
                Enlace: tipo.Enlace,
                TextoEnlace: tipo.TextoEnlace,
              });
            });

          return result;
        });

      setFideicomiso({ type: "tipos", value: tipos }); //reducer({ type: 'tipos', value: tipos }, fideicomiso));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <EducationFideicomisoContext.Provider value={fideicomiso}>
      {children}
    </EducationFideicomisoContext.Provider>
  );
}

const useEducationFideicomiso = () => {
  const context = useContext(EducationFideicomisoContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
};

export { useEducationFideicomiso, EducationFideicomisoContextProvider };
