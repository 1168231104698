import { BannerTop } from "../../../components/bannerTop/bannerTop";
import { GlobalSideMenu, ListItemProps } from "../../../components/sideMenu/globalSideMenu";
import { useMiembrosConsejo } from "../../../context/Nosotros/MiembrosConsejo.contex";
import { PersonalList } from "../../../components/personal/personalList/personalList";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export interface MiembrosConsejoProps {
    menu: ListItemProps[];
}
export const MiembrosConsejo = (props: MiembrosConsejoProps) => {
    const context = useMiembrosConsejo();
    const location = useLocation();
    const { menu } = props;
    const [menuState, setMenu] = useState(menu);
    useEffect(() => {
        const _menu = menu.map((item) => {
            if (item.route === location.pathname) {
                //TODO: implement active route logic
            }
            return item;
        });

        // setMenu(_menu);
    }, [location.pathname, menu]);

    return (
        <>
            <BannerTop title={context?.Titulo || ""}></BannerTop>
            <div className="article section">
                <div className="container mx-auto xl:flex">
                    <div className="col xl:w-1/3 xl:px-4 xl:order-last">
                        <GlobalSideMenu title="Secciones" listItems={menuState}></GlobalSideMenu>
                    </div>
                    <div className="col xl:w-2/3 xl:pr-14">
                        <PersonalList title="Miembros del Consejo" personalList={context?.personalList}></PersonalList>
                    </div>
                </div>
            </div>
        </>
    );
};