import { Link } from "react-router-dom";
import { DynamicImage } from "../../base/dynamicImage/dynamicImage";
import "./projectHorizontalCard.css";

export interface HorizontalProjectCardProps {
    id: number;
    className?: string;
    title: string;
    descripcion: string;
    ubicacion: string;
    thumbnail: string;
}

export function HorizontalProjectCard(props: HorizontalProjectCardProps) {
    return (
        <Link to={"/Proyectos/Detalles?proyectoId=" + props.id} className="horizontal-project-card">
            <DynamicImage src={props.thumbnail} alt={"Imagen de " + props.title}></DynamicImage>
            <div className="info">
                <h3 className="project-list__name">{props.title}</h3>
                <h4 className="project-list__builder">{props.descripcion}</h4>
                <h4 className="project-list__city">
                    <span className="feather icon-map-pin"></span>
                    {props.ubicacion}
                </h4>
            </div>
            <span className="feather icon-chevron-right"></span>
        </Link>
    );
}
