import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HoverBackgroundCard } from "../../components/cards/hoverBackgroundCard/hoverBackgroundCard";
import { HoverProfileCard } from "../../components/cards/hoverProfileCard/hoverProfileCard";
import { InfoText } from "../../components/infoText/infoText";
import { CheckList } from "../../components/lists/checkList/checkList";
import { ToggleList } from "../../components/lists/toggleList/toggleList";
import { useEducationFideicomiso } from "../../context/Education/Fideicomiso.context";
import {
  BeneficioGeneral,
  Naturaleza,
  Participante,
  TipoFideicomiso,
} from "../../context/Education/models/Fideicomiso.models";
import "./Fideicomiso.css";

export const EducativaFideicomiso = () => {
  const context = useEducationFideicomiso();
  const [showAllTypes, setShowAllTypes] = useState(false);
  const [showingTypes, setShowingTypes] = useState(false);

  useEffect(() => {
    setShowingTypes(true);
    if (showAllTypes)
      setTimeout(() => {
        setShowingTypes(false);
      }, 500);
  }, [showAllTypes]);

  return (
    <div className="educativa-fideicomiso section">
      <div className="">
        <div className="container mx-auto intro-container">
          <div className="list-container">
            <div className="general-benefits">
              <h3>Beneficios Generales</h3>

              <CheckList
                type="check"
                listElements={context?.BeneficiosGenerales?.map(
                  (beneficio: BeneficioGeneral) => {
                    return { text: beneficio.Titulo };
                  }
                )}
              ></CheckList>
            </div>
          </div>
          <div className="text-container">
            <div className="article-text">
              <h3 className="article-text__head-title">
                ¿Qué es un Fideicomiso?
              </h3>

              <InfoText
                image={context?.Intro?.Imagen?.ServerRelativeUrl}
                title={context?.Intro?.Titulo}
                text={context?.Intro?.Contenido}
              ></InfoText>
            </div>

            <div className="mt-16 article-text">
              <h3 className="mb-10 article-text__head-title">
                Naturaleza del Fideicomiso
              </h3>

              <ToggleList
                listItems={context?.Naturaleza?.map(
                  (naturaleza: Naturaleza) => {
                    return {
                      title: naturaleza.Titulo,
                      body: naturaleza.Contenido,
                    };
                  }
                )}
              ></ToggleList>
            </div>
          </div>
        </div>
      </div>

      <div className="py-20 mt-12 bg-oscura">
        <div className="container mx-auto">
          <h3 className="text-center article-text__head-title text-blanco">
            Participantes de un Fideicomiso
          </h3>
          <div className="article-text">
            <div className="participants">
              {context?.Participantes?.map(
                (participante: Participante, index: number) => (
                  <HoverProfileCard
                    image={participante.Imagen.ServerRelativeUrl}
                    title={participante.Titulo}
                    text={participante.Contenido}
                  ></HoverProfileCard>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="article">
        <div className="mt-12">
          <div className="container mx-auto">
            <h3 className="text-center article-text__head-title">
              Tipos de Fideicomiso
            </h3>

            <div className={"types mt-12" + (showAllTypes ? " show-all" : "")}>
              {context?.Tipos?.map((tipo: TipoFideicomiso, index: number) => {
                if (index < 6 || showAllTypes)
                  return (
                    <HoverBackgroundCard
                      className={
                        (index >= 6 ? "hidden-type" : "") +
                        (index >= 6 && showingTypes ? " opacity-0" : "")
                      }
                      image={tipo.Imagen.ServerRelativeUrl}
                      title={tipo.Titulo}
                      Contenido={tipo.Contenido}
                      hoverText={tipo.TextoEnlace}
                      enlace={tipo.Enlace}
                      style={{
                        animationDelay: `${(index - 5) * 0.2}s`,
                        MozAnimationDelay: `${(index - 5) * 0.2}s`,
                        WebkitAnimationDelay: `${(index - 5) * 0.2}s`,
                      }}
                    ></HoverBackgroundCard>
                  );
              })}
            </div>

            <div className="link-container">
              <Link className="primary-button" to="/Fideicomisos">
                Ver Todos
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
