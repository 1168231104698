import { useEffect, useState } from "react";
import "./transparency-document.css";

import DocumentBlock from "./DocumentBlock";
import SingleDocumentBlock from "./SingleDocumentBlock";

function AccordionDocument({ currDocSelection, handleShowDocuments }: any) {
  const [activeTitle, setActiveTitle] = useState("");
  const [groupedSelection, setGroupedSelection] = useState<string[]>([]);

  const toggleAccordion = (title: any) => {
    if (activeTitle === title) {
      return setActiveTitle("");
    }
    setActiveTitle(title);
  };

  const groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    let grouped = groupBy(currDocSelection, "clasificacion");
    let masterobject = Object.keys(grouped);
    setGroupedSelection(masterobject);
  }, [currDocSelection]);

  return (
    currDocSelection &&
    currDocSelection.length > 0 && (
      <>
        {groupedSelection.map((collapseTitle, i) => {
          return (
            <div key={i} className="py-6 accordion-section">
              <div
                className="flex justify-between w-full cursor-pointer accordion"
                onClick={() => toggleAccordion(collapseTitle)}
              >
                <h6 className="accordion-section__title">{collapseTitle}</h6>
                <span
                  style={{ marginLeft: "20px" }}
                  className={`feather accordion-icon ${
                    activeTitle === collapseTitle
                      ? "icon-chevron-up"
                      : "icon-chevron-down"
                  }`}
                ></span>
              </div>
              <div
                className={`accordion__content py-3 ${
                  activeTitle === collapseTitle ? "block" : "hidden"
                }`}
              >
                <SingleDocumentBlock
                  handleShowDocuments={handleShowDocuments}
                  currDocSelection={currDocSelection.filter(
                    (selected: any) => selected.clasificacion === collapseTitle
                  )}
                />
              </div>
            </div>
          );
        })}
      </>
    )
  );
}

export default AccordionDocument;
