import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const location = useLocation();

    useEffect(() => {
        const workspace = document.getElementById("s4-workspace");
        if (workspace)
            workspace.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        //window.scrollTo(0, 0);
        // console.log(location);
    }, [location]);

    return null;
}