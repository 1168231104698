import React from "react";
import "./transparency-document.css";

type SearchDocumentComponentFilterProps = {
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  changeCurrDocType: (value: any) => void;
  changeCurrDate: (value: any) => void;
  hideSelect?: boolean;
};

function FilterTransparecy({
  setSearchInput,
  changeCurrDocType,
  changeCurrDate,
  hideSelect = true,
}: SearchDocumentComponentFilterProps) {
  const DocTypeOptions = [
    "Licitación Pública",
    "Licitación Cerrada",
    "Sorteo de Obras",
    "Comparación de Precios",
    "Compras Menores",
  ];
  const DateOptions = [
    { value: "desc", label: "De reciente a mas antigua" },
    { value: "asc", label: "De mas antigua a mas reciente" },
  ];
  const handleChange = function (e: any) {
    setSearchInput(e.target.value);
  };

  return (
    <div className="transparency-searchbar">
      <div className="project-head xl:flex xl:justify-between">
        <div className="xl:flex xl:items-center xl:w-1/2">
          <span className="project__filter-text">Filtrar:</span>
          <select
            className="w-full project__select xl:w-1/2"
            onChange={(e: any) => changeCurrDate(e.target.value)}
          >
            <option value="">Fecha</option>
            {DateOptions.map((option, i) => (
              <option key={i} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <select
            className={`w-full project__select xl:w-1/2 ${
              hideSelect ? "" : "project__select--hidden"
            }`}
            onChange={(e: any) => changeCurrDocType(e.target.value)}
          >
            <option value="">Mostrar Todos</option>
            {DocTypeOptions.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div>
          <div className="mt-3 xl:mt-0 project__icon-search">
            <input
              onChange={handleChange}
              placeholder="Buscar"
              className="project__input"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterTransparecy;
