import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import { ProjectCard } from "../../components/cards/projectCard/ProjectCard";
import { Project } from "../../context/Projects/models/Project.models";

export interface ProjectsSliderProps {
    projects: Project[];
}
export const ProjectsSlider = (props: ProjectsSliderProps) => {

    const { projects } = props;
    const sliderOptions = {
        perPage: 3,
        perMove: 1,
        arrows: true,
        pagination: false,
        gap: "2.25rem",
        breakpoints: {
            1024: {
                perPage: 2,
                perMove: 1,
                arrows: false,
                pagination: true,
                gap: "2rem"
            },
            640: {
                perPage: 1,
                arrows: false,
                pagination: true,
            },
        }
    };

    return (
        <div className="project-list project-list--slide">
            <h3 className="article-text__title article-text__title--project">Otros Proyectos
            </h3>

            <Splide
                id="morePorjectSlide"
                options={sliderOptions}
            >
                {
                    projects?.map((project) => (
                        <SplideSlide>
                            <ProjectCard project={project}></ProjectCard>
                        </SplideSlide>
                    ))
                }
            </Splide>
        </div>);
};